<template lang="html">
  <div class="editor">
    <div ref="toolbar" class="toolbar"></div>
    <div ref="editor" class="text"></div>
  </div>
</template>

<script>
import i18next from "i18next";
import { fileCKUploadImg } from "../api";

export default {
  name: "editoritem",
  data() {
    return {
      isFocus: false, // 富文本获取焦点
      editorContent: "",
      editor: {},
      E: window.wangEditor,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
  },
  mounted() {
    this.seteditor();
  },
  methods: {
    // 模板插入
    inserthtml(val) {
      if (this.isFocus) {
        // 富文本获取了焦点
        // 在光标位置插入文字
        this.editor.cmd.do("insertHTML", val);
      } else {
        let valstr = val + this.editor.txt.html();
        this.clearvalue();
        this.editor.txt.html(valstr);
      }
    },
    seteditor() {
      let that = this;
      this.editor = new this.E(this.$refs.toolbar, this.$refs.editor);
      // 选择语言
      if (localStorage.getItem("locale") === "zh") {
        this.editor.config.lang = "zh-CN";
      } else {
        // 其他情况展示英文
        this.editor.config.lang = "en";
      }

      // 引入 i18next 插件
      this.editor.i18next = i18next;
      // 隐藏菜单栏提示
      // this.editor.config.showMenuTooltips = false
      // 自定义 placeholder 提示文字
      this.editor.config.placeholder = "";
      // 设置编辑区域高度为 500px
      // this.editor.config.height = 500
      this.editor.config.onchange = () => {
        this.editorContent = this.editor.txt.html();
      };
      // 富文本获取焦点
      this.editor.config.onfocus = function () {
        that.isFocus = true;
      };
      // this.editor.config.uploadImgShowBase64 = true; // base 64 存储图片
      this.editor.config.uploadImgServer = window.Glod['ccex-apitsf']+"/api/file/CKUploadImg"; // 配置服务器端地址
      // this.editor.config.uploadFileName = 'file'
      this.editor.config.uploadImgMaxSize = 2 * 1024 * 1024; // 将图片大小限制为 2M
      this.editor.config.uploadImgMaxLength = 6; // 限制一次最多上传 3 张图片
      this.editor.config.uploadImgTimeout = 3 * 60 * 1000; // 设置超时时间
      this.editor.config.zIndex = 1;
      // 配置菜单
      this.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "video", // 插入视频
        "code", // 插入代码
        "undo", // 撤销
        "redo", // 重复
        "fullscreen", // 全屏
      ];
      this.editor.config.withCredentials = true; //跨域上传中如果需要传递 cookie 需设置 withCredentials
       // 取消自动 focus
      this.editor.config.focus = false
      this.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        let formData = new FormData();
        formData.append("file", resultFiles[0]);
        formData.append("fileName", resultFiles[0].name);
        fileCKUploadImg(formData).then((res) => {
          if (res.data) {
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.data);
          }
        });
      };

      // 创建富文本编辑器
      this.editor.create();
    },

    //清空副文本编辑器的值
    clearvalue() {
      this.editor.txt.clear();
    },
    //是否为回复邮件
    beforeEditorEdit(params) {
      let value = this.editor.txt.html();
      let isreply = value.indexOf(
        `<p>------------------------${this.$i18n.t(
          "label.email.original.content"
        )}-------------------------</p>`
      );
      if (isreply != -1) {
        // 不是回复邮件
        this.clearvalue();
        this.signContent(params + value);
      } else {
        this.signContent(params);
      }
    },
    signContent(val, flag) {
      // flag:true   表示使用模板
      let div = `<div>${val}</div>`;
      // this.editor.txt && this.editor.txt.append(div)

      if (flag) {
        this.inserthtml(div);
      } else {
        this.editor.txt && this.editor.txt.append(div);
      }
      // 触发监听器，改变父组件中富文本对应的值
      // this.editorContent = this.editor.txt.html()
    },
    wangEditorese(val) {
      // 要求原文内容可以编辑
      let div = `
       <div>
        <div style="width: 100%;min-height:150px;"></div>
        <div
          contenteditable="true"
          style="width: 100%;text-align:left;padding-left: 15px;"
        >
          <p>------------------------${this.$i18n.t(
            "label.email.original.content"
          )}-------------------------</p>
          <p>${this.$i18n.t("label.senduser")}:${val.detail.fromaddress}</p>
          <p>${this.$i18n.t("component.sendmail.label.touser")}:${
        val.detail.toaddress
      }</p>
          <p ${val.detail.ccaddress === "" ? false : true}>${this.$i18n.t(
        "label.mobile.phone.cc.people"
      )}:${val.detail.ccaddress}</p>
          <p>${this.$i18n.t("vue_label_email_cc")}:${
        val.detail.bccaddress === "" || val.detail.bccaddress === undefined
          ? ""
          : val.detail.bccaddress
      }</p>
          <div style="letter-spacing: 0px;
    text-align: justify;
    white-space: pre-line">${val.detail.htmlbody}</div>
        </div>
      </div>`;
      this.editor.txt && this.editor.txt.append(div);
      // 不获取焦点
      this.isFocus = false;
    },
  },
  watch: {
    editorContent(val) {
      if (typeof val == "string") {
        this.$emit("editorContent", val);
      }
      if(val == ""){
        this.editor.txt.html(`<span></span>`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.editor {
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
::v-deep .toolbar {
  border: 1px solid #ccc;
  flex-wrap: wrap;
  background-color: #f1f1f1;
  .w-e-menu {
    font-size: 12px !important;
    padding: 0px 6px !important;
    z-index: auto !important;
  }
}
::v-deep .text {
  border: 1px solid #ccc;
  height: 300px !important;
  font-size: 16px !important;
}
</style>
<style scoped>
.editor >>> .w-e-text-container {
  z-index: 1 !important;
}
.editor >>> .w-e-toolbar {
  flex-wrap: wrap;
}
.editor >>> .w-e-menu {
  z-index: auto !important;
}
.editor >>> .w-e-droplist {
  z-index: 3 !important;
}
</style>
