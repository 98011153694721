<template>
  <!-- 开发人员：李长兵 -->
  <div v-if="isShow">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>
          <!-- 编辑签名 -->
          {{ $t("label.emailobject.signature") }}
        </span>
        <i
          style="float: right; padding: 3px 0; cursor: pointer"
          type="text"
          class="el-icon-close"
          @click="clonse"
        ></i>
      </div>
      <div style="padding: 20px">
        <wangEditores
          ref="wangEditoresRef"
          @editorContent="editorContent"
        ></wangEditores>
        <el-checkbox v-model="checked">
          <!-- 启用签名 -->
          {{ $t("vue_label_email_enable_signature") }}
        </el-checkbox>
      </div>
      <div class="footer">
        <el-button size="mini" @click="clonse">
          <!-- 取 消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button class="queding" size="mini" @click="quedings">
          <!-- 确 定 -->
          {{ $t("label.ems.confirm") }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import wangEditores from '../../../../components/wangEditor'
import { getEmailObjectSaveSign, getEmailObjectQuerySign } from '../api.js'
export default {
  components: {
    wangEditores,
  },
  data() {
    return {
      isShow: true,
      content: "",
      checked: false,
    };
  },
  methods: {
    clonse() {
      // 清空富文本内容
      this.$refs.wangEditoresRef.clearvalue();
      this.$emit("Autographes");
    },
    async quedings() {
      let params = {
        content: this.content,
        isusing: JSON.stringify(this.checked),
      };
      let res = await getEmailObjectSaveSign(params);
      if (res.result) {
        this.clonse();
      }
    },
    editorContent(val) {
      this.content = val;
    },
    // 父组件中通过ref调用
    async getEmailObjectQuerySigns() {
      let res = await getEmailObjectQuerySign();
      if (res.result) {
        if (res.data.isusing === "true") {
          this.checked = true;
        } else {
          this.checked = false;
        }
        this.$refs.wangEditoresRef.signContent(res.data.signContent);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .box-card {
  width: 475px;
  .el-card__header {
    padding: 11px 14px;
  }
  .el-card__body {
    .text {
      height: 225px !important;
    }
    padding: 0;
    .el-checkbox {
      margin-top: 10px;
      .el-checkbox__label {
        display: inline-block;
      }
    }
    .footer {
      border-top: 1px solid #ebeef5;
      padding: 10px 25px 10px 0px;
      text-align: right;

      .queding {
        background: #005fb2;
        color: #ffffff;
      }
    }
  }
}
</style>