<template>
  <!-- 开发人员：李长兵 -->
  <div>
    <div>
      <!-- 另存为模板 -->
      <el-dialog
        :title="$t('label.data.exp.savetemp')"
        :visible.sync="dialogVisible"
        width="425px"
      >
        <div style="display: flex;text-align: left;">
          <span
            style="width: 100px;
          height: 14px;
          font-size: 14px;

          font-weight: 400;
          line-height: 21px;"
            ><i style="color: red;">*</i>
            <!-- 模板名称 -->
            {{ $t('label.emailtemplate.name') }}
          </span>
          <!-- 请输入内容 -->
          <el-input
            v-model="name"
            :placeholder="$t('label_tabpage_contentz')"
          ></el-input>
        </div>
        <div style="display: flex;margin-top: 10px;text-align: left;">
          <span
            style="width: 100px;
          height: 14px;
          font-size: 14px;

          font-weight: 400;
          line-height: 21px;"
          >
            <!-- 可见性 -->
            {{ $t('label.customsetting.visible') }}
          </span>
          <!-- 请选择 -->
          <el-select
            v-model="value"
            :placeholder="$t('label_tabpage_pleaseSelectz')"
            class="select"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">
            <!-- 取消 -->
            {{ $t('button_partner_cancel') }}
          </el-button>
          <el-button class="define" @click="save">
            <!-- 确定 -->
            {{ $t('label.ems.confirm') }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <div class="edit">
      <!-- 管理邮件模板 -->
      <el-dialog
        :title="$t('label.emailobject.manage.template')"
        :visible.sync="dialogVisibles"
        width="685px"
      >
        <div>
          <div class="headerbox">
            <!-- 请输入内容 -->
            <el-input
              v-model="state"
              @input="querySearchAsync"
              :placeholder="$t('label_tabpage_contentz')"
              :fetch-suggestions="querySearchAsync"
            />
            <el-checkbox
              v-model="checked"
              @change="getEmailObjectGetEmailTemplatess"
            >
              <!-- 仅显示我的模板 -->
              {{ $t('label.emailobject.my.template') }}
            </el-checkbox>
          </div>
        </div>
        <div style="margin-top: 20px;height: 380px;overflow: hidden;">
          <ul style="height: 380px;overflow-y: auto;" class="ul">
            <li
              v-for="(item, index) in tableData"
              :key="index"
              style="cursor: pointer;"
            >
              <!-- <el-popover placement="top-start"
                          title="提示"
                          width="276"
                          trigger="hover">
                <p>
                  插入此模板将覆盖当前邮件主题
                  {{ $t('vue_label_email_current_theme') }}
                </p>
                <div style="text-align: right; margin: 0">
                  <el-button size="mini"
                             @click="visible = false"
                             class="define">
                             确定
                             {{ $t('label.ems.confirm') }}
                             </el-button>
                </div>
                <span slot="reference"> {{ item.name }}</span>
              </el-popover> -->
              <span slot="reference" @click="add(item)"> {{ item.name }}</span>
              <!-- <i
                class="el-icon-download"
                style="float: right;-webkit-transform:rotate(180deg);font-size: 22px;cursor: pointer;"
                @click="Topping(item)"
              ></i> -->
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
    <!-- 选择模板提示 -->
    <el-dialog
      :title="$t('label.prompt')"
      :visible.sync="isInsertTem"
      width="30%"
      :append-to-body="true"
    >
      <!-- 插入此模板将覆盖当前邮件 -->
      <!-- {{$t('vue_label_email_current_email')}} -->
      <div class="englishWrap">
        <!-- 插入此模板将覆盖当前邮件主题 -->
        {{ $t('vue_label_email_current_theme') }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isInsertTem = false">
          <!-- 取消 -->
          {{ $t('label.cancel') }}
        </el-button>
        <el-button type="primary" @click="insertTemplate">
          <!-- 确定 -->
          {{ $t('label.confirm') }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getEmailObjectGetEmailTemplates,
  // getEmailObjecttopEmailTemplate,
  getEmailObjectsaveEmailTemplate,
} from '../api.js'
export default {
  props: {
    templateInfo: Object,
  },
  data() {
    return {
      item: {}, // 选中的模板
      isInsertTem: false, // 模板插入提示弹窗
      name: '',
      dialogVisible: false,
      dialogVisibles: false,
      // options: [],
      tableData: [],
      checked: false,
      state: '',
      templateInfos: this.templateInfo,
      options: [
        {
          value: 'private',
          label: this.$i18n.t('label.emailobject.template.private'),
          //私有
        },
        {
          value: 'public',
          label: this.$i18n.t('label.emailobject.template.public'),
          // 公开
        },
      ],
      value: '',
    }
  },
  methods: {
    add(item) {
      this.item = item
      this.isInsertTem = true
    },
    //插入模板
    insertTemplate() {
      this.$emit('tmp', this.item)
      this.isInsertTem = false
      this.dialogVisibles = false
    },
    plus() {
      this.dialogVisibles = false
      this.dialogVisible = !this.dialogVisible
    },
    edit() {
      this.dialogVisible = false
      this.dialogVisibles = !this.dialogVisibles
    },
    async getEmailObjectGetEmailTemplatess() {
      if (this.checked === true) {
        let params = {
          onlyShowMyTemplate: true,
          searchWord: '',
        }
        let res = await getEmailObjectGetEmailTemplates(params)

        if (res.result) {
          this.tableData = res.data
        }
      } else {
        let params = {
          onlyShowMyTemplate: '',
          searchWord: '',
        }
        let res = await getEmailObjectGetEmailTemplates(params)

        if (res.result) {
          this.tableData = res.data
        }
      }
    },
    async Topping() {
      // let params = {
      //   id: item.id,
      //   name: item.name,
      // }

      // let res = await getEmailObjecttopEmailTemplate(params)
      this.getEmailObjectGetEmailTemplatess()
    },
    querySearchAsync(queryString) {
      if (queryString !== '') {
        var restaurants = this.tableData

        var results = queryString
          ? restaurants.filter(this.createStateFilter(queryString))
          : restaurants
        this.tableData = results
      } else {
        this.GetTagSearchTagss()
      }
    },
    createStateFilter(queryString) {
      return (input) => {
        return input.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    async save() {
      let params = {
        name: this.name,
        subject: this.templateInfos.theme,
        htmlbody: this.templateInfos.content,
        visibility: this.value,
        id: '',
        fileids: '',
      }

      let res = await getEmailObjectsaveEmailTemplate(params)
      if (res.result) {
        this.dialogVisible = false
        this.$parent.Manages()
      }
    },
  },
  watch: {
    state(val) {
      if (val === '') {
        this.getEmailObjectGetEmailTemplatess()
      }
    },
    templateInfos() {
      // 
    },
  },
}
</script>
<style lang="scss" scoped>
.headerbox {
  display: flex;
  ::v-deep .el-checkbox__label {
    display: inline;
  }
}
.ul {
  margin: 0 !important;
  border-top: 1px solid #dcdcdc;
}

.ul li {
  padding: 10px;
  height: 42px;
  /* border-top: 1px solid #DCDCDC; */
  border-bottom: 1px solid #dcdcdc;
}
.englishWrap {
  word-wrap: break-word;
  word-break: normal;
}
::v-deep .el-dialog {
  height: 240px;

  .el-dialog__title {
    font-size: 18px;
    font-weight: bold;
    color: #686868;
  }

  .el-select {
    width: 100%;

    .el-input__icon {
      line-height: 30px;
    }
  }

  .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;

    .el-button {
      width: 64px;
      height: 28px;
      padding: 0px;
    }
  }
}

::v-deep .edit {
  .el-dialog {
    height: 528px;

    .el-dialog__header {
      border-bottom: 1px solid #dcdcdc;
    }

    .el-input__inner {
      width: 370px;
      height: 42px;
    }

    .el-checkbox {
      margin-top: 10px;
      margin-right: 20px;
    }

    ::v-deep .el-popover {
      width: 276px;
      height: 164px;

      .el-popover__title {
        font-size: 18px !important;
        font-weight: bold;
        color: #686868;
      }
    }
  }
}

.define {
  background: #006dcc;
  color: #ffffff;
}
.select {
  ::v-deep .el-select-dropdown__list {
    li {
      padding: 0 !important;
    }
  }
}
</style>
