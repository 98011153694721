<template>
  <!-- 开发人员：李长兵 -->
  <div>
    <el-tabs type="border-card" class="tabs" v-model="activeName">
      <!-- 新建事件 -->
      <el-tab-pane
        name="event"
        :label="$t('label.commonobjects.new', { eventName: eventName })"
      >
        <!-- <el-tab-pane
        name="event"
        :label="`${$t('label.ems.create')}${eventName}`"
      > -->
        <div class="newTask calendar_New_Task">
          <!-- 新建事件 -->
          <el-input
            class="calendar_New_Task_input"
            :placeholder="
              $t('label.commonobjects.new', { eventName: eventName })
            "
            @focus="focus('event')"
          ></el-input>
          <el-button
            type="primary"
            class="calendar_New_Task_button"
            @click="calendarNew"
          >
            <!-- 去日历新建事件 -->
            {{ $t("vue_label_commonobjects_detail_activity_tocalendar") }}
          </el-button>
        </div>
      </el-tab-pane>
      <!-- 记录电话 -->
      <el-tab-pane name="logCall" :label="$t('label.recordphone')">
        <div class="newTask">
          <!-- 记录电话 -->
          <el-input
            :placeholder="
              $t('label.globaloperation.operationtype.record.phone')
            "
            @focus="focus('logCall')"
          ></el-input>
        </div>
      </el-tab-pane>

      <!-- 新建任务 -->
      <!-- <el-tab-pane name="task" :label="`${$t('label.ems.create')}${taskName}`"> -->
      <el-tab-pane
        name="task"
        :label="$t('label.ems.createTask', { taskName: taskName })"
      >
        <div class="newTask">
          <!-- 新建任务 -->
          <el-input
            :placeholder="$t('label.ems.createTask', { taskName: taskName })"
            @focus="focus('task')"
          ></el-input>
        </div>
      </el-tab-pane>

      <!-- 电子邮件 -->
      <el-tab-pane :label="$t('label.email')" name="email" v-if="!isprivate">
        <div v-if="ifEmail">
          <p
            style="
              font-size: 14px;
              font-weight: bold;
              color: #333333;
              font-family: SourceHanSansCN-Medium, SourceHanSansCN;
            "
          >
            <!-- 管理您的个人邮箱 -->
            {{ $t("label_emailsync_setting") }}
          </p>
          <span
            style="
              font-size: 12px;
              font-family: SourceHanSansCN-Normal, SourceHanSansCN;
              font-weight: 400;
              color: #333333;
            "
          >
            <!-- 离开系统去您的邮箱与联系人进行邮件往来已成为过去，在CloudCC上，关联您的个人邮箱，可以直接从CloudCC发送邮件，同时自动关联您的潜在客户、联系人和业务机会，沟通历史按时间线一目了然。 -->
            {{ $t("label.emailobject.wizard.desc") }}
          </span>
          <div>
            <div>
              <svg class="img" aria-hidden="true">
                <use href="#icon-yes"></use>
              </svg>
              <span class="tls">
                <!-- 关联您的个人邮箱，在此发送邮件并自动同步到记录 -->
                {{ $t("label.emailobject.wizard.desc1") }}
              </span>
            </div>
            <div>
              <svg class="img" aria-hidden="true">
                <use href="#icon-yes"></use>
              </svg>
              <span class="tls">
                <!-- 跟踪电子邮件是否被打开及打开次数 -->
                {{ $t("label.emailobject.wizard.desc2") }}
              </span>
            </div>
            <div>
              <svg class="img" aria-hidden="true">
                <use href="#icon-yes"></use>
              </svg>
              <span class="tls">
                <!-- 使用电子邮件模版，节省更多时间 -->
                {{ $t("vue_label_commonobjects_detail_email_template") }}
              </span>
            </div>
            <div>
              <svg class="img" aria-hidden="true">
                <use href="#icon-yes"></use>
              </svg>
              <span class="tls">
                <!-- 插入一个可预约时间，让多人邀约更简单 -->
                {{ $t("label.emailobject.wizard.desc4") }}
              </span>
            </div>
            <div>
              <svg class="img" aria-hidden="true">
                <use href="#icon-yes"></use>
              </svg>
              <span class="tls">
                <!-- 制您的签名，让邮件看起来更美观 -->
                {{ $t("label.emailobject.wizard.desc5") }}
              </span>
            </div>
          </div>
          <div class="img_box">
            <svg
              class="iconReply"
              aria-hidden="true"
              width="330"
              height="200"
              viewBox="0,0,330,200"
            >
              <use href="#icon-email4"></use>
            </svg>
          </div>
          <div style="margin-bottom: 10px; text-align: center">
            <el-button style="background: #006dcc; color: #ffff" @click="urls">
              <!-- 关联个人邮箱 -->
              {{ $t("label.emailobject.wizard.btn1") }}
            </el-button>
            <!-- <el-button style="margin-left: 50px"> -->
            <!-- 已关联，去发邮箱 -->
            <!-- {{ $t('label.emailobject.wizard.btn2') }} -->
            <!-- </el-button> -->
          </div>
        </div>
        <div v-else>
          <div class="newTask">
            <!-- 电子邮件 -->
            <el-input
              :placeholder="$t('label.email')"
              v-if="isnewTask"
              @focus="focus('Email')"
            ></el-input>
          </div>
          <div class="conent emails" v-if="isConent">
            <el-form
              :rules="callss"
              :model="email"
              ref="ruleForm"
              class="demo-ruleForm"
            >
              <div class="shou">
                <!-- 发件人 -->
                <el-form-item
                  :label="$t('label.senduser')"
                  prop="emailFrom"
                  label-width="70px"
                >
                  <el-select
                    v-model="emailFrom"
                    style="width: 100%; padding: 0"
                  >
                    <el-option
                      v-for="item in emaillist"
                      :key="item.id"
                      :label="item.syncmail"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <!-- 收件人 -->
                <el-form-item
                  :label="$t('component_sendmail_label_touser')"
                  class="emails_addressee"
                  prop="address"
                  label-width="70px"
                >
                  <div class="bcc">
                    <span
                      @click="ccaddresses"
                      v-if="isCcaddress === true ? false : true"
                    >
                      <!-- 抄送 -->
                      {{ $t("label.email.cc") }}
                    </span>
                    <span
                      @click="bccaddresses"
                      v-if="isBccaddress === true ? false : true"
                    >
                      <!-- 密送 -->
                      {{ $t("vue_label_commonobjects_detail_bcc") }}
                    </span>
                  </div>

                  <multiselect
                    ref="multiselect"
                    class="multiselects"
                    v-model="seahcvalue"
                    :hide-selected="true"
                    :options="countries"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="true"
                    :preserveSearch="true"
                    :custom-label="customLabel"
                    placeholder=""
                    label="name"
                    track-by="id"
                    :preselect-first="true"
                    @search-change="remoteMethod"
                    @keyup.enter.native="addmytag"
                    @close="addtagtest"
                    tag-position="top"
                    :taggable="false"
                    tagPlaceholder
                    :showNoResults="true"
                    selectLabel
                    @open="open"
                  >
                    <span slot="noOptions"></span>
                    <span slot="noResult" class="noResult"></span>
                    <template slot="option" slot-scope="props">
                      <div>
                        {{ props.option.value }}
                        <span> &lt;</span>
                        {{ props.option.name }}
                        <span>&gt;</span>
                      </div>
                    </template>
                  </multiselect>
                </el-form-item>
                <!-- 抄送人 -->
                <el-form-item
                  :label="$t('label.mobile.phone.cc.people')"
                  class="emails_addressee chao"
                  v-if="isCcaddress"
                  label-width="70px"
                >
                  <multiselect
                    ref="multiselects"
                    v-model="seahcvalues"
                    :hide-selected="true"
                    :options="countriess"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="true"
                    :preserveSearch="true"
                    selectLabel
                    :custom-label="customLabel"
                    placeholder=""
                    label="name"
                    track-by="id"
                    :preselect-first="true"
                    @search-change="remoteMethods"
                    @keyup.enter.native="addmytagtwo"
                    tag-position="top"
                    :taggable="false"
                    :showNoResults="true"
                    @open="opens"
                    @close="addtagtests"
                  >
                    <span slot="noOptions"></span>
                    <span slot="noResult" class="noResult"></span>
                    <template slot="option" slot-scope="props">
                      <div>
                        {{ props.option.value }}
                        <span> &lt;</span>
                        {{ props.option.name }}
                        <span>&gt;</span>
                      </div>
                    </template>
                  </multiselect>
                </el-form-item>
                <!-- 密送人 -->
                <el-form-item
                  :label="$t('vue_label_email_cc')"
                  class="emails_addressee chao"
                  v-if="isBccaddress"
                  label-width="70px"
                >
                  <multiselect
                    ref="multiselectes"
                    v-model="seahcvaluesn"
                    :hide-selected="true"
                    :options="countriessn"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="true"
                    :preserve-search="true"
                    :preserveSearch="true"
                    selectLabel=""
                    :custom-label="customLabel"
                    placeholder=""
                    label="name"
                    track-by="id"
                    :preselect-first="true"
                    @search-change="remoteMethodes"
                    @keyup.enter.native="addmytagthree"
                    tag-position="top"
                    :taggable="false"
                    :showNoResults="true"
                    @open="openes"
                    @close="addtagtestes"
                  >
                    <span slot="noOptions"></span>
                    <span slot="noResult" class="noResult"></span>
                    <template slot="option" slot-scope="props">
                      <div>
                        {{ props.option.value }}
                        <span> &lt;</span>
                        {{ props.option.name }}
                        <span>&gt;</span>
                      </div>
                    </template>
                  </multiselect>
                </el-form-item>
                <!-- 主题 -->
                <el-form-item
                  :label="$t('component_telerecord_label_subject')"
                  style="margin-bottom: 10px"
                  prop="theme"
                  label-width="70px"
                >
                  <!-- 请输入主题 -->
                  <el-input
                    v-model="email.theme"
                    :placeholder="$t('label_chatter_theme')"
                    style="width: 100%"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <!-- 邮件编辑 -->
              <div>
                <wangEditor
                  ref="wangEditor"
                  :emailInfo="emailInfo"
                  @editorContent="editorContent"
                >
                  <!-- :contents="content" -->
                  <!--  -->
                </wangEditor>
              </div>
              <!-- 邮件下的按钮 -->
              <div style="height: 50px; position: relative">
                <el-row class="rowButton">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('label.emailobject.trackemailopening')"
                    placement="top-start"
                  >
                    <svg class="buttonSvg" aria-hidden="true" @click="ids('1')">
                      <use
                        :href="email.istrackopen === '1' ? tracking : trackings"
                        x="6"
                        y="6"
                        width="15"
                        height="15"
                      ></use>
                    </svg>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('label.ems.attachfiles')"
                    placement="top-start"
                  >
                    <svg
                      class="buttonSvg"
                      aria-hidden="true"
                      @click.prevent="add"
                      @mouseover="changSvgOver('addassembly')"
                      @mouseout="changSvgOut('addassembly')"
                    >
                      <use
                        :href="appendixSvg"
                        x="7"
                        y="7"
                        width="13"
                        height="13"
                      ></use>
                    </svg>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('vue_label_email_insertmergefield')"
                    placement="top-start"
                  >
                    <el-popover
                      placement="top-end"
                      v-model="visibles"
                      width="670"
                      trigger="click"
                    >
                      <inseart
                        ref="ist"
                        :objectApis="objectApi"
                        :prefix="prefix"
                        :tabName="tabName"
                        :objId="objId"
                      >
                      </inseart>
                      <div
                        style="
                          padding: 20px;
                          text-align: right;
                          border-top: 1px solid #d8d8d8;
                          margin-top: 50px;
                        "
                      >
                        <span slot="footer" class="dialog-footer">
                          <el-button size="mini" @click="visibles = !visibles">
                            <!-- 取消 -->
                            {{ $t("button_partner_cancel") }}
                          </el-button>
                          <el-button
                            size="mini"
                            class="determine"
                            @click="insertFieldsMethod"
                          >
                            <!-- 确定 -->
                            {{ $t("label.ems.confirm") }}
                          </el-button>
                        </span>
                      </div>

                      <span slot="reference">
                        <svg
                          class="buttonSvg"
                          aria-hidden="true"
                          @click="ids('3')"
                          @mouseover="changSvgOver('insert')"
                          @mouseout="changSvgOut('insert')"
                        >
                          <use
                            :href="insertSvg"
                            x="6"
                            y="6"
                            width="15"
                            height="15"
                          ></use>
                        </svg>
                      </span>
                    </el-popover>
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('label.select.template')"
                    placement="top-start"
                  >
                    <!-- <el-popover
                      placement="top-end"
                      width="283"
                      trigger="hover"
                      v-model="temp"
                    >
                      <span slot="reference"> -->
                    <svg
                      class="buttonSvg"
                      aria-hidden="true"
                      @click="Manages"
                      @mouseover="changSvgOver('administration')"
                      @mouseout="changSvgOut('administration')"
                    >
                      <use
                        :href="administrationSvg"
                        x="6"
                        y="6"
                        width="15"
                        height="15"
                      ></use>
                    </svg>
                    <!-- </span> -->
                    <!-- </el-popover> -->
                  </el-tooltip>

                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('label.emailobject.signature')"
                    placement="top-start"
                  >
                    <el-popover
                      placement="top-end"
                      popper-class="Autograph_padding"
                      v-model="Autograph"
                    >
                      <div>
                        <Autograph
                          ref="Autograph"
                          @Autographes="Autographes"
                        ></Autograph>
                      </div>
                      <!-- <el-button class="editor" slot="reference"></el-button> -->
                      <span slot="reference">
                        <svg
                          class="buttonSvg"
                          aria-hidden="true"
                          @mouseover="changSvgOver('editor')"
                          @mouseout="changSvgOut('editor')"
                        >
                          <use
                            :href="editorSvg"
                            x="6"
                            y="6"
                            width="15"
                            height="15"
                          ></use>
                        </svg>
                      </span>
                    </el-popover>
                  </el-tooltip>

                  <!-- 日程预约 -->
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('vue_label_normal_appointment_sf')"
                    placement="top-start"
                  >
                    <el-popover
                      placement="top-end"
                      popper-class="Autograph_padding"
                      v-model="isSchedule"
                      trigger="click"
                    >
                      <div>
                        <schedule
                          ref="schedule"
                          @scheduleCencel="scheduleCencel"
                          @saveSchedule="saveSchedule"
                        ></schedule>
                      </div>
                      <span slot="reference" @click="schedule">
                        <svg
                          class="buttonSvg"
                          aria-hidden="true"
                          @mouseover="changSvgOver('schedule')"
                          @mouseout="changSvgOut('schedule')"
                        >
                          <use
                            :href="scheduleSvg"
                            x="6"
                            y="6"
                            width="15"
                            height="15"
                          ></use>
                        </svg>
                      </span>
                    </el-popover>
                  </el-tooltip>
                </el-row>
                <div style="clear: both"></div>
              </div>
            </el-form>
            <div class="enclosure" v-if="isEnclosure">
              <el-tag
                closable
                type="info"
                v-for="(item, index) in enclosure"
                :key="index"
                @close="handleClose(item)"
              >
                <svg class="ic" aria-hidden="true">
                  <use href="#icon-collection"></use>
                </svg>
                <span
                  style="
                    width: 245px;
                    display: inline-block;
                    height: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 40px;
                    margin-left: 10px;
                  "
                >
                  {{ item.fileName }}</span
                >
              </el-tag>
            </div>
            <div class="related">
              <label style="padding-top: 10px">
                <!-- 相关项 -->
                {{ $t("component_telerecord_label_related") }}
              </label>
              <div class="inputWrapper">
                <div style="margin-top: 6px; position: relative">
                  <!-- 请输入内容 -->
                  <el-input
                    disabled
                    :placeholder="$t('label_tabpage_contentz')"
                    v-model="shortMessage.labelValue"
                    class="input-with-select"
                  >
                    <!-- 请选择 -->
                    <el-select
                      v-model="shortMessage.select"
                      slot="prepend"
                      :disabled="
                        objectApi !== 'Lead' &&objectApi !== 'cloudcclead' && objectApi !== 'Contact'
                          ? true
                          : false
                      "
                      :placeholder="$t('label_tabpage_pleaseSelectz')"
                    >
                      <el-option
                        v-for="(item, index) in cities"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                        @click.native="optiones(item)"
                      >
                        <span style="float: left">{{ item.label }}</span>
                      </el-option>
                    </el-select>
                    <el-button
                      v-if="
                        objectApi === 'Lead'  ||objectApi === 'cloudcclead'  ||objectApi === 'Contact'
                          ? true
                          : false
                      "
                      slot="append"
                      @click="remoteSearchOwner()"
                      icon="el-icon-search"
                    ></el-button>
                  </el-input>
                  <el-button
                    class="fasong"
                    @click="submits"
                    :loading="isSending"
                  >
                    <!-- 发送 -->
                    {{ $t("label_chatter_send") }}
                  </el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 短信 -->
      <el-tab-pane
        :label="$t('label.setupsms')"
        name="message"
        v-if="objectApi === 'Lead' || objectApi === 'cloudcclead' ||objectApi === 'Contact'"
      >
        <!-- 短信 -->
        <el-input
          :placeholder="$t('label.setupsms')"
          v-if="ismessage"
          @focus="focus('message')"
        ></el-input>
        <div style="width: 100%; height: 340px" class="short" v-else>
          <el-form class="shor_form">
            <!-- 收件人 -->
            <el-form-item
              :label="$t('component_sendmail_label_touser')"
              prop="name"
            >
              <!-- 请输入内容 -->
              <el-input
                :placeholder="$t('label_tabpage_contentz')"
                v-model="note.addressee"
                class="duanxin"
                @focus="searches"
              >
                <i class="el-icon-search" slot="suffix" @click="searches"> </i>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input type="textarea" v-model="note.content"> </el-input>
            </el-form-item>
          </el-form>
          <div class="label">
            <el-popover
              placement="top-start"
              width="285"
              trigger="click"
              popper-class="popover_template"
              v-model="visibleMessage"
            >
              <div style="height: 300px">
                <!-- 请输入内容 -->
                <el-input
                  v-model="note.inputValue"
                  :placeholder="$t('label_tabpage_contentz')"
                  @input="querySearchAsync"
                ></el-input>
                <div
                  style="
                    width: 259px;
                    height: 183px;
                    border-radius: 3px;
                    border: 1px solid #d8d8d8;
                    margin-top: 20px;
                    padding: 10px;
                    overflow: auto;
                  "
                >
                  <p
                    v-for="(item, index) in smsTemplate"
                    :key="index"
                    style="cursor: pointer"
                    @click="smsTemplateDetail(item)"
                  >
                    {{ item.name }}
                  </p>
                </div>
              </div>
              <span slot="reference" class="template">
                <svg
                  class="templateSvg"
                  aria-hidden="true"
                  @mouseover="changSvgOver('template')"
                  @mouseout="changSvgOut('template')"
                >
                  <use
                    :href="templateSvg"
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                  ></use>
                </svg>
              </span>
              <!-- <i slot="reference" class="template"></i> -->
            </el-popover>

            <div class="expression-img">
              <VueEmojiBox
                :baseUrl="baseUrl"
                :customEmojis="emojis"
                :customCategories="emojiCategory"
                height="200px"
                @change="bindChange"
                label=" "
                :visible="false"
              />
            </div>

            <div style="clear: both"></div>
          </div>

          <div class="related">
            <label>
              <!-- 相关项 -->
              {{ $t("component_telerecord_label_related") }}
            </label>
            <div class="inputWrapper">
              <div style="margin-top: 6px; position: relative">
                <!-- 请输入内容 -->
                <el-input
                  disabled
                  :placeholder="$t('label_tabpage_contentz')"
                  v-model="note.labelValue"
                  class="input-with-select"
                >
                  <!-- 请选择 -->
                  <el-select
                    v-model="note.select"
                    slot="prepend"
                    :disabled="
                      objectApi !== 'Lead'  && objectApi !== 'cloudcclead'  &&objectApi !== 'Contact'
                        ? true
                        : false
                    "
                    :placeholder="$t('label_tabpage_pleaseSelectz')"
                  >
                    <el-option
                      v-for="(item, index) in cities"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                      @click.native="optiones(item)"
                    >
                      <span style="float: left">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                  <el-button
                    v-if="
                      objectApi === 'Lead' ||objectApi === 'cloudcclead' || objectApi === 'Contact'
                        ? true
                        : false
                    "
                    slot="append"
                    @click="remoteSearchOwner()"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
                <el-button class="fasong" @click="nodeSend">
                  <!-- 发送 -->
                  {{ $t("label_chatter_send") }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <!-- 生成文档 -->
      <el-tab-pane name="generateFile" :label="$t('label.Generate.Document')">
        <div class="generate-box" v-loading="generateLoading">
          <div class="generate-Title">
            {{ $t("label.emailmerge.mergeFolder.emailmerge") }}
          </div>
          <div class="generate-tip">
            {{ $t("c1051") }}
          </div>
          <div class="generate-line"></div>
          <div class="generate-record">
            {{ $t("label.emailmerge.mergepage.step1") }}
          </div>
          <div class="generate-recordBox">
            <el-select
              v-model="generateRecord"
              disabled
              suffix-icon="el-icon-search"
              popper-class="generate-recordData"
              :placeholder="$t('label.emailtocloudcc.select1')"
            >
              <!-- 邮件模板优化：取消新建和选择记录，只能合并当前记录 -->
              <!-- <div class="generateTipBox">
                <span
                  @click="getCustomer(item)"
                  style="display: inline-block; width: 100%"
                >
                  <i class="el-icon-search"></i>
                  <span style="padding-left: 10px">
                    {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
                  </span>
                </span>
              </div> -->
              <!-- <div class="generateTipBox" @click="newSearchableField()">
                <i class="el-icon-plus"></i>
                <span>
                  {{ $t("label.new") }}
                </span>
              </div> -->
              <!-- <el-option
                v-show="false"
                key="new"
                :label="$t('label.new')"
                :value="$t('label.new')"
              >
              </el-option> -->
              <el-option
                v-for="item in generateRecordList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="generate-record">
            {{ $t("label.emailmerge.mergepage.step2") }}
          </div>
          <div class="generate-FileBox">
            <div class="generate-FileBox">
              {{ $t("report.label.folder") }}
            </div>
            <el-select
              popper-class="emplateFile"
              @change="changeFolderTemplate"
              v-model="generateFileChoose"
              clearable
              :placeholder="$t('label.emailtocloudcc.select1')"
            >
              <el-option
                v-for="item in generateFolderlist"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="generate-table">
            <el-table
              ref="multipleTable"
              :data="generateObjlist"
              tooltip-effect="dark"
              :empty-text="$t('vue_label_SMS_nodata')"
              style="width: 100%; font-size: 12px; color: #666666"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column
                prop="name"
                :label="$t('label.emailtocloudcc.name')"
              >
              </el-table-column>
              <el-table-column
                prop="filename"
                :label="$t('label.import.page2.filedname')"
              >
              </el-table-column>
              <el-table-column
                prop="createuser"
                :label="$t('label.createname')"
              >
              </el-table-column>
              <el-table-column
                prop="filesize"
                :label="$t('label.addattachment.size')"
              >
              </el-table-column>
            </el-table>
          </div>
          <!-- 文件类型 -->
          <div class="generate-records">
            <div class="generate-record-title">{{ $t("c1041") }}</div>
            <div class="generate-record-checkbox">
              <el-radio-group v-model="chooseFileType">
                <el-radio
                  v-for="item in fileTypeList"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- 文件保存方式 -->
          <div class="generate-records">
            <div class="generate-record-title">{{ $t("c1042") }}</div>
            <div class="generate-record-checkbox">
              <el-radio-group v-model="documentSaving">
                <el-radio
                  v-for="item in documentSaveList"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="generate-record">
            5. <el-checkbox v-model="isrecordactivity"></el-checkbox>
            {{ $t("label.emailmerge.mergepage.step3") }}
          </div>
          <div class="generate-btn">
            <el-button
              type="primary"
              :loading="generatebtnLoading"
              @click="generateDocument()"
            >
              {{ $t("label.emailmerge.mergepage.generator") }}
            </el-button>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="text_right">
      <span @click="refresh">
        <!-- 刷新 -->
        {{ $t("label.dashboard.refresh") }}
      </span>
      <span>·</span>
      <span @click="expandAll">
        <!-- 全部展开 -->
        {{
          isExpandAll === false ? $t("label.allunfold") : $t("label.allfold")
        }}
      </span>
    </div>
    <!-- 即将到来和逾期 -->
    <div class="comingSoon comingSoones">
      <div class="header">
        <i
          :class="[keys === 1 ? 'iconjiantou-you' : 'iconxiajiantou-changtai']"
          class="icons iconfont"
          @click="shouqi(1)"
        ></i>
        <span class="titles">
          <!-- 即将到来和逾期 -->
          {{ $t("vue_label_commonobjects_detail_and_overdue") }}
        </span>
      </div>
      <div
        style="width: 100%; margin-top: -12px; padding-top: 10px"
        v-show="show1"
      >
        <div
          ref="Boxes"
          style="
            width: 100%;
            overflow-y: auto;
            min-height: 75px;
            margin-top: 5px;
            font-size: 12px;
          "
          v-loading="straining"
        >
          <div class="timeline">
            <el-timeline v-if="newActivities.length > 0">
              <el-timeline-item
                v-for="(activity, index) in newActivities"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :class="activity.class"
                :color="activity.color"
                :size="activity.size"
              >
                <span
                  :class="[
                    isExpandAll === true
                      ? 'iconxiajiantou-changtai'
                      : indexs == index
                      ? 'iconxiajiantou-changtai'
                      : 'iconjiantou-you',
                  ]"
                  class="icons iconfont iconfonts"
                  @click.prevent="hovers(index, activity.id)"
                ></span>
                <div class="del">
                  <!-- 改为flex，保证在一行 -->
                  <div class="flexbox">
                    <div class="title">
                      <el-checkbox-group v-model="checkList">
                        <el-checkbox
                          type="checkbox"
                          style="margin-right: 10px"
                          v-if="
                            activity.istask === '1' &&
                            activity.taskoreventtype !==
                              $t(
                                'label.globaloperation.operationtype.record.phone'
                              ) &&
                            activity.taskoreventtype != 'logcall'
                              ? true
                              : false
                          "
                          @change="checks($event, activity, 'soon')"
                        ></el-checkbox>
                      </el-checkbox-group>

                      <!-- <el-link
                        type="primary"
                        class="link"
                        @click="routeres(activity)"
                        ><div class="content">{{ activity.subject }}</div></el-link
                      > -->
                      <el-tooltip
                        :content="
                          activity.type === 'sms'
                            ? $t('label.setupsms')
                            : activity.subject
                        "
                        placement="top-start"
                      >
                        <div
                          class="content"
                          @click="routeres(activity)"
                          v-if="activity.type !== 'sms'"
                        >
                          <!-- 非短信活动 -->
                          <span class="contentspan blueclass">{{
                            activity.subject
                          }}</span>
                        </div>
                        <div class="content" v-if="activity.type === 'sms'">
                          <!-- 短信活动 -->
                          <span class="contentspan">{{
                            $t("label.setupsms")
                          }}</span>
                          <!-- 短信图标 -->
                          <svg
                            v-if="activity.sendstatus === '-1'"
                            class="iconSms"
                            aria-hidden="true"
                          >
                            <use href="#icon-shibai"></use>
                          </svg>
                        </div>
                      </el-tooltip>
                    </div>

                    <div style="display: flex">
                      <!-- 邮件跟踪 -->
                      <p
                        v-if="activity.type === 'email' ? true : false"
                        style="margin-right: 10px"
                      >
                        <!-- 发送的邮件 -->
                        <span
                          v-if="
                            activity.incoming === '0' &&
                            activity.istrackopen === '1'
                              ? true
                              : false
                          "
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            placement="top-start"
                          >
                            <div slot="content">
                              {{
                                $t("label.emailobject.track.times", {
                                  0: activity.opentimes,
                                })
                              }}
                              <!-- 打开{{ activity.opentimes }}次 -->
                              <br />{{ activity.lastopendate }}
                            </div>
                            <!-- 打开次数大于1为蓝色，否则是灰色 -->
                            <svg
                              class="iconReply"
                              aria-hidden="true"
                              width="15"
                              height="15"
                              viewBox="0,0,15,15"
                            >
                              <use
                                :href="
                                  activity.opentimes > 0 ? tracking : trackings
                                "
                              ></use>
                            </svg>
                          </el-tooltip>
                        </span>
                      </p>
                      <!-- 时间 -->
                      <!-- display:flex：防止即将到来和已逾期中时间和右侧图标不对齐 -->
                      <span
                        style="display: flex; align-items: center"
                        :class="
                          timestamp > activity.expiredate ? 'colorRed' : ''
                        "
                      >
                        <!-- 短信时间 -->
                        <span
                          v-if="activity.type === 'sms' && !!activity.emaildate"
                          >{{ activity.emaildate | formatTime(timeZone) }}</span
                        >
                        <span
                          v-if="activity.istask === '0' && activity.begintime"
                          >{{ activity.begintime | monthDay(timeZone) }}</span
                        >
                        <span
                          v-if="activity.type === 'email' && activity.emaildate"
                          >{{ activity.emaildate | formatTime(timeZone) }}</span
                        >
                        <span
                          v-if="
                            activity.istask === '0' &&
                            activity.begintime === undefined
                              ? true
                              : false
                          "
                          >{{
                            $t(
                              "vue_label_commonobjects_detail_activity_nostarttime"
                            )
                          }}</span
                        >
                        <span
                          v-if="
                            activity.istask === '1' && activity.expiredateformat
                          "
                        >
                          <!-- {{activity.expiredate | monthDay(countryCode) }} -->
                          <!-- 到期时间无法做多时区，为保持展示时间一致，使用时间字符串 -->
                          {{ activity.expiredateformat.slice(-5) }}
                        </span>
                        <!-- 无到期日期 -->
                        <span
                          v-if="
                            activity.istask === '1' &&
                            activity.expiredate === undefined
                              ? true
                              : false
                          "
                          >{{
                            $t(
                              "vue_label_commonobjects_detail_activity_noexpirationdate"
                            )
                          }}</span
                        >

                        <el-dropdown
                          @command="handleCommand"
                          v-if="activity.type !== 'sms'"
                        >
                          <svg class="iconReply xiala" aria-hidden="true">
                            <use href="#icon-xiala"></use>
                          </svg>
                          <el-dropdown-menu slot="dropdown">
                            <!-- <el-dropdown-item command="a">更改日期</el-dropdown-item>
                      <el-dropdown-item command="b">更改状态</el-dropdown-item>
                      <el-dropdown-item command="c">更改优先级</el-dropdown-item> -->
                            <el-dropdown-item
                              v-if="activity.type === 'email' ? false : true"
                              :command="{ type: 'd', id: activity }"
                            >
                              <!-- 编辑 -->
                              {{ $t("pagecreator_page_button_edit") }}
                            </el-dropdown-item>
                            <el-dropdown-item
                              :command="{ type: 'e', id: activity }"
                            >
                              <!-- 删除 -->
                              {{ $t("component_setup_tabs_label_delete") }}
                            </el-dropdown-item>
                            <!-- <el-dropdown-item command="f">创建跟进事件</el-dropdown-item>
                      <el-dropdown-item command="g">创建跟进任务</el-dropdown-item> -->
                          </el-dropdown-menu>
                        </el-dropdown>
                      </span>
                    </div>
                  </div>
                  <p
                    v-if="activity.istask === '0' ? true : false"
                    style="margin-bottom: 0px"
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      >
                    </span>
                    {{
                      $t(
                        "vue_label_commonobjects_detail_activity_commingevents"
                      )
                    }}
                    <!-- 拥有即将进行的事件 -->
                  </p>
                  <p
                    v-if="activity.type === 'email' ? true : false"
                    class="pClass"
                  >
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, listInfo.userName)"
                      v-if="listInfo.userId === activity.ownerId ? true : false"
                      >{{ $t("vue_label_commonobjects_detail_you") }}</span
                    >
                    <!-- 您 -->
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, activity.fromaddress)"
                      v-else-if="activity.incoming === '1' ? true : false"
                      >{{ activity.fromaddress }}</span
                    >
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, activity.formname)"
                      v-else-if="activity.incoming === '0' ? true : false"
                      >{{ activity.formname }}</span
                    >
                    <span class="paddingClass">{{
                      $t("vue_label_email_sentto")
                    }}</span>
                    <span v-if="activity.incoming === '0' ? true : false">{{
                      activity.toaddress
                    }}</span>
                    <span v-if="activity.incoming === '1' ? true : false">{{
                      activity.formname
                    }}</span>
                    <span class="paddingClass">{{
                      $t("vue_label_commonobjects_detail_an_email")
                    }}</span>
                    <!-- 发送一封电子邮件 -->
                  </p>
                  <!-- 短信 -->
                  <p v-if="activity.type === 'sms'" class="pClass">
                    <!-- <span
                      class="colores"
                      @click="routers(activity.ownerId)"
                      v-if="listInfo.userId === activity.ownerId"
                      >{{ $t("vue_label_commonobjects_detail_you") }}</span
                    > -->
                    <!-- 您 -->
                    <!-- XXX收到一封短信 -->
                    <span class="coloreses" v-if="activity.incoming === '0'"
                      >{{ activity.name ? activity.name : activity.toaddress }}
                      <span class="paddingClass">{{
                        $t("label.sms.toaddress")
                      }}</span></span
                    >

                    <!-- XXX发送一封短信 -->
                    <span
                      class="coloreses"
                      v-else-if="activity.incoming === '1'"
                      >{{ activity.name ? activity.name : activity.fromaddress
                      }}<span class="paddingClass">{{
                        $t("label.sent.sms")
                      }}</span>
                    </span>
                  </p>
                  <!-- 拥有即将进行的任务-->
                  <p
                    v-if="
                      activity.istask === '1' &&
                      activity.taskoreventtype !==
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) &&
                      activity.taskoreventtype != 'logcall'
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{
                      $t("vue_label_commonobjects_detail_activity_commingtask")
                    }}
                  </p>
                  <!-- 记录电话 -->
                  <p
                    v-if="
                      activity.istask === '1' &&
                      (activity.taskoreventtype ===
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) ||
                        activity.taskoreventtype === 'logcall')
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      >
                    </span>
                    <!-- 已记录呼叫 -->
                    {{ $t("label_chatter_recordcall") }}
                  </p>
                </div>

                <el-card
                  v-if="
                    isExpandAll === true ? true : indexs == index ? true : false
                  "
                  style="min-height: 100px; padding: 10px 10px 10px 10px"
                >
                  <div v-if="activity.istask === '0' ? true : false">
                    <div style="display: flex">
                      <span style="margin-right: 100px">
                        <p>
                          <!-- 开始 -->
                          {{ $t("label_tabpage_beginz") }}
                        </p>
                        <p>
                          {{ activity.begintime | formatTimess(timeZone) }}
                        </p>
                      </span>
                      <span >
                        <p>
                          <!-- 结束 -->
                          {{ $t("label_tabpage_endz") }}
                        </p>
                        <p>
                          {{ activity.endtime | formatTimess(timeZone) }}
                        </p>
                      </span>
                    </div>
                    <div v-if="activity.remark">
                      <p>
                        <!-- 内容 -->
                        {{ $t("label.content") }}
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div v-if="activity.istask === '1' && activity.remark">
                    <div>
                      <p>
                        <!-- 内容 -->
                        {{ $t("label.content") }}
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div v-if="activity.type === 'email' ? true : false">
                    <div
                      class="emailbox1"
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人邮件地址 -->
                          {{ $t("label_tabpage_emailaddressz") }}:
                        </span>
                        <br />
                        <span>{{ activity.fromaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人地址 -->
                          <!-- {{
                            $t("vue_label_commonobjects_detail_email_address")
                          }}: -->
                          <!-- 收件人邮件地址 -->
                          {{
                            $t(
                              "vue_label_commonobjects_detail_email_newaddress"
                            )
                          }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.toaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 抄送人 -->
                          {{ $t("label.mobile.phone.cc.people") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.ccaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 密送人 -->
                          {{ $t("vue_label_email_cc") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p>
                      <div>
                        <span>
                          <!-- 邮件正文 -->
                          {{ $t("label.emailmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.content"
                        ></div>
                      </div>
                    </div>
                    <div class="backEmail">
                      <p style="margin-right: 35px">
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-reply"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'reply')"
                          style="cursor: pointer"
                        >
                          <!-- 回复 -->
                          {{ $t("label.emailobject.emaildetail.button.reply") }}
                        </span>
                      </p>
                      <p>
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-forward"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'forward')"
                          style="cursor: pointer"
                        >
                          <!-- 转发      -->
                          {{
                            $t("label.emailobject.emaildetail.button.forward")
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <!-- 短信 -->
                  <div v-if="activity.type === 'sms'">
                    <div
                      class="emailbox1"
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人手机号 -->
                          {{ $t("label.chatter.addresser") }}:
                        </span>
                        <br />
                        <span>{{
                          activity.fromaddressname
                            ? activity.fromaddressname
                            : activity.fromaddress
                        }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人 -->
                          {{ $t("label.chatter.addressee") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{
                          activity.toaddressname
                            ? activity.toaddressname
                            : activity.toaddress
                        }}</span>
                      </p>
                      <!-- <p>
                        <span style="font-weight: bold">

                          {{ $t("label.emailtocloudcc.name") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.name }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">

                          {{ $t("label.relevant.records") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p> -->
                      <div>
                        <span style="font-weight: bold">
                          <!-- 短信正文 -->
                          {{ $t("label.smsmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.htmlbody"
                        ></div>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <p v-if="noMoress" style="text-align: center">
              <svg class="weichuli" aria-hidden="true">
                <use href="#icon-weichuli"></use>
              </svg>

              <span
                style="
                  text-align: center;
                  font-size: 12px;
                  display: block;
                  padding-top: 15px;
                "
              >
                <!-- 恭喜你,没有未处理的事件和任务啦。 -->
                {{ $t("vue_label_commonobjects_detail_and_tasks") }}
              </span>
            </p>

            <div class="feature-box">
              <el-button
                class="button ckgd"
                :loading="featureAndDieLoading"
                @click="seeMoreButton('feature')"
                v-if="
                  newActivities.length == this.activities.length ? false : true
                "
              >
                <!-- 查看更多isgengduo-->
                {{ $t("label_tabpage_lookatmorez") }}...
              </el-button>
              <span
                v-if="
                  (newActivities.length == this.activities.length
                    ? true
                    : false) && featureAndDiePage > 1
                "
              >
                <!-- 暂无更多数据  -->
                {{ $t("mobel_web_not_more_data") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 本月 -->
    <div class="comingSoon" v-if="isNewtasks">
      <div class="header">
        <i
          :class="[keyss === 2 ? 'iconjiantou-you' : 'iconxiajiantou-changtai']"
          class="icons iconfont"
          @click="shouqi(2)"
        ></i>
        <span class="titles">{{ $t("label.brief.thismonth") }}</span>
      </div>
      <div
        style="width: 100%; overflow: hidden; margin-top: -12px"
        v-show="show2"
      >
        <div
          ref="Box"
          style="width: 100%; overflow-y: auto; margin-top: 5px"
          @scroll="orderScroll"
        >
          <div class="timeline">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in monthList"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :class="activity.class"
                :color="activity.color"
                :size="activity.size"
              >
                <i
                  :class="[
                    isExpandAll === true
                      ? 'iconxiajiantou-changtai'
                      : indexes == index
                      ? 'iconxiajiantou-changtai'
                      : 'iconjiantou-you',
                  ]"
                  class="icons iconfont iconfonts"
                  @click.prevent="hoveres(index, activity.id)"
                ></i>
                <div class="del">
                  <!-- 改为flex，保证在一行 -->
                  <div class="flexbox">
                    <div class="title">
                      <el-tooltip
                        :content="
                          activity.type === 'sms'
                            ? $t('label.setupsms')
                            : activity.subject
                        "
                        placement="top-start"
                      >
                        <div
                          class="content"
                          @click="routeres(activity)"
                          v-if="activity.type !== 'sms'"
                        >
                          <span
                            class="contentspan"
                            :style="
                              activity.type === 'email' ? '' : 'color: #006dcc;'
                            "
                            >{{ activity.subject }}</span
                          >
                        </div>
                        <div class="content" v-if="activity.type === 'sms'">
                          <!-- 短信活动 -->
                          <span class="contentspan">{{
                            $t("label.setupsms")
                          }}</span>
                          <!-- 短信图标 -->
                          <svg
                            v-if="activity.sendstatus === '-1'"
                            class="iconSms"
                            aria-hidden="true"
                          >
                            <use href="#icon-shibai"></use>
                          </svg>
                        </div>
                      </el-tooltip>
                    </div>

                    <!-- 邮件时间 -->
                    <div style="display: flex">
                      <!-- 邮件跟踪 -->
                      <p
                        v-if="activity.type === 'email' ? true : false"
                        style="margin-right: 10px"
                      >
                        <!-- 邮件跟踪显示 -->
                        <span
                          v-if="
                            activity.incoming === '0' &&
                            activity.istrackopen === '1'
                              ? true
                              : false
                          "
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            placement="top-start"
                          >
                            <div slot="content">
                              {{
                                $t("label.emailobject.track.times", {
                                  0: activity.opentimes,
                                })
                              }}
                              <br />{{ activity.lastopendate }}
                            </div>
                            {{ activity.opentimes }}
                            <!-- 打开次数大于1为蓝色，否则是灰色 -->
                            <svg
                              class="iconReply"
                              aria-hidden="true"
                              width="15"
                              height="15"
                              viewBox="0,0,15,15"
                            >
                              <use
                                :href="
                                  activity.opentimes > 0 ? tracking : trackings
                                "
                              ></use>
                            </svg>
                          </el-tooltip>
                        </span>
                      </p>
                      <!-- 时间 -->
                      <!-- 短信时间 -->
                      <span
                        v-if="activity.type === 'sms' && !!activity.emaildate"
                        >{{ activity.emaildate | formatTime(timeZone) }}</span
                      >
                      <span
                        v-if="activity.istask === '0' && activity.begintime"
                        >{{ activity.begintime | monthDay(timeZone) }}</span
                      >
                      <span
                        v-if="activity.type === 'email' && activity.emaildate"
                        >{{ activity.emaildate | formatTime(timeZone) }}</span
                      >
                      <span
                        v-if="
                          activity.istask === '0' &&
                          activity.begintime === undefined
                            ? true
                            : false
                        "
                        >{{
                          $t(
                            "vue_label_commonobjects_detail_activity_nostarttime"
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          activity.istask === '1' && activity.expiredateformat
                        "
                      >
                        <!-- {{ activity.expiredate | monthDay(countryCode) }} -->
                        <!-- 到期时间无法做多时区，为保持展示时间一致，使用时间字符串 -->
                        {{ activity.expiredateformat.slice(-5) }}
                      </span>
                      <!-- 无到期日期 -->
                      <span
                        v-if="
                          activity.istask === '1' &&
                          activity.expiredate === undefined
                            ? true
                            : false
                        "
                        >{{
                          $t(
                            "vue_label_commonobjects_detail_activity_noexpirationdate"
                          )
                        }}</span
                      >
                      <!-- 无到期日期 -->
                      <el-dropdown
                        @command="handleCommand"
                        v-if="activity.type !== 'sms'"
                      >
                        <svg class="iconReply xiala" aria-hidden="true">
                          <use href="#icon-xiala"></use>
                        </svg>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            v-if="activity.type === 'email' ? false : true"
                            :command="{ type: 'd', id: activity }"
                          >
                            <!-- 编辑 -->
                            {{ $t("pagecreator_page_button_edit") }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="
                              activity.istask === undefined
                                ? { type: 'e', id: activity, types: 'email' }
                                : { type: 'e', id: activity }
                            "
                          >
                            <!-- 删除 -->
                            {{ $t("component_setup_tabs_label_delete") }}
                          </el-dropdown-item>
                          <!-- <el-dropdown-item command="f">创建跟进事件</el-dropdown-item>
                      <el-dropdown-item command="g">创建跟进任务</el-dropdown-item> -->
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <!-- 短信 -->
                  <p v-if="activity.type === 'sms'" class="pClass">
                    <!-- XXX收到一封短信 -->
                    <span class="coloreses" v-if="activity.incoming === '0'"
                      >{{ activity.name ? activity.name : activity.toaddress
                      }}<span class="paddingClass">{{
                        $t("label.sms.toaddress")
                      }}</span></span
                    >
                    <!-- XXX发送一封短信 -->
                    <span
                      class="coloreses"
                      v-else-if="activity.incoming === '1'"
                      >{{
                        activity.name ? activity.name : activity.fromaddress
                      }}
                      <span class="paddingClass">{{
                        $t("label.sent.sms")
                      }}</span>
                    </span>
                  </p>
                  <p v-if="activity.istask === '0' ? true : false">
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{
                      $t("vue_label_commonobjects_detail_activity_ownevent")
                    }}
                    <!-- 拥有事件 -->
                  </p>
                  <!-- 附件 -->
                  <div v-if="activity.isattachment == '1'" class="file">
                    <span
                      v-for="(files, index) in activity.attachmentname"
                      :key="index"
                      class="filelist"
                      @click="downloadfile(files)"
                    >
                      <svg class="fujian" aria-hidden="true">
                        <use href="#icon-addassemblyblue"></use>
                      </svg>
                      <span class="fileName"
                        >{{ files.fileName }}{{ files.fileSuffix }}</span
                      >
                    </span>
                  </div>
                  <p
                    v-if="activity.type === 'email' ? true : false"
                    class="pClass"
                  >
                    <!-- {{activity.fromaddress}}-{{activity.formname}} -->
                    <!-- {{activity.ownerId}}-{{activity.incoming}}-{{listInfo.userId}} -->
                    <!-- <span
                      class="colores"
                      @click="routers(activity.ownerId)"
                      v-if="listInfo.userId === activity.ownerId ? true : false"
                      >{{ $t('vue_label_commonobjects_detail_you') }}</span
                    > -->
                    <!-- 您 -->
                    <!-- <span
                      class="colores"
                      @click="routers(activity.ownerId)"
                      v-if="
                        activity.ownerId
                          ? false
                          : activity.incoming === '1'
                          ? true
                          : false
                      "
                      >{{ activity.fromaddress }}</span
                    > -->
                    <span>{{ activity.fromaddress }}</span>
                    <!-- <span
                      class="colores"
                      >{{ activity.fromaddress }}</span
                    >
                    <span
                      class="colores"
                      @click="routers(activity.ownerId)"
                      v-else-if="
                        activity.ownerId
                          ? false
                          : activity.incoming === '0'
                          ? true
                          : false
                      "
                      >{{ activity.formname }}</span
                    > -->
                    <span class="paddingClass">{{
                      $t("vue_label_email_sentto")
                    }}</span>
                    <span>{{ activity.toaddress }}</span>
                    <!-- <span v-if="activity.incoming === '0' ? true : false">{{
                      activity.toaddress
                    }}</span>
                    <span v-if="activity.incoming === '1' ? true : false">{{
                      activity.formname
                    }}</span> -->
                    <span class="paddingClass">{{
                      $t("vue_label_commonobjects_detail_an_email")
                    }}</span>
                    <!-- 发送一封电子邮件 -->
                  </p>
                  <p
                    v-if="
                      activity.istask === '1' &&
                      activity.taskoreventtype !==
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) &&
                      activity.taskoreventtype != 'logcall'
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{ $t("vue_label_commonobjects_detail_activity_owntask") }}
                    <!-- 拥有任务 -->
                  </p>
                  <p
                    v-if="
                      activity.istask === '1' &&
                      (activity.taskoreventtype ===
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) ||
                        activity.taskoreventtype === 'logcall')
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{ $t("label_chatter_recordcall") }}
                    <!-- 已记录呼叫 -->
                  </p>
                </div>
                <el-card
                  v-if="
                    isExpandAll === true
                      ? true
                      : indexes == index
                      ? true
                      : false
                  "
                  style="min-height: 100px; padding: 10px 10px 10px 10px"
                >
                  <div v-if="activity.istask === '0' ? true : false">
                    <div style="display: flex">
                      <span style="margin-right: 100px">
                        <p>
                          <!-- 开始 -->
                          {{ $t("label_tabpage_beginz") }}
                        </p>
                        <p>
                          {{ activity.begintime | formatTimess(timeZone) }}
                        </p>
                      </span>
                      <span>
                        <p>
                          <!-- 结束 -->
                          {{ $t("label_tabpage_endz") }}
                        </p>
                        <p>
                          {{ activity.endtime | formatTimess(timeZone) }}
                        </p>
                      </span>
                    </div>
                    <div>
                      <p>
                        <!-- 内容 -->
                        <!-- {{ $t("label.content") }} -->
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div
                    v-if="
                      activity.istask === '1' && !activity.taskoreventtype
                        ? true
                        : false
                    "
                  >
                    <div>
                      <p>
                        <!-- 内容 -->
                        <!-- {{ $t("label.content") }} -->
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div v-if="activity.type === 'email' ? true : false">
                    <div
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人邮件地址 -->
                          {{ $t("label_tabpage_emailaddressz") }}
                          :
                        </span>
                        <br />
                        <span>{{ activity.fromaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人邮件地址: -->
                          {{
                            $t(
                              "vue_label_commonobjects_detail_email_newaddress"
                            )
                          }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.toaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 抄送人 -->
                          {{ $t("label.mobile.phone.cc.people") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.ccaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 密送人 -->
                          {{ $t("vue_label_email_cc") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p>
                      <div>
                        <span>
                          <!-- 邮件正文 -->
                          {{ $t("label.emailmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.content"
                        ></div>
                      </div>
                    </div>
                    <div class="backEmail">
                      <p style="margin-right: 35px">
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-reply"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'reply')"
                          style="cursor: pointer"
                        >
                          <!-- 回复 -->
                          {{ $t("label.emailobject.emaildetail.button.reply") }}
                        </span>
                      </p>
                      <p>
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-forward"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'forward')"
                          style="cursor: pointer"
                        >
                          <!-- 转发      -->
                          {{
                            $t("label.emailobject.emaildetail.button.forward")
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <!-- 短信 -->
                  <div v-if="activity.type === 'sms'">
                    <div
                      class="emailbox1"
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人 -->
                          {{ $t("label.chatter.addresser") }}:
                        </span>
                        <br />
                        <span>{{
                          activity.fromaddressname
                            ? activity.fromaddressname
                            : activity.fromaddress
                        }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人 -->
                          {{ $t("label.chatter.addressee") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{
                          activity.toaddressname
                            ? activity.toaddressname
                            : activity.toaddress
                        }}</span>
                      </p>
                      <!-- 名称和相关记录 -->
                      <!-- <p>
                        <span style="font-weight: bold">

                          {{ $t("label.emailtocloudcc.name") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.name }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">

                          {{ $t("label.relevant.records") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p> -->
                      <div>
                        <span style="font-weight: bold">
                          <!-- 短信正文 -->
                          {{ $t("label.smsmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.htmlbody"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <!-- 记录电话 -->
                  <div
                    v-if="
                      activity.istask === '1' &&
                      (activity.taskoreventtype ===
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) ||
                        activity.taskoreventtype === 'logcall')
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      >
                    </span>
                    <!-- 已记录呼叫 -->
                    {{ $t("label_chatter_recordcall") }}
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <div class="feature-box">
              <el-button
                class="button ckgd"
                :loading="thisMonthLoading"
                @click="seeMoreButton('this')"
                v-if="thisMonthSeeMore"
              >
                <!-- 查看更多 -->
                {{ $t("label_tabpage_lookatmorez") }}...
              </el-button>
              <span v-if="!thisMonthSeeMore && thisMonthPage > 1">
                <!-- 暂无更多数据  -->
                {{ $t("mobel_web_not_more_data") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 上月 -->
    <div class="comingSoon" v-if="isNewtaskss">
      <div class="header">
        <i
          :class="[
            keysss === 3 ? 'iconjiantou-you' : 'iconxiajiantou-changtai',
          ]"
          class="icons iconfont"
          @click="shouqi(3)"
        ></i>
        <span class="titles">{{ $t("label.brief.lastmonth") }}</span>
      </div>
      <div style="width: 100%; margin-top: -12px" v-show="show3">
        <div
          ref="Boxs"
          @scroll="orderScrolls"
          style="width: 100%; overflow-y: auto"
        >
          <div class="timeline">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in lastmonthlist"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :class="activity.class"
                :color="activity.color"
                :size="activity.size"
              >
                <i
                  :class="[
                    isExpandAll === true
                      ? 'iconxiajiantou-changtai'
                      : indexess == index
                      ? 'iconxiajiantou-changtai'
                      : 'iconjiantou-you',
                  ]"
                  class="icons iconfont iconfonts"
                  @click.prevent="hoveress(index, activity.id)"
                ></i>
                <div class="del">
                  <!-- 改为flex，保证在一行 -->
                  <div class="flexbox">
                    <div class="title">
                      <el-tooltip
                        :content="
                          activity.type === 'sms'
                            ? $t('label.setupsms')
                            : activity.subject
                        "
                        placement="top-start"
                      >
                        <div
                          class="content"
                          @click="routeres(activity)"
                          v-if="activity.type !== 'sms'"
                        >
                          <!-- 非短信活动 -->
                          <span class="contentspan">{{
                            activity.subject
                          }}</span>
                        </div>
                        <div class="content" v-if="activity.type === 'sms'">
                          <!-- 短信活动 -->
                          <span class="contentspan">{{
                            $t("label.setupsms")
                          }}</span>
                          <!-- 短信图标 -->
                          <svg
                            v-if="activity.sendstatus === '-1'"
                            class="iconSms"
                            aria-hidden="true"
                          >
                            <use href="#icon-shibai"></use>
                          </svg>
                        </div>
                      </el-tooltip>
                    </div>

                    <div style="display: flex">
                      <!-- 邮件跟踪 -->
                      <p
                        v-if="activity.type === 'email' ? true : false"
                        style="margin-right: 10px"
                      >
                        <!-- 邮件跟踪显示 -->
                        <span
                          v-if="
                            activity.incoming === '0' &&
                            activity.istrackopen === '1'
                              ? true
                              : false
                          "
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            placement="top-start"
                          >
                            <div slot="content">
                              {{
                                $t("label.emailobject.track.times", {
                                  0: activity.opentimes,
                                })
                              }}<br />{{ activity.lastopendate }}
                            </div>
                            <svg
                              class="iconReply"
                              aria-hidden="true"
                              width="15"
                              height="15"
                              viewBox="0,0,15,15"
                            >
                              <use
                                :href="
                                  activity.opentimes > 0 ? tracking : trackings
                                "
                              ></use>
                            </svg>
                          </el-tooltip>
                        </span>
                      </p>
                      <!-- 时间 -->
                      <!-- 短信时间 -->
                      <span
                        v-if="activity.type === 'sms' && !!activity.emaildate"
                        >{{ activity.emaildate | formatTime(timeZone) }}</span
                      >
                      <span
                        v-if="activity.istask === '0' && activity.begintime"
                        >{{ activity.begintime | monthDay(timeZone) }}</span
                      >
                      <span
                        v-if="activity.type === 'email' && activity.emaildate"
                        >{{ activity.emaildate | formatTime(timeZone) }}</span
                      >
                      <span
                        v-if="
                          activity.istask === '0' &&
                          activity.begintime === undefined
                            ? true
                            : false
                        "
                        >{{
                          $t(
                            "vue_label_commonobjects_detail_activity_nostarttime"
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          activity.istask === '1' && activity.expiredateformat
                        "
                      >
                        <!-- {{ activity.expiredate | monthDay(countryCode) }} -->
                        <!-- 到期时间无法做多时区，为保持展示时间一致，使用时间字符串 -->
                        {{ activity.expiredateformat.slice(-5) }}
                      </span>
                      <!-- 无到期日期 -->
                      <span
                        v-if="
                          activity.istask === '1' &&
                          activity.expiredate === undefined
                            ? true
                            : false
                        "
                        >{{
                          $t(
                            "vue_label_commonobjects_detail_activity_noexpirationdate"
                          )
                        }}</span
                      >
                      <!-- 无到期日期 -->
                      <el-dropdown
                        @command="handleCommand"
                        v-if="activity.type !== 'sms'"
                      >
                        <svg class="iconReply xiala" aria-hidden="true">
                          <use href="#icon-xiala"></use>
                        </svg>
                        <el-dropdown-menu slot="dropdown">
                          <!-- <el-dropdown-item command="a">更改日期</el-dropdown-item>
                      <el-dropdown-item command="b">更改状态</el-dropdown-item>
                      <el-dropdown-item command="c">更改优先级</el-dropdown-item> -->
                          <el-dropdown-item
                            v-if="activity.type === 'email' ? false : true"
                            :command="{ type: 'd', id: activity }"
                          >
                            <!-- 编辑 -->
                            {{ $t("pagecreator_page_button_edit") }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="{ type: 'e', id: activity }"
                          >
                            <!-- 删除 -->
                            {{ $t("component_setup_tabs_label_delete") }}
                          </el-dropdown-item>
                          <!-- <el-dropdown-item command="f">创建跟进事件</el-dropdown-item>
                      <el-dropdown-item command="g">创建跟进任务</el-dropdown-item> -->
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <!-- 短信 -->
                  <p v-if="activity.type === 'sms'" class="pClass">
                    <!-- XXX收到一封短信 -->
                    <span class="coloreses" v-if="activity.incoming === '0'"
                      >{{ activity.name ? activity.name : activity.toaddress
                      }}<span class="paddingClass">{{
                        $t("label.sms.toaddress")
                      }}</span></span
                    >
                    <!-- XXX发送一封短信 -->
                    <span
                      class="coloreses"
                      v-else-if="activity.incoming === '1'"
                      >{{ activity.name ? activity.name : activity.fromaddress
                      }}<span class="paddingClass">{{
                        $t("label.sent.sms")
                      }}</span></span
                    >
                  </p>
                  <p v-if="activity.istask === '0' ? true : false">
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{
                      $t("vue_label_commonobjects_detail_activity_ownevent")
                    }}
                    <!-- 拥有事件 -->
                  </p>
                  <!-- 附件 -->
                  <div v-if="activity.isattachment == '1'" class="file">
                    <span
                      v-for="(files, index) in activity.attachmentname"
                      :key="index"
                      class="filelist"
                      @click="downloadfile(files)"
                    >
                      <svg class="fujian" aria-hidden="true">
                        <use href="#icon-addassemblyblue"></use>
                      </svg>
                      <span class="fileName"
                        >{{ files.fileName }}{{ files.fileSuffix }}</span
                      >
                    </span>
                  </div>
                  <p
                    v-if="activity.type === 'email' ? true : false"
                    class="pClass"
                  >
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, listInfo.userName)"
                      v-if="listInfo.userId === activity.ownerId ? true : false"
                      >{{ $t("vue_label_commonobjects_detail_you") }}</span
                    >
                    <!-- 您 -->
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, activity.fromaddress)"
                      v-else-if="activity.incoming === '1' ? true : false"
                      >{{ activity.fromaddress }}</span
                    >
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, activity.formname)"
                      v-else-if="activity.incoming === '0' ? true : false"
                      >{{ activity.formname }}</span
                    >
                    <span class="paddingClass">{{
                      $t("vue_label_email_sentto")
                    }}</span>
                    <span v-if="activity.incoming === '0' ? true : false">{{
                      activity.toaddress
                    }}</span>
                    <span v-if="activity.incoming === '1' ? true : false">{{
                      activity.formname
                    }}</span>
                    <span class="paddingClass">{{
                      $t("vue_label_commonobjects_detail_an_email")
                    }}</span>
                    <!-- 发送一封电子邮件 -->
                  </p>

                  <p
                    v-if="
                      activity.istask === '1' &&
                      activity.taskoreventtype !==
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) &&
                      activity.taskoreventtype != 'logcall'
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{ $t("vue_label_commonobjects_detail_activity_owntask") }}
                    <!-- 拥有任务 -->
                  </p>
                  <p
                    v-if="
                      activity.istask === '1' &&
                      (activity.taskoreventtype ===
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) ||
                        activity.taskoreventtype === 'logcall')
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccnamhue
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccnamhue }}</span
                      > </span
                    >{{ $t("label_chatter_recordcall") }}
                    <!-- 已记录呼叫 -->
                  </p>
                </div>
                <el-card
                  v-if="
                    isExpandAll === true
                      ? true
                      : indexess == index
                      ? true
                      : false
                  "
                  style="min-height: 100px; padding: 10px 10px 10px 10px"
                >
                  <div v-if="activity.istask === '0' ? true : false">
                    <div style="display: flex">
                      <span style="margin-right: 100px">
                        <p>
                          <!-- 开始 -->
                          {{ $t("label_tabpage_beginz") }}
                        </p>
                        <p>
                          {{ activity.begintime | formatTimess(timeZone) }}
                        </p>
                      </span>
                      <span>
                        <p>
                          <!-- 结束 -->
                          {{ $t("label_tabpage_endz") }}
                        </p>
                        <p>
                          {{ activity.endtime | formatTimess(timeZone) }}
                        </p>
                      </span>
                    </div>
                    <div>
                      <p>
                        <!-- 内容 -->
                        <!-- {{ $t("label.content") }} -->
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div v-if="activity.istask === '1' ? true : false">
                    <div>
                      <p>
                        <!-- 内容 -->
                        <!-- {{ $t("label.content") }} -->
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div v-if="activity.type === 'email' ? true : false">
                    <div
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人邮件地址 -->
                          {{ $t("label_tabpage_emailaddressz") }}:
                        </span>
                        <br />
                        <span>{{ activity.fromaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人邮件地址: -->
                          {{
                            $t(
                              "vue_label_commonobjects_detail_email_newaddress"
                            )
                          }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.toaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 抄送人 -->
                          {{ $t("label.mobile.phone.cc.people") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.ccaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 密送人 -->
                          {{ $t("vue_label_email_cc") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p>
                      <div>
                        <span>
                          <!-- 邮件正文 -->
                          {{ $t("label.emailmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.content"
                        ></div>
                      </div>
                    </div>
                    <div class="backEmail">
                      <p style="margin-right: 35px">
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-reply"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'reply')"
                          style="cursor: pointer"
                        >
                          <!-- 回复 -->
                          {{ $t("label.emailobject.emaildetail.button.reply") }}
                        </span>
                      </p>
                      <p>
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-forward"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'forward')"
                          style="cursor: pointer"
                        >
                          <!-- 转发      -->
                          {{
                            $t("label.emailobject.emaildetail.button.forward")
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <!-- 短信 -->
                  <div v-if="activity.type === 'sms'">
                    <div
                      class="emailbox1"
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人 -->
                          {{ $t("label.chatter.addresser") }}:
                        </span>
                        <br />
                        <span>{{
                          activity.fromaddressname
                            ? activity.fromaddressname
                            : activity.fromaddress
                        }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人 -->
                          {{ $t("label.chatter.addressee") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{
                          activity.toaddressname
                            ? activity.toaddressname
                            : activity.toaddress
                        }}</span>
                      </p>
                      <!-- <p>
                        <span style="font-weight: bold">

                          {{ $t("label.emailtocloudcc.name") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.name }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">

                          {{ $t("label.relevant.records") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p> -->
                      <div>
                        <span style="font-weight: bold">
                          <!-- 短信正文 -->
                          {{ $t("label.smsmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.htmlbody"
                        ></div>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <div class="feature-box">
              <el-button
                class="button ckgd"
                :loading="lastMonthLoading"
                @click="seeMoreButton('last')"
                v-if="lastMonthSeeMore"
              >
                <!-- 查看更多 -->
                {{ $t("label_tabpage_lookatmorez") }}...
              </el-button>
              <span v-if="!lastMonthSeeMore && lastMonthPage > 1">
                <!-- 暂无更多数据  -->
                {{ $t("mobel_web_not_more_data") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 更早之前 -->
    <div class="comingSoon" v-if="moreList.length > 0">
      <div class="header">
        <i
          :class="[
            keysses === 4 ? 'iconjiantou-you' : 'iconxiajiantou-changtai',
          ]"
          class="icons iconfont"
          @click="shouqi(4)"
        ></i>
        <span class="titles">
          <!-- //{{ Front_month }} -->
          <!-- 月及以前 -->
          {{ $t("vue_label_commonobjects_detail_and_before") }}
        </span>
      </div>
      <div style="width: 100%; margin-top: -12px" v-show="show4">
        <div
          ref="Boxese"
          @scroll="orderScrollss"
          style="width: 100%; overflow-y: auto"
        >
          <div class="timeline">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in moreList"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :class="activity.class"
                :color="activity.color"
                :size="activity.size"
              >
                <i
                  :class="[
                    isExpandAll === true
                      ? 'iconxiajiantou-changtai'
                      : indexes == index
                      ? 'iconxiajiantou-changtai'
                      : 'iconjiantou-you',
                  ]"
                  class="icons iconfont iconfonts"
                  @click.prevent="hoverese(index, activity.id)"
                ></i>
                <div class="del">
                  <!-- 改为flex，保证在一行 -->
                  <div class="flexbox">
                    <div class="title">
                      <el-tooltip
                        :content="
                          activity.type === 'sms'
                            ? $t('label.setupsms')
                            : activity.subject
                        "
                        placement="top-start"
                      >
                        <div
                          class="content"
                          @click="routeres(activity)"
                          v-if="activity.type !== 'sms'"
                        >
                          <!-- 非短信活动 -->
                          <span class="contentspan">{{
                            activity.subject
                          }}</span>
                        </div>
                        <div class="content" v-if="activity.type === 'sms'">
                          <!-- 短信活动 -->
                          <span class="contentspan">{{
                            $t("label.setupsms")
                          }}</span>
                          <!-- 短信图标 -->
                          <svg
                            v-if="activity.sendstatus === '-1'"
                            class="iconSms"
                            aria-hidden="true"
                          >
                            <use href="#icon-shibai"></use>
                          </svg>
                        </div>
                      </el-tooltip>
                    </div>

                    <div style="display: flex">
                      <!-- 邮件跟踪 -->
                      <p
                        v-if="activity.type === 'email' ? true : false"
                        style="margin-right: 10px"
                      >
                        <!-- 邮件跟踪显示 -->
                        <span
                          v-if="
                            activity.incoming === '0' &&
                            activity.istrackopen === '1'
                              ? true
                              : false
                          "
                        >
                          <el-tooltip
                            class="item"
                            effect="dark"
                            placement="top-start"
                          >
                            <div slot="content">
                              {{
                                $t("label.emailobject.track.times", {
                                  0: activity.opentimes,
                                })
                              }}<br />{{ activity.lastopendate }}
                            </div>
                            <svg
                              class="iconReply"
                              aria-hidden="true"
                              width="15"
                              height="15"
                              viewBox="0,0,15,15"
                            >
                              <use
                                :href="
                                  activity.opentimes > 0 ? tracking : trackings
                                "
                              ></use>
                            </svg>
                          </el-tooltip>
                        </span>
                      </p>
                      <!-- 时间 -->
                      <!-- 短信时间 -->
                      <span
                        v-if="activity.type === 'sms' && !!activity.emaildate"
                        >{{ activity.emaildate | formatTime(timeZone) }}</span
                      >
                      <span
                        v-if="activity.istask === '0' && activity.begintime"
                      >
                        {{ activity.begintime | deadline(timeZone) }}
                      </span>
                      <span
                        v-if="activity.type === 'email' && activity.emaildate"
                        >{{ activity.emaildate | formatTime(timeZone) }}</span
                      >
                      <span
                        v-if="
                          activity.istask === '0' &&
                          activity.begintime === undefined
                            ? true
                            : false
                        "
                        >{{
                          $t(
                            "vue_label_commonobjects_detail_activity_nostarttime"
                          )
                        }}</span
                      >
                      <span
                        v-if="
                          activity.istask === '1' && activity.expiredateformat
                        "
                      >
                        <!-- {{ activity.expiredate | deadline(countryCode) }} -->
                        <!-- 到期时间无法做多时区，为保持展示时间一致，使用时间字符串 -->
                        {{ activity.expiredateformat.slice(-5) }}
                      </span>
                      <!-- 无到期日期 -->
                      <span
                        v-if="
                          activity.istask === '1' &&
                          activity.expiredate === undefined
                            ? true
                            : false
                        "
                        >{{
                          $t(
                            "vue_label_commonobjects_detail_activity_noexpirationdate"
                          )
                        }}</span
                      >
                      <!-- 无到期日期 -->
                      <el-dropdown
                        @command="handleCommand"
                        v-if="activity.type !== 'sms'"
                      >
                        <svg class="iconReply xiala" aria-hidden="true">
                          <use href="#icon-xiala"></use>
                        </svg>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            v-if="activity.type === 'email' ? false : true"
                            :command="{ type: 'd', id: activity }"
                          >
                            <!-- 编辑 -->
                            {{ $t("pagecreator_page_button_edit") }}
                          </el-dropdown-item>
                          <el-dropdown-item
                            :command="{ type: 'e', id: activity }"
                          >
                            <!-- 删除 -->
                            {{ $t("component_setup_tabs_label_delete") }}
                          </el-dropdown-item>
                          <!-- <el-dropdown-item command="f">创建跟进事件</el-dropdown-item>
                      <el-dropdown-item command="g">创建跟进任务</el-dropdown-item> -->
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <!-- 短信 -->
                  <p v-if="activity.type === 'sms'" class="pClass">
                    <!-- XXX收到一封短信 -->
                    <span class="coloreses" v-if="activity.incoming === '0'"
                      >{{ activity.name ? activity.name : activity.toaddress
                      }}<span class="paddingClass">{{
                        $t("label.sms.toaddress")
                      }}</span></span
                    >
                    <!-- XXX发送一封短信 -->
                    <span
                      class="coloreses"
                      v-else-if="activity.incoming === '1'"
                      >{{ activity.name ? activity.name : activity.fromaddress
                      }}<span>{{ $t("label.sent.sms") }}</span></span
                    >
                  </p>
                  <p v-if="activity.istask === '0' ? true : false">
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{
                      $t("vue_label_commonobjects_detail_activity_ownevent")
                    }}
                    <!-- 拥有事件 -->
                  </p>
                  <!-- 附件 -->
                  <div v-if="activity.isattachment == '1'" class="file">
                    <span
                      v-for="(files, index) in activity.attachmentname"
                      :key="index"
                      class="filelist"
                      @click="downloadfile(files)"
                    >
                      <svg class="fujian" aria-hidden="true">
                        <use href="#icon-addassemblyblue"></use>
                      </svg>
                      <span class="fileName"
                        >{{ files.fileName }}{{ files.fileSuffix }}</span
                      >
                    </span>
                  </div>
                  <p
                    v-if="activity.type === 'email' ? true : false"
                    class="pClass"
                  >
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, listInfo.userName)"
                      v-if="listInfo.userId === activity.ownerId ? true : false"
                      >{{ $t("vue_label_commonobjects_detail_you") }}</span
                    >
                    <!-- 您 -->
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, activity.fromaddress)"
                      v-else-if="activity.incoming === '1' ? true : false"
                      >{{ activity.fromaddress }}</span
                    >
                    <span
                      class="colores"
                      @click="routers(activity.ownerId, activity.formname)"
                      v-else-if="activity.incoming === '0' ? true : false"
                      >{{ activity.formname }}</span
                    >
                    <span class="paddingClass">{{
                      $t("vue_label_email_sentto")
                    }}</span>
                    <span v-if="activity.incoming === '0' ? true : false">{{
                      activity.toaddress
                    }}</span>
                    <span v-if="activity.incoming === '1' ? true : false">{{
                      activity.formname
                    }}</span>
                    <span class="paddingClass">{{
                      $t("vue_label_commonobjects_detail_an_email")
                    }}</span>
                    <!-- 发送一封电子邮件 -->
                  </p>
                  <p
                    v-if="
                      activity.istask === '1' &&
                      activity.taskoreventtype !==
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) &&
                      activity.taskoreventtype != 'logcall'
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{ $t("vue_label_commonobjects_detail_activity_owntask") }}
                    <!-- 拥有任务 -->
                  </p>
                  <p
                    v-if="
                      activity.istask === '1' &&
                      (activity.taskoreventtype ===
                        $t(
                          'label.globaloperation.operationtype.record.phone'
                        ) ||
                        activity.taskoreventtype == 'logcall')
                        ? true
                        : false
                    "
                  >
                    <span>
                      <span
                        class="colores"
                        @click="routers(activity.belongtoid, listInfo.userName)"
                        v-if="
                          listInfo.userId === activity.belongtoid ? true : false
                        "
                        >{{ $t("vue_label_commonobjects_detail_you") }}</span
                      >
                      <!-- 您 -->
                      <span
                        class="colores"
                        @click="
                          routers(
                            activity.belongtoid,
                            activity.belongtoidccname
                          )
                        "
                        v-else
                        >{{ activity.belongtoidccname }}</span
                      > </span
                    >{{ $t("label_chatter_recordcall") }}
                    <!-- 已记录呼叫 -->
                  </p>
                </div>
                <el-card
                  v-if="
                    isExpandAll === true
                      ? true
                      : indexeses == index
                      ? true
                      : false
                  "
                  style="min-height: 100px; padding: 10px 10px 10px 10px"
                >
                  <div v-if="activity.istask === '0' ? true : false">
                    <div style="display: flex">
                      <span style="margin-right: 100px">
                        <p>
                          <!-- 开始 -->
                          {{ $t("label_tabpage_beginz") }}
                        </p>
                        <p>
                          {{ activity.begintime | formatTimess(timeZone) }}
                        </p>
                      </span>
                      <span>
                        <p>
                          <!-- 结束 -->
                          {{ $t("label_tabpage_endz") }}
                        </p>
                        <p>
                          {{ activity.endtime | formatTimess(timeZone) }}
                        </p>
                      </span>
                    </div>
                    <div>
                      <p>
                        <!-- 内容 -->
                        <!-- {{ $t("label.content") }} -->
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div v-if="activity.istask === '1' ? true : false">
                    <div>
                      <p>
                        <!-- 内容 -->
                        <!-- {{ $t("label.content") }} -->
                      </p>
                      <p>{{ activity.remark }}</p>
                    </div>
                  </div>
                  <div v-if="activity.type === 'email' ? true : false">
                    <div
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人邮件地址 -->
                          {{ $t("label_tabpage_emailaddressz") }}:
                        </span>
                        <br />
                        <span>{{ activity.fromaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人邮件地址: -->
                          {{
                            $t(
                              "vue_label_commonobjects_detail_email_newaddress"
                            )
                          }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.toaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 抄送人 -->
                          {{ $t("label.mobile.phone.cc.people") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.ccaddress }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 密送人 -->
                          {{ $t("vue_label_email_cc") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p>
                      <div>
                        <span>
                          <!-- 邮件正文 -->
                          {{ $t("label.emailmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.content"
                        ></div>
                      </div>
                    </div>
                    <div class="backEmail">
                      <p style="margin-right: 35px">
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-reply"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'reply')"
                          style="cursor: pointer"
                        >
                          <!-- 回复 -->
                          {{ $t("label.emailobject.emaildetail.button.reply") }}
                        </span>
                      </p>
                      <p>
                        <svg
                          class="iconReply"
                          aria-hidden="true"
                          width="22"
                          height="22"
                          viewBox="0,0,22,22"
                        >
                          <use href="#icon-forward"></use>
                        </svg>
                        <span
                          @click="replys(activity.id, 'forward')"
                          style="cursor: pointer"
                        >
                          <!-- 转发      -->
                          {{
                            $t("label.emailobject.emaildetail.button.forward")
                          }}
                        </span>
                      </p>
                    </div>
                  </div>
                  <!-- 短信 -->
                  <div v-if="activity.type === 'sms'">
                    <div
                      class="emailbox1"
                      style="
                        max-height: 300px;
                        overflow: auto;
                        font-size: 12px;
                        margin-bottom: 15px;
                      "
                    >
                      <p>
                        <span style="font-weight: bold">
                          <!-- 发件人 -->
                          {{ $t("label.chatter.addresser") }}:
                        </span>
                        <br />
                        <span>{{
                          activity.fromaddressname
                            ? activity.fromaddressname
                            : activity.fromaddress
                        }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">
                          <!-- 收件人 -->
                          {{ $t("label.chatter.addressee") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{
                          activity.toaddressname
                            ? activity.toaddressname
                            : activity.toaddress
                        }}</span>
                      </p>
                      <!-- <p>
                        <span style="font-weight: bold">

                          {{ $t("label.emailtocloudcc.name") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.name }}</span>
                      </p>
                      <p>
                        <span style="font-weight: bold">

                          {{ $t("label.relevant.records") }}:
                        </span>
                        <br />
                        <span class="emailbox1">{{ activity.bccaddress }}</span>
                      </p> -->
                      <div>
                        <span style="font-weight: bold">
                          <!-- 短信正文 -->
                          {{ $t("label.smsmainbody") }}
                        </span>
                        <div
                          style="
                            width: 100%;
                            word-break: break-all;
                            text-align: justify;
                            white-space: pre-line;
                          "
                          v-html="activity.htmlbody"
                        ></div>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-timeline-item>
            </el-timeline>
            <div class="feature-box">
              <el-button
                class="button ckgd"
                :loading="beforeMonthLoading"
                @click="seeMoreButton('before')"
                v-if="beforeMonthSeeMore"
              >
                <!-- 查看更多 -->
                {{ $t("label_tabpage_lookatmorez") }}...
              </el-button>
              <span v-if="!beforeMonthSeeMore && beforeMonthPage > 1">
                <!-- 暂无更多数据  -->
                {{ $t("mobel_web_not_more_data") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 对话框 -->
    <el-dialog :title="title" :visible.sync="centerDialogVisible" width="30%">
      <span>{{ contents }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">
          <!-- 取消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button type="primary" @click="queding">
          {{ $t("label.ems.confirm") }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 附件中包含exe文件提示弹框 -->
    <el-dialog
      :title="$t('error')"
      :close-on-click-modal="false"
      :visible.sync="containExeFile"
      width="30%"
    >
      <span>
        {{ $t("label.exe.supported.request.rejected") }}
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="containExeFile = false">
          {{ $t("label.ems.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <div>
      <add-files
        ref="addfiles"
        :dialogAddVisible="dialogAddVisible"
        @close="addWorkClose"
        @addSubmit="getworkList"
        @UploadSuccesses="UploadSuccesses"
      ></add-files>
    </div>
    <Pop ref="isPop" :templateInfo="email" @tmp="tmp"></Pop>

    <div>
      <!-- 查找/查找多选 -->
      <!-- 搜索 -->
      <el-dialog
        :title="$t('label.quickbooks.searchs')"
        custom-class="dialog-form-wrapper"
        width="60%"
        top="5%"
        style="height: 91%"
        :visible.sync="showSearchTables"
        :lock-scroll="true"
        :close-on-click-modal="false"
        :modal="true"
        append-to-body
      >
        <search-table
          ref="searchTable"
          :fieldId="fieldId"
          :checked="checkeds"
          :relevant-objid="relevantObjId"
          :relevant-prefix="relevantPrefix"
          @changeSelect="changeSelect"
        />
      </el-dialog>
    </div>
    <Reply
      ref="Replyes"
      :recordId="recordId"
      :emaillist="emaillist"
      :emailFrom="ccType"
    ></Reply>
    <addContact
      ref="echo"
      :showAddCampaignMembers="showAddCampaignMembers"
      @closeAddCampaignMembersDialog="closeAddCampaignMembersDialog"
      @next="next"
    ></addContact>

    <!-- 快速新建 -->
    <create-edit-obj
      ref="createEditObj"
      :prefix="mold"
      :recordIds="recordId"
      :objectApi="objectApis"
      :objectName="tabNames"
      :bigTitle="bigTitle"
      :objectApies="objectApi"
      :prefixes="prefix"
      :objectNames="tabName"
      :isActive="Active"
      @save="save"
      :id="id"
      :type="active"
    >
    </create-edit-obj>

    <!-- 插入字段失败提示 -->
    <div>
      <!-- 提示 -->
      <el-dialog
        :title="$t('label.prompt')"
        :visible.sync="errdialog"
        width="40%"
      >
        <span>
          <!-- 当前邮件正文中插入的字段与已关联的记录不匹配，可能会造成取值失败。 -->
          {{ $t("label.emailobject.template.message2") }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="errdialog = false">
            <!-- 放弃 -->
            {{ $t("label.emailobject.template.giveup") }}
          </el-button>
          <el-button type="primary" @click="Continueadd">
            <!-- 继续添加 -->
            {{ $t("label.emailobject.template.insert.continue") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <email-template-front
      ref="emailTemplateFront"
      @deliverTemplate="deliverTemplate"
      :showRelateId="showRelateid"
    ></email-template-front>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { consoleSaveTab } from "@/views/home/api.js";
import debounce from "lodash.debounce";
// 邮件搜索框样式，要保留！！！
import "vue-multiselect/dist/vue-multiselect.min.css";
import { AddFiles } from "@/components/index";
import inseart from "./detailChild/Email/inseart.vue";
import Pop from "./detailChild/Email/Pop.vue";
import schedule from "./detailChild/Email/schedule.vue";
import {
  GetActivityGetActivityList,
  ActivityGetQuickActivityPage,
  GetDelete,
  getEmailObjectGetEmailPageInfo,
  getEmailObjectGetTemplateBody,
  getEmailObjectmergeField,
  getObjectGetObjectList,
  getEmailObjectsendEmailObject,
  getEmailObjectDelete,
  getEmailObjectSearchEmail,
  getEmailObjectQuerySign,
  getTextMessageSendTextMessage,
  getTextMessageGetTextMessageTemplata,
  getMergeMailList,
  getMailMergePageInfo,
  insertFields,
  getobjectlist,
} from "./api";
import wangEditor from "./components/wangEditor";
import * as CommonObjApi from "./api";
import INPUTTYPE from "@/config/enumCode/inputType";
import Bus from "@/mixin/bus.js";
// import SearchTable from '@/components/Form/search-table.vue'
import Autograph from "./detailChild/Email/Autograph";
import Reply from "@/components/Reply";
import VueEmojiBox from "vue-emoji-box";
import { emojiCategory, emojis } from "@/assets/emoji";
import addContact from "../noteSms/components/addContact";
import Multiselect from "vue-multiselect";
import createEditObj from "./components/create-edit-obj.vue";
import axios from "axios";
import emailTemplateFront from "@/views/Email/components/email-template-front";
axios.defaults.timeout = 5000;
axios.defaults.baseURL = window.Glod["ccex-apitsf"] + "/api";

export default {
  components: {
    schedule,
    wangEditor,
    AddFiles,
    inseart,
    Pop,
    Autograph,
    createEditObj,
    Reply,
    VueEmojiBox,
    addContact,
    Multiselect,
    emailTemplateFront,
  },
  props: {
    objectApi: {
      type: String,
    },
    prefix: {
      type: String,
    },
    tabName: {
      type: String,
    },
    bigTitle: {
      type: Array,
      default: () => [{}],
    },
    dataId: {
      type: String,
      default: "",
    },
    objId: {
      type: String,
      default: "",
    },
  },
  created() {
    // 电子邮件收件人、密送人添加防抖
    this.remoteMethod = debounce(this.remoteMethod, 300)
    this.remoteMethodes = debounce(this.remoteMethodes, 300)
    if (this.dataId !== "") {
      this.recordId = this.dataId;
      this.generateData.relatedid = this.dataId;
    }
    // 获取右侧邮件信息，默认主题和联系人邮箱
    this.getEmailObjectGetEmailPageInfos();
    this.init();
    this.getobjectlists();
    // this.getMailMergePageInfo();
    this.listenStorage();
    // this.getUserInfo()
    // this.getObjectGetObjectLists()
    // this.GetActivityGetActivityLists()
    // this.month()
    // this.Lastmonth()
    // this.getMonth()
    // this.more()
    // this.sameDay()
  },
  data() {
    return {
      isprivate: localStorage.getItem("private") === "true", // 是否是私有云
      // 日程预约按钮
      rightIcon: "date",
      defaultEmailaddress: false, // 默认收件人邮箱
      defaultSubject: false, // 默认主题
      generateLoading: true, //生成文档的loading
      wordUrl: "", //文件地址
      generateObjlist: [], //文件列表
      generateFolderlist: [], //文件夹模板列表
      generateFileChoose: "", //选中模板文件加
      generateData: {
        objId: "",
        prefix: "",
        labelValue: "",
        relatedid: "",
      }, //生成模板
      isrecordactivity: true, //记录活动
      documentSaving: "1", //文档保存方式,默认保存至文档
      chooseFileType: "word", //选择的文件类型，默认word
      // 文件类型数据
      fileTypeList: [
        {
          name: "Microsoft Word",
          id: "word",
        },
        {
          name: "PDF",
          id: "pdf",
        },
      ],
      //文件保存方式
      documentSaveList: [
        {
          name: this.$i18n.t("c1458"), //保存至文件
          id: "1",
        },
        {
          name: this.$i18n.t("label.emailobject.attachment.download"), // 下载
          id: "2",
        },
        {
          name: this.$i18n.t("c1459"), //保存并下载至文件
          id: "3",
        },
      ],
      multipleSelection: {}, //选中模板的id
      url: "", //地址
      generateRecordList: [
        {
          id: "",
          name: "",
        },
      ], //合并记录列表
      generateRecord: "", //选择要合并的记录
      noEmailUrl: "", // 未配置邮件url
      emailUrl: "", // 开通邮件url
      emailaddressOrSubject: false, // 是否有默认的主题和收件人
      //发送按钮loading
      isSending: false,
      //插入字段
      insertFields: "",
      recordOptions: [],
      queryvalue: "",
      isNewtask: true,
      emailBeforeContent: "", //邮件原文内容
      isRecordingcalls: false,
      isNewevent: false,
      isEmail: false,
      isCcaddress: false,
      isBccaddress: false,
      event: false, //创建跟进事件
      tasks: false, //创建跟进任务
      showClose: false, //关闭按钮
      editTasks: false, //编辑任务
      isConent: false,
      isnewTask: true, //新建任务
      isFilter: false, //任务筛选器显示与隐藏
      isFilters: false, //任务筛选器遮罩层
      filter: true, //任务筛选器
      title: this.$i18n.t("vue_label_commonobjects_detail_task_name"), //编辑任务名称
      content: "", //
      isInput: false,
      isData: false,
      centerDialogVisible: false,
      icon: "conjiantou-you",
      iconImg: "conjiantou-you",
      iconImgs: "conjiantou-you",
      iconImges: "conjiantou-you",
      isIf: false,
      isCard: false,
      activities: [],
      newActivities: [], //即将到来和已逾期的数据
      dialogAddVisible: false,
      //电子邮件
      callss: {
        emailFrom: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        theme: [
          {
            required: true,
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            trigger: "blur",
          },
        ],
      },
      //记录ID
      recordId: null,
      //电子邮件数据绑定
      email: {
        addressee: "",
        theme: "",
        items: "",
        istrackopen: "1",
        isattachment: "0",
        ccaddress: "",
        bccaddress: "",
        content: "",
        addressees: "",
        relatedid: "",
      },
      page: 1,
      pageSize: 2,
      indexs: null,
      isTrue: true,
      listInfo: {},
      visibles: false,
      ifEmail: true,
      emailInfo: {},
      emaillist: [], //发件人信息
      emailFrom: "", //发件人
      fromaddress: "",
      index: 0,
      contents: "",
      temp: false,
      templateInfo: {},
      tabData: [],
      //查找/查找多选
      showSearchTable: false,
      activeName: "event",
      subjectOptions: [], //主题
      fieldList: [], //快速新建字段信息
      relateobjList: [], //相关项对象列表
      results: {},
      isPhone: false,
      isTask: false,
      // 本月
      monthpagesize: 10,
      monthList: [],
      //  上月
      listmonthpage: 1,
      listmonthpagesize: 10,
      lastmonthlist: [],
      // 更多
      moreListpage: 1,
      moreListpagesize: 10,
      moreList: [],
      indexes: null,
      indexess: null,
      indexeses: null,

      // 编辑
      // 修改
      showBaseForm: false,
      formAttr: null,
      optionList: {},
      statusIcon: true,
      autoRefresh: false,
      dialogAttr: {
        title: "",
        type: "add",
        isShow: false,
        dialogWidth: "85%",
      },
      recordType: "", //记录类型
      isaddShow: true,
      zhucongfield: [],
      id: "",
      objectApis: "",
      noMore: false,
      noMores: false,
      noMoress: false, //已逾期和即将到来的提示，没有任务和事件要处理
      isgengduo: false,
      checkList: [],
      checkListe: [],
      checkListes: [],
      isNewtasks: false, //是否显示本月模块
      isNewtaskss: false, //是否显示上月模块
      isNewtasksses: false,
      dialogBodyHeight: `${document.body.offsetHeight - 248}px`,
      currentUserInfo: {},
      isdown: true,
      activitieslength: null,
      monthlength: null,
      Lastmonthlength: null,
      Morelength: null,
      checkLists: [],
      check: "check",
      loading: false,
      keys: null, //即将到来和已逾期箭头状态，展开||收起
      keyss: null, //本月箭头状态，展开||收起
      keysss: null, //上月箭头状态，展开||收起
      keysses: null, //更早之前箭头状态，展开||收起
      featureAndDieLoading: false, //即将到来和已逾期loading
      thisMonthLoading: false, //本月loading
      lastMonthLoading: false, //上月loading
      beforeMonthLoading: false, //更早之前loading
      thisMonthSeeMore: false, //本月查看更多
      lastMonthSeeMore: false, //上月查看更多
      beforeMonthSeeMore: false, //更早之前查看更多
      featureAndDiePage: 1, //即将到来和已逾期页数
      featureAndDiePageSize: 10, //即将到来和已逾期每页条数
      thisMonthPage: 1, //本月页数
      thisMonthPageSize: 10, //本月每页条数
      lastMonthPage: 1, //上月页数
      lastMonthPageSize: 10, //上月每页条数
      beforeMonthPage: 1, //更早之前页数
      beforeMonthPageSize: 10, //更早之前每页条数
      keyes: [],
      getmonth: "",
      lastmonth: "",
      Front_month: "",
      Front_months: "",
      cities: [],
      shortMessage: {
        addressee: "",
        content: "",
        labelValue: "",
        select: "",
        objId: "",
        prefix: "",
      },
      showSearchTables: false,
      checkeds: false,
      relevantObjId: "",
      relevantPrefix: "",
      fieldId: "",
      isEnclosure: false,
      isSchedule: false,
      Autograph: false,
      enclosure: [],
      identification: "",
      emails: "",
      tabNames: this.$i18n.t("label.mobile.quickcreate.default.events"),
      isNoGengDuo: false,
      show1: true, //即将到来和逾期
      show2: true, //本月
      show3: true, //上月
      show4: true, //更早之前
      ischakan: false, //是否显示查看更早以前按钮
      types: "",
      visibleMessage: false,
      // 短信
      smsTemplate: [],
      note: {
        addressee: "",
        content: "",
        inputValue: "",
        labelValue: "",
        select: "",
        addressees: "",
        objId: "",
        prefix: "",
        relatedid: "",
      },
      emojiCategory,
      emojis,
      baseUrl: "@/assets/emoji.js",
      addressee: [],
      selectValue: "",
      seahcvalue: [],
      seahcvalues: [],
      seahcvaluesn: [],
      ccaddress: "",
      ccaddreses: "",
      bccaddress: "",
      bccaddreses: "",
      addresseeNote: "",
      addresseeNotes: "",
      showAddCampaignMembers: false,
      checkedList: [],
      timestamp: null,
      ismessage: true,
      countries: [],
      countriess: [],
      countriessn: [],
      titles: "editorElem",
      mold: "bef",
      ccType: "",
      straining: false,
      Active: "active",
      errdialog: false,
      tracking: "#icon-emailtrackblue",
      trackings: "#icon-emailtrack",
      isrelationval: "",
      seahcvalueIndex: null,
      seahcvalueIndexTwo: null,
      seahcvalueIndexThree: null,
      active: "active",
      countryCode: this.$cookies.get("countryCode"),
      timeZone: this.$store.state.userInfoObj.timeZone, // 时区
      taskName: null, // 任务名称
      eventName: null, // 事件名称
      echoEmail: null, //服务请求单回显
      isExpandAll: false, //全部展开
      appendixSvg: "#icon-addassembly", // 附件svg
      insertSvg: "#icon-insert", // 插入合并字段svg
      administrationSvg: "#icon-administration", // 选择模板svg
      editorSvg: "#icon-editor", // 编辑签名svg
      scheduleSvg: "#icon-shizhong", // 编辑签名svg
      templateSvg: "#icon-shezhi_02", // 短信svg
      showRelateid: "",
      // 附件中包含exe文件提示弹框
      containExeFile: false,
      // 生成按钮loading
      generatebtnLoading: false,
    };
  },
  filters: {},
  beforeDestroy() {
    this.$Bus.$off("deliver-insert-fields", this.setInsertFields);
    Bus.$off("windowResize", this.setDialogHight);
    window.removeEventListener("storage", this.addRelevantObjectType);
  },
  mounted() {
    Bus.$on("windowResize", this.setDialogHight);
    this.$Bus.$on("deliver-insert-fields", this.setInsertFields);
  },
  computed: {
    ...mapState(["consoleData"]),
    // 即将到来和已逾期数据处理
    // 1、即将到来和已逾期:首次进入，默认显示3条数据
    // 2、本月，上月，更早以前：点击查看更多，每次累加10条数据
    featureisShowNumber() {
      return (arr) => {
        if (this.featureAndDiePage == 1 && this.isgengduo === true) {
          // 页面为1，查看更多为true,显示前三条
          let newArr = arr.slice(0, 3);
          return newArr;
        } else if (this.featureAndDiePage > 1 && this.isgengduo === true) {
          let newArr = arr.slice(0, this.featureAndDiePage * 10 - 7);
          return newArr;
        } else if (this.featureAndDiePage == 1 && this.isgengduo === false) {
          // 页面为1，查看更多为false,全部显示
          return arr;
        } else if (this.featureAndDiePage > 1 && this.isgengduo === false) {
          return arr;
        } else {
          return arr;
        }
      };
    },
  },
  methods: {
    setInsertFields(res) {
      this.insertFields = res;
    },
    setDialogHight(offsetHeight) {
      this.dialogBodyHeight = `${offsetHeight - 248}px`;
    },
    deliverTemplate(items, wangContent = {}) {
      // 主体内容
      let mainBody = wangContent.content;
      //this.emailBeforeContent:原文内容，如果有原文在原文上追加模板，没有原文模板替换邮件内容
      this.email.theme = wangContent.subject;
      this.$refs.wangEditor.clearvalue();
      if (this.emailBeforeContent) {
        this.$refs.wangEditor.editor.txt.clear();
        this.$refs.wangEditor.isFocus = true;
        this.$refs.wangEditor.signContent(
          mainBody + this.emailBeforeContent,
          true
        );
      } else {
        this.$refs.wangEditor.editor.txt.clear();
        this.$refs.wangEditor.isFocus = true;
        this.$refs.wangEditor.signContent(mainBody, true);
        this.email.content = mainBody;
      }
    },
    // 点击查看更多按钮
    seeMoreButton(value) {
      if (value == "feature") {
        this.featureAndDieLoading = true;
        this.featureAndDiePage = this.featureAndDiePage + 1;
        this.GetActivityGetActivityLists();
      } else if (value == "this") {
        this.thisMonthLoading = true;
        this.thisMonthPage = this.thisMonthPage + 1;
        this.month();
      } else if (value == "last") {
        this.lastMonthLoading = true;
        this.lastMonthPage = this.lastMonthPage + 1;
        this.Lastmonth();
      } else if (value == "before") {
        this.beforeMonthLoading = true;
        this.beforeMonthPage = this.beforeMonthPage + 1;
        this.more();
      }
    },
    saveSchedule(url) {
      let htmlTemplate = `<p>${this.$i18n.t(
        "vue.schedule.pre"
      )}<a href="${url}">${this.$i18n.t(
        "label.emailobject.appointment.urltext2"
      )}</a></p>`;
      this.$refs.wangEditor.inserthtml(htmlTemplate);
    },
    scheduleCencel() {
      this.isSchedule = !this.isSchedule;
    },
    //日程预约
    schedule() {
      this.$refs.schedule.init();
      this.scheduleSvg = "#icon-time-img";
    },
    // svg上浮
    changSvgOver(val) {
      if (val === "addassembly") {
        this.appendixSvg = "#icon-addassemblyblue";
      }
      if (val === "insert") {
        this.insertSvg = "#icon-insertblue";
      }
      if (val === "administration") {
        this.administrationSvg = "#icon-administrationblue";
      }
      if (val === "editor") {
        this.editorSvg = "#icon-editorblue";
      }
      if (val === "schedule") {
        this.scheduleSvg = "#icon-time-img";
      }
      if (val === "template") {
        this.templateSvg = "#icon-shezhi_02_blue";
      }
    },
    // svg离开
    changSvgOut(val) {
      if (val === "addassembly") {
        this.appendixSvg = "#icon-addassembly";
      }
      if (val === "insert") {
        this.insertSvg = "#icon-insert";
      }
      if (val === "administration") {
        this.administrationSvg = "#icon-administration";
      }
      if (val === "editor") {
        this.editorSvg = "#icon-editor";
      }
      if (val === "schedule") {
        this.scheduleSvg = "#icon-shizhong";
      }
      if (val === "template") {
        this.templateSvg = "#icon-shezhi_02";
      }
    },
    // 监听查找字段新增事件
    listenStorage() {
      window.addEventListener("storage", this.addRelevantObjectType);
    },
    // 监听addRelevantObjectType变化
    addRelevantObjectType(event) {
      if (
        event.key === "relevantObjectDataId" &&
        localStorage.getItem("generate") == "Document"
      ) {
        this.generateRecordList = [
          {
            id: localStorage.getItem("relevantObjectDataId"),
            name: localStorage.getItem("relevantObjectDataName"),
          },
        ];
        this.generateRecord = localStorage.getItem("relevantObjectDataName");
      }
    },
    // 新增查找、查找多选字段
    newSearchableField() {
      // 先查权限再新建
      CommonObjApi.getObjectPermission({ id: this.generateData.objId }).then(
        (res) => {
          if (res.data.add) {
            // 获取对象api后进行页面跳转
            CommonObjApi.getLookupInfo({
              fieldId: "",
              objId: this.generateData.objId,
              prefix: this.generateData.prefix,
            }).then(() => {
              let newObjLevel = 0;
              window.open(
                `#/add-relevant-obj/${this.generateData.prefix}/${this.objectApi}/${newObjLevel}?addType=option&generate=Document`
              );
              localStorage.setItem("generate", "Document");
            });
          } else {
            // 抱歉，您暂无该对象新建权限
            this.$message.warning(this.$i18n.t("message.nopermession"));
          }
        }
      );
    },
    // 清空选中记录
    cleargenerateRecord() {
      this.generateData.relatedid = "";
      this.generateRecord = "";
      this.generateRecordList = [];
    },
    // 更改文件夹模板
    changeFolderTemplate(val) {
      this.getMergeMailList(val);
    },
    // 合并文档
    generateDocument() {
      if (
        this.multipleSelection.length > 0 &&
        this.multipleSelection.split(",").length <= 20
      ) {
        if (!this.generateData.relatedid) {
          this.$message.error(
            this.$i18n.t("label.emailmerge.mergepage.record.requeired")
          ); //请选择要合并的记录!
          return;
        }
        if (!this.chooseFileType) {
          //"请选择文件类型！"
          this.$message.error(this.$i18n.t("c1046"));
          return;
        }
        if (!this.documentSaving) {
          // "请选择文件保存方式！"
          this.$message.error(this.$i18n.t("c1047"));
          return;
        }
        this.generatebtnLoading = true;
        let params = {
          mergetemplates: this.multipleSelection, //邮件模板id，以逗号分隔
          recordId: this.generateData.relatedid, //记录ID
          isrecordactivity: this.isrecordactivity ? "true" : "false", //是否记录活动，记录传true
          fileType: this.chooseFileType == "pdf" ? this.chooseFileType : "", //是否是生成pdf,是的话传”pdf”
          saveMode: this.documentSaving, //保存方式，1为保存至文档；2为下载至本地；3为保存并下载至本地
        };
        // 根据是否多选判断文件下载类型，单个word，多个：zip
        let blobType;
        let blobName;
        if (this.multipleSelection.indexOf(",") > -1) {
          blobType = `application/zip`;
          blobName = this.$i18n.t("label.download_merge_file"); // '下载合并文件'
        } else {
          blobType = `application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8`;
          this.generateObjlist.some((item) => {
            if (item.id == this.multipleSelection) {
              blobName = item.filename;
            }
          });
        }
        // 判断当选取一个模板且是doc类型的模板时，不能生成PDF文件
        // 只有docx类型才能生成PDF文件
        if (this.chooseFileType === "pdf") {
          if (
            this.multipleSelection.indexOf(",") === -1 &&
            blobName.substr(blobName.length - 3, 3) === "doc"
          ) {
            this.$message.error(this.$i18n.t("c1049"));
            // '生成失败，目前仅支持docx类型模板生成PDF文件'
            return;
          }
          // docx模板生成PDF时，需要改文件后缀
          if (
            this.multipleSelection.indexOf(",") === -1 &&
            blobName.substr(blobName.length - 4, 4) === "docx"
          ) {
            blobName = blobName.substr(0, blobName.length - 4) + "pdf";
          }
          // 下载多个模板，文件类型为PDF时，模板中不能包含doc
          if (this.multipleSelection.indexOf(",") > -1) {
            let seletData = this.multipleSelection.split(",");
            let n = 0;
            this.generateObjlist.map((item) => {
              seletData.map((sele) => {
                if (item.id == sele && item.suffix === "doc") {
                  n = n + 1;
                }
              });
            });
            if (n > 0) {
              this.$message.error(this.$i18n.t("c1049"));
              return;
            }
          }
        }
        // 当选中多个模板生成PDF文件时，不能包含doc模板类型
        axios
          .post(`mailMerge/generateDocument`, params, {
            headers: {
              accesstoken: this.$CCDK.CCToken.getToken(),
            },
            responseType: "arraybuffer",
          })
          .then((response) => {
            if (this.documentSaving == "2" || this.documentSaving == "3") {
              this.wordUrl = window.URL.createObjectURL(
                new Blob([response.data], { type: blobType })
              );
              let link = document.createElement("a");
              link.href = this.wordUrl;
              link.setAttribute("download", blobName); // 下载文件的名字
              document.body.appendChild(link);
              link.click();
              localStorage.setItem("generate", "noDocument");
              this.month();
              this.$refs.multipleTable.clearSelection();
            }
            // 保存方式包括保存至文档，刷新文件相关列表
            if (this.documentSaving == "1" || this.documentSaving == "3") {
              this.$message.success(this.$i18n.t("c1050")); //生成成功，文档已保存至文件列表
              this.$emit("refreshUpload");
            }
            this.$refs.multipleTable.clearSelection();
            this.multipleSelection = {};
            this.chooseFileType = "word";
            this.documentSaving = "1";
            this.isrecordactivity = true;
            this.generatebtnLoading = false;
          })
          .catch(() => {
            this.generatebtnLoading = false;
          });
      } else {
        this.$message.error(this.$i18n.t("label.templates.merge")); //请选择要合并的模板，最多20个
      }
    },
    // 获取邮件合并页面信息
    async getMailMergePageInfo() {
      let params = {
        folderid: "",
        recordId: this.recordId,
        objId: this.objId,
      };
      let res = await getMailMergePageInfo(params);
      if (res.result) {
        this.generateFolderlist = res.data.folderlist;
        this.generateFileChoose = res.data.folderlist[0]?.id;
        this.generateRecord = this.bigTitle[0]?.fieldValue;
        this.getMergeMailList(this.generateFileChoose);
      }
    },
    // 过去邮件模板列表
    async getMergeMailList(folderid) {
      let params = {
        folderid: folderid,
        objId: this.objId,
      };
      let res = await getMergeMailList(params);
      // 列表显示文件名时添加文件类型
      if (res?.data?.objlist) {
        res.data.objlist.map((item) => {
          item.filename = item.filename + "." + item.suffix;
        });
      }
      this.generateObjlist = res.data.objlist;
      this.generateLoading = false;
    },
    // 显示客户弹框
    getCustomer() {
      this.relevantObjId = this.generateData.objId;
      this.relevantPrefix = this.generateData.prefix;
      this.showSearchTables = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 全选和取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 选择模板数据
    handleSelectionChange(val) {
      let data = [];
      val.map((item) => {
        data.push(item.id);
      });
      this.multipleSelection = data.join();
    },
    //下载文件
    downloadfile(item) {
      const baseURL = this.$baseConfig.baseURL;
      const token = this.$CCDK.CCToken.getToken();
      const link = document.createElement("a");
      link.setAttribute("download", "");
      link.href = `${baseURL}/file/downloadFile?accessToken=${token}&id=${item.file_info_id}`;
      link.click();
    },
    //插入字段
    async insertFieldsMethod() {
      if (this.insertFields == "") {
        //请选择插入字段
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_email_insert_field"),
        });
        return false;
      }
      if (this.recordId) {
        let result = await insertFields({
          filedstr: `{${this.insertFields}}`,
          recordId: this.recordId,
        });
        if (result.data == null) {
          this.errdialog = true;
        } else if (result.data == "") {
          let fieldstr = `<span style="color:red">{${this.insertFields}}</span>`;
          this.$refs.wangEditor.beforeEditorEdit(fieldstr);
        } else {
          this.$refs.wangEditor.beforeEditorEdit(result.data);
        }
      } else {
        this.errdialog = true;
      }
      this.visibles = false;
    },
    init() {
      this.recordId = this.dataId;
      // 清空数据，默认页数为1
      this.thisMonthPage = 1;
      this.lastMonthPage = 1;
      this.beforeMonthPage = 1;
      this.featureAndDiePage = 1;
      //即将到来和已逾期
      this.GetActivityGetActivityLists();
      this.getUserInfo();
      this.getObjectGetObjectLists();
      this.month();
      this.more();
      this.Lastmonth();
      this.getMonth();
      this.sameDay();
    },
    async getobjectlists() {
      // 获取对象列表
      let res = await getobjectlist();
      if (res.result) {
        res.data.forEach((item) => {
          if (item.id === "event") {
            this.eventName = item.labelname;
          } else if (item.id === "task") {
            this.taskName = item.labelname;
          }
        });
      }
    },
    customLabel({ name }) {
      if (name !== "") {
        return `${name}`;
      }
    },
    //获取对象列表
    async getObjectGetObjectLists() {
      let params = {
        searchKeyWord: "",
      };
      let res = await getObjectGetObjectList(params);
      this.tabData = res.data;
    },
    //获取邮件信息
    async getEmailObjectGetEmailPageInfos() {
      let params = {
        recordId: this.$route.params.id,
      };
      let res = await getEmailObjectGetEmailPageInfo(params);
      if (res.result) {
        // 开通邮件url
        if (
          res.data.relatedActiveMailUrl &&
          res.data.relatedActiveMailUrl.slice(0, 4) === "http"
        ) {
          this.emailUrl = res.data.relatedActiveMailUrl;
        } else {
          this.noEmailUrl = res.data.relatedActiveMailError;
        }
        // 添加邮箱
        // if (res.data.email) {
        //   let obj = {
        //     name: res.data.email,
        //     value: res.data.email,
        //   };
        //   this.$nextTick(() => {
        //     this.seahcvalue.push(obj);
        //   });
        // }

        // 获取主题和联系人邮箱作为默认主题和邮箱,只有在个案、联系人、潜客对象下
        if (res.data.emailaddress || res.data.subject) {
          this.emailaddressOrSubject = true;

          if (res.data.emailaddress) {
            let obj = {
              name: res.data.emailaddress,
              value: res.data.emailaddress,
            };
            // 存默认收件人，发送邮件后重新赋值
            this.defaultEmailaddress = Object.assign({}, obj);
            this.$nextTick(() => {
              this.seahcvalue = [];
              this.seahcvalue.push(obj);
            });
          }
          // 存默认主题，发送邮件后重新赋值
          this.defaultSubject = res.data.subject
            ? `${this.$i18n.t("label.emailobject.emaildetail.button.reply")}：${
                res.data.subject
              }`
            : false;
          this.email.theme = res.data.subject
            ? `${this.$i18n.t("label.emailobject.emaildetail.button.reply")}：${
                res.data.subject
              }`
            : "";
        }

        if (
          res.data.relatedActiveMailSetting ||
          res.data.relatedActiveGroupMailSetting
        ) {
          this.isrelationval = res.data.relatedActiveMailSetting
            ? res.data.relatedActiveMailSetting
            : res.data.relatedActiveGroupMailSetting;
          //relatedActiveMailSetting和发件人都为true时才打开操作,个案时，relatedActiveGroupMailSetting为true时也可以打开操作
          if (res.data.emaillist && res.data.emaillist.length > 0) {
            this.ifEmail = false;
          }
        }
        this.emailInfo = res.data;
        //发件人
        if (res.data.emaillist && res.data.emaillist[0]) {
          this.emailFrom = res.data.emaillist[0].id;
          this.ccType = res.data.emaillist[0].cctype;
        }
        this.emaillist = res.data.emaillist;
        // this.email.addressee = res.data.email;
      }
    },
    async getUserInfo() {
      var res = await CommonObjApi.getUserInfo();
      if (res.result) {
        this.listInfo = res.data;
      }
    },
    //即将到来和已逾期
    async GetActivityGetActivityLists(val) {
      if (this.featureAndDiePage == 1) {
        this.activities = [];
      }
      let params = {
        recordId: val !== undefined ? val : this.recordId,
        type: "futureAndOverdue",
        page: this.featureAndDiePage,
        pageSize: this.featureAndDiePageSize,
      };
      let res = await GetActivityGetActivityList(params);
      if (res.result) {
        res.data.forEach((item) => {
          if (item.iscompleted === "1") {
            this.checkList.push(item);
            this.checkLists.push(item);
          }
          if (
            item.taskoreventtype === undefined ||
            item.taskoreventtype === null
          ) {
            if (item.istask === "0") {
              (item.size = "large"),
                (item.class = "shijian"),
                (item.icon = "el-icon-date");
            } else if (item.istask === "1") {
              (item.size = "large"),
                (item.class = "renwu"),
                (item.icon = "el-icon-finished");
            }
          } else {
            (item.size = "large"),
              (item.class = "phone"),
              (item.icon = "el-icon-phone");
          }
          if (item.type === "email") {
            (item.size = "large"),
              (item.class = "email"),
              (item.icon = "el-icon-message");
            // 处理发件人、抄送人、密送人尖括号
            let reg = /<(.+?)>/g;
            if (item.toaddress) {
              let toaddressArg = item.toaddress.match(reg);

              if (Array.isArray(toaddressArg)) {
                item.toaddress = "";
                toaddressArg.forEach((itemaddress, index) => {
                  item.toaddress += itemaddress.slice(
                    1,
                    itemaddress.length - 1
                  );
                  if (toaddressArg.length - 1 !== index) {
                    item.toaddress += ";";
                  }
                });
              }
            }
            if (item.fromaddress) {
              let toaddressArg = item.fromaddress.match(reg);
              if (Array.isArray(toaddressArg)) {
                item.fromaddress = "";
                toaddressArg.forEach((itemaddress, index) => {
                  item.fromaddress += itemaddress.slice(
                    1,
                    itemaddress.length - 1
                  );
                  if (toaddressArg.length - 1 !== index) {
                    item.fromaddress += ";";
                  }
                });
              }
            }
            if (item.bccaddress) {
              let bccaddressArg = item.bccaddress.match(reg);
              if (Array.isArray(bccaddressArg)) {
                item.bccaddress = "";
                bccaddressArg.forEach((itemaddress, index) => {
                  item.bccaddress += itemaddress.slice(
                    1,
                    itemaddress.length - 1
                  );
                  if (bccaddressArg.length - 1 !== index) {
                    item.bccaddress += ";";
                  }
                });
              }
            }
            if (item.ccaddress) {
              let toaddressArg = item.ccaddress.match(reg);
              if (Array.isArray(toaddressArg)) {
                item.ccaddress = "";
                toaddressArg.forEach((itemaddress, index) => {
                  item.ccaddress += itemaddress.slice(
                    1,
                    itemaddress.length - 1
                  );
                  if (toaddressArg.length - 1 !== index) {
                    item.ccaddress += ";";
                  }
                });
              }
            }
          }
          if (item.type === "email") {
            (item.size = "large"),
              (item.class = "email"),
              (item.icon = "el-icon-message");
            // 处理发件人、抄送人、密送人尖括号
            let reg = /<(.+?)>/g;
            if (item.toaddress) {
              let toaddressArg = item.toaddress.match(reg);
              item.toaddress = "";
              toaddressArg.forEach((itemaddress, index) => {
                item.toaddress += itemaddress.slice(1, itemaddress.length - 1);
                if (toaddressArg.length - 1 !== index) {
                  item.toaddress += ";";
                }
              });
            }
            if (item.fromaddress) {
              let toaddressArg = item.fromaddress.match(reg);
              item.fromaddress = "";
              toaddressArg.forEach((itemaddress, index) => {
                item.fromaddress += itemaddress.slice(
                  1,
                  itemaddress.length - 1
                );
                if (toaddressArg.length - 1 !== index) {
                  item.fromaddress += ";";
                }
              });
            }
            if (item.bccaddress) {
              let bccaddressArg = item.bccaddress.match(reg);
              item.bccaddress = "";
              bccaddressArg.forEach((itemaddress, index) => {
                item.bccaddress += itemaddress.slice(1, itemaddress.length - 1);
                if (bccaddressArg.length - 1 !== index) {
                  item.bccaddress += ";";
                }
              });
            }
            if (item.ccaddress) {
              let toaddressArg = item.ccaddress.match(reg);
              item.ccaddress = "";
              toaddressArg.forEach((itemaddress, index) => {
                item.ccaddress += itemaddress.slice(1, itemaddress.length - 1);
                if (toaddressArg.length - 1 !== index) {
                  item.ccaddress += ";";
                }
              });
            }
          }
          if (item.type === "sms") {
            item.size = "large";
            item.class = "sms";
          }
        });
      }
      if (this.featureAndDiePage > 1) {
        if (res.data.length > 0 && res.data.length < 10) {
          // 当featureAndDiePage>1时，res.data.length >3，要显示查看更多按钮
          if (res.data.length < 4) {
            this.isgengduo = false;
            this.isNoGengDuo = true;
            this.noMoress = false;
          } else {
            this.isgengduo = true;
            this.isNoGengDuo = false;
            this.noMoress = false;
          }
          res.data.forEach((item) => {
            this.activities.push(item);
          });
        } else if (res.data.length == 10) {
          res.data.forEach((item) => {
            this.activities.push(item);
          });
          // 返回数据有10条，在掉一次接口查询是否还有数据，是否要显示查看更多按钮
          this.againSeeMore(
            "futureAndOverdue",
            this.featureAndDiePage + 1,
            this.featureAndDiePageSize
          );
        } else if (res.data.length == 0) {
          this.isgengduo = false;
          this.isNoGengDuo = true;
          this.noMoress = false;
        }
        this.newActivities = this.featureisShowNumber(this.activities);
        this.featureAndDieLoading = false;
      } else {
        // 页数==1，没有数据提示没有任务和事件要处理
        // 页数==1，超过三条显示查看更多按钮
        // 页数==1，小于三条不显示查看更多按钮
        // 页数==1，没有更多数据
        // isgengduo:即将到来和已逾期的查看更多按钮
        // isNoGengDuo:即将到来和已逾期提示：没有更多数据
        // noMoress:即将到来和已逾期的提示：恭喜你,没有未处理的事件和任务啦。
        if (res.data.length == 0) {
          this.isgengduo = false;
          this.isNoGengDuo = false;
          this.noMoress = true;
        } else {
          // 数据小于3条，不显示查看更多按钮
          if (res.data.length < 4) {
            this.isgengduo = false;
            this.isNoGengDuo = true;
            this.noMoress = false;
            res.data.forEach((item) => {
              this.activities.push(item);
            });
          } else if (res.data.length > 3 && res.data.length < 10) {
            // // 数据大于3条，小于8条，显示查看更多按钮
            this.isgengduo = true;
            this.isNoGengDuo = false;
            this.noMoress = false;
            res.data.forEach((item) => {
              this.activities.push(item);
            });
          } else if (res.data.length == 10) {
            res.data.forEach((item) => {
              this.activities.push(item);
            });
            // 返回数据有10条，在掉一次接口查询是否还有数据，是否要显示查看更多按钮
            this.againSeeMore(
              "futureAndOverdue",
              this.featureAndDiePage + 1,
              this.featureAndDiePageSize
            );
          }
        }
        this.newActivities = this.featureisShowNumber(this.activities);
        this.featureAndDieLoading = false;
      }
      this.activitieslength = res.data.length;
    },
    //接口返回数据等于10条时，再次调取接口，判断是否展示查看更多按钮
    async againSeeMore(type, page, pageSize) {
      let params = {
        recordId: this.recordId,
        type: type,
        page: page,
        pageSize: pageSize,
      };
      let res = await GetActivityGetActivityList(params);
      if (res.result) {
        if (type == "futureAndOverdue") {
          if (res.data.length > 0) {
            this.isgengduo = true;
            this.isNoGengDuo = false;
            this.noMoress = false;
          } else {
            this.isgengduo = false;
            this.isNoGengDuo = true;
            this.noMoress = false;
          }
          this.newActivities = this.featureisShowNumber(this.activities);
        }
        if (type == "thismonth") {
          if (res.data.length > 0) {
            this.thisMonthSeeMore = true;
          } else {
            this.thisMonthSeeMore = false;
          }
        }
        if (type == "lastmonth") {
          if (res.data.length > 0) {
            this.lastMonthSeeMore = true;
          } else {
            this.lastMonthSeeMore = false;
          }
        }
        if (type == "more") {
          if (res.data.length > 0) {
            this.beforeMonthSeeMore = true;
          } else {
            this.beforeMonthSeeMore = false;
          }
        }
      }
    },
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    hovers(index) {
      if (this.indexs !== index) {
        this.indexs = index;
      } else {
        this.indexs = null;
      }
    },
    hoveres(index) {
      if (this.indexes !== index) {
        this.indexes = index;
      } else {
        this.indexes = null;
      }
    },
    hoveress(index) {
      if (this.indexess !== index) {
        this.indexess = index;
      } else {
        this.indexess = null;
      }
    },
    hoverese(index) {
      if (this.indexeses !== index) {
        this.indexeses = index;
      } else {
        this.indexeses = null;
      }
    },
    shouqi(val) {
      if (this.keyes.indexOf(val) === -1) {
        this.keyes.push(val);
        if (val === 1) {
          this.keys = 1;
          this.show1 = false;
        } else if (val === 2) {
          this.keyss = 2;
          this.show2 = false;
        } else if (val === 3) {
          this.keysss = 3;
          this.show3 = false;
        } else if (val === 4) {
          this.keysses = 4;
          this.show4 = false;
        }
      } else {
        if (val === 1) {
          this.keyes.forEach((item, index) => {
            if (this.keyes.indexOf(val) !== -1) {
              this.keyes.splice(index, 1);
            }
          });
          this.keys = null;

          this.show1 = true;
        } else if (val === 2) {
          this.keyes.forEach((item, index) => {
            if (this.keyes.indexOf(val) !== -1) {
              this.keyes.splice(index, 1);
            }
          });
          this.keyss = null;
          this.show2 = true;
        } else if (val === 3) {
          this.keyes.forEach((item, index) => {
            if (this.keyes.indexOf(val) !== -1) {
              this.keyes.splice(index, 1);
            }
          });
          this.keysss = null;
          this.show3 = true;
        } else if (val === 4) {
          this.keyes.forEach((item, index) => {
            if (this.keyes.indexOf(val) !== -1) {
              this.keyes.splice(index, 1);
            }
          });
          this.keysses = null;
          this.show4 = true;
        }
      }
    },
    handleCommand(command) {
      if (command.type === "d") {
        this.id = command.id.id;
        let params = {
          id: command.id.id,
        };
        CommonObjApi.getPermissionById(params).then((res) => {
          if (res.data.isEdit) {
            // 事件
            if (command.id.istask === "0") {
              this.objectApis = "Event";
              this.tabNames = this.eventName;
            } else if (
              command.id.istask === "1" &&
              !command.id.taskoreventtype
            ) {
              // 任务
              this.objectApis = "Task";
              this.tabNames = this.taskName;
            }
            // 记录电话
            if (
              command.id.istask === "1" &&
              (command.id.taskoreventtype ===
                this.$i18n.t(
                  "label.globaloperation.operationtype.record.phone"
                ) ||
                command.id.taskoreventtype === "logcall")
            ) {
              // 任务
              this.objectApis = "Task";
              this.tabNames = this.$i18n.t("label.recordphone");
            }
            this.validRule = true;
            // 记录电话类型
            if (this.tabNames == this.$i18n.t("label.recordphone")) {
              this.$nextTick(() => {
                this.$refs.createEditObj.edit("", "isCall");
              });
            } else {
              this.$nextTick(() => {
                this.$refs.createEditObj.edit();
              });
            }
            // this.dialogAttr.title = this.$i18n.t(
            //   "pagecreator_page_button_edit"
            // );
            // this.dialogAttr.type = "EDIT";
            // this.dialogAttr.isShow = true;
            // this.getFormAttr();
          } else {
            // 对不起，您没有权限进行此操作!
            this.$message.error(this.$i18n.t("label.weixin.donot.permission"));
          }
        });

        //编辑
        // this.editTasks = true;
        // this.isnewTask = false;
        // this.tasks = false;
        // this.event = false;
      } else if (command.type === "e") {
        this.listInfo = command.id;
        this.types = command.types;
        let params = {
          id: command.id.id,
        };
        if (this.types !== "email") {
          CommonObjApi.getPermissionById(params).then((res) => {
            if (res.data.isDelete) {
              //删除
              this.title = this.$i18n.t("component_setup_tabs_label_delete");
              this.contents = this.$i18n.t("label.weixin.confirm.delete"); //"确定删除此任务吗?";
              this.centerDialogVisible = true;
            } else {
              this.$message.error(
                this.$i18n.t("label.weixin.donot.permission")
              );
            }
          });
        } else {
          //删除
          this.title = this.$i18n.t("component_setup_tabs_label_delete");
          this.contents = this.$i18n.t("label.weixin.confirm.delete"); //"确定删除此任务吗?";
          this.centerDialogVisible = true;
        }
      }
    },
    Show() {
      this.isFilter = !this.isFilter;
    },
    async focus(val) {
      if (val === "Email") {
        this.isConent = true;
        this.isnewTask = false;
        // 插入签名
        this.getEmailObjectQuerySigns();
        if (this.objectApi === "cloudcccase") {
          // 插入原文内容
          this.email.content = this.echoEmail?.content;
          // 原文内容
          this.getEmailContent(this.echoEmail);
        }
      } else if (val === "message") {
        this.ismessage = false;
      } else if (val === "logCall") {
        this.objectApis = "Task";
        this.tabNames = this.$i18n.t("label.recordphone"); //记录电话多语言
        this.$nextTick(() => {
          this.$refs.createEditObj.add("", "isCall");
        });
      } else if (val === "event") {
        CommonObjApi.getObjectPermission({
          id: val,
        }).then((res) => {
          if (res.data.add) {
            this.objectApis = "Event";
            this.id = "";
            this.tabNames = this.eventName;
            this.$nextTick(() => {
              this.$refs.createEditObj.add("active");
            });
          } else {
            this.$message.error(
              this.$i18n.t("message.error.system.service.permissiondeny")
            );
          }
        });
      } else if (val === "task") {
        this.objectApis = "Task";
        this.tabNames = this.taskName;
        this.$nextTick(() => {
          this.$refs.createEditObj.add("active");
        });
      }
    },
    getZhuCong(layoutId) {
      CommonObjApi.getMasterInfo({
        objId: this.objId,
        layoutId: layoutId,
      })
        .then((res) => {
          this.zhucongfield = res.data;
          for (var i = 0; i < this.zhucongfield.length; i++) {
            for (var j = 0; j < this.zhucongfield[i].fieldList.length; j++) {
              if (
                this.zhucongfield[i].fieldList[j].fieldLabel == "姓" ||
                this.zhucongfield[i].fieldList[j].fieldLabel == "名"
              ) {
                this.zhucongfield[i].fieldList.splice(j, 1);
                j--;
              }
              this.$set(
                this.zhucongfield[i].fieldList[j],
                "fieldType",
                INPUTTYPE[this.zhucongfield[i].fieldList[j].fieldType]
              );
              this.index = i;
            }
          }
        })
        .catch(() => {});
    },
    async submits() {
      if (this.seahcvalue.map((res) => res.name).join() === "") {
        this.$message.error(
          this.$i18n.t("errormessage_email_atleastonereceiver_z")
        );
        return;
      }
      if (this.email.theme === "") {
        this.$message.error(this.$i18n.t("vue_label_lead_required_fields"));
        return;
      }
      let str = [];
      if (this.enclosure.length !== 0) {
        this.email.isattachment = "1";
        this.enclosure.forEach((item) => {
          str.push(item.id);
        });
      }
      // 附件中是否包含.exe文件，不调取接口
      if (this.containExeFile === true) {
        return;
      }
      this.emaillist.forEach((val) => {
        if (this.emailFrom === val.id) {
          this.fromaddress = val.syncmail;
          this.ccType = val.cctype;
        }
      });
      // let toadData = "";
      // this.seahcvalue.forEach((val) => {
      //   if (val.name.split("<")[1]) {
      //     toadData = val.name.split("<")[1].split(">")[0];
      //   } else {
      //     toadData = val.name;
      //   }
      // });
      let params = {
        subject: this.email.theme,
        // toaddress: toadData,
        toaddress: this.seahcvalue.map((res) => res.name).join(";"),
        ccaddress: this.seahcvalues.map((res) => res.name).join(";"),
        bccaddress: this.seahcvaluesn.map((res) => res.name).join(";"),
        attachment: str.join(","),
        content: this.email.content,
        isattachment: this.email.isattachment,
        istrackopen: this.email.istrackopen,
        relateid: this.dataId,
        savetemp: "0",
        singleSend: "0",
        cctype: this.ccType,
        fromaddress: this.fromaddress,
      };
      let data = JSON.stringify(params);
      this.isSending = true;
      let res;
      try {
        res = await getEmailObjectsendEmailObject(data);
        this.isSending = false;
      } catch (error) {
        this.isSending = false;
      }
      if (res.result) {
        this.seahcvalue = [];
        // 重新赋值默认收件人
        this.defaultEmailaddress
          ? this.seahcvalue.push(this.defaultEmailaddress)
          : null;
        this.seahcvalues = [];
        this.seahcvaluesn = [];
        this.shortMessage.select = "";
        // 重新赋值默认主题
        this.email.theme = this.defaultSubject ? this.defaultSubject : "";
        this.email.content = "";
        this.selectValue = "";
        this.ccaddress = "";
        this.ccaddreses = "";
        this.bccaddress = "";
        this.bccaddreses = "";
        this.enclosure = [];
        this.shortMessage.labelValue = "";
        this.email.relatedid = "";
        this.isCcaddress = false;
        this.isBccaddress = false;
        this.featureAndDiePage = 1;
        this.lastMonthPage = 1;
        this.beforeMonthPage = 1;
        this.thisMonthPage = 1;
        if (res.returnInfo && res.returnInfo.length > 0) {
          this.$message(res.returnInfo);
        } else {
          this.$message.success(this.$i18n.t("label.apply.send.success"));
        }
        this.ActivityGetQuickActivityPages();
        this.GetActivityGetActivityLists();
        this.month();
        this.Lastmonth();
        this.more();
      }
      // if(this.objectApi === 'cloudcccase'){
      //     this.isConent = false
      //     this.isnewTask = true
      //   }else{
      // 邮件发送成功收起邮件页面
      this.isConent = false;
      this.isnewTask = true;
      // }
    },
    Goemail() {},
    urls() {
      // 这里不要删
      // this.$router.push({
      //   path: "/personalEmailBox",
      //   query: {
      //     userid: this.listInfo.userId,
      //     groupid: this.listInfo.orgId,
      //     lange: this.listInfo.language,
      //     countryCode: this.listInfo.countryCode,
      //     timezone: this.listInfo.timeZone,
      //     rtnURL: window.location.href,
      //   },
      // });
      if (this.emailUrl) {
        let url =
          this.emailUrl +
          "/#/?userid=" +
          this.listInfo.userId +
          "&" +
          "groupid=" +
          this.listInfo.orgId +
          "&" +
          "lange=" +
          this.listInfo.language +
          "&" +
          "countryCode=" +
          this.listInfo.countryCode +
          "&" +
          "timezone=" +
          this.listInfo.timeZone +
          "&" +
          "rtnURL=" +
          window.location.href;
        window.open(url);
      } else {
        this.$message.error(this.noEmailUrl);
      }
    },
    add() {
      this.dialogAddVisible = !this.dialogAddVisible;
      this.$refs.addfiles.initPage();
    },
    plus() {
      if (this.email.theme === "") {
        // 请输入主题
        this.$message.info(
          this.$i18n.t("vue_label_commonobjects_detail_enter_subject")
        );
      } else {
        this.$refs.isPop.plus();
      }
    },
    edit() {
      this.$refs.isPop.edit();
      this.$refs.isPop.getEmailObjectGetEmailTemplatess();
    },
    ids(index) {
      this.index = index;
      if (index === "1") {
        if (this.email.istrackopen == "1") {
          this.email.istrackopen = "0";
        } else {
          this.email.istrackopen = "1";
        }
      }
    },
    addWorkClose() {
      this.dialogAddVisible = !this.dialogAddVisible;
    },
    getworkList(n) {
      let arr = [];

      arr = n.filter((item) => {
        return item.checkedWork !== false;
      });
      if (arr.length !== 0 && this.enclosure.length === 0) {
        this.isEnclosure = true;
      } else if (arr.length === 0 && this.enclosure.length !== 0) {
        this.isEnclosure = true;
      } else if (arr.length !== 0 && this.enclosure.length !== 0) {
        this.isEnclosure = true;
      } else {
        this.isEnclosure = false;
      }
      if (arr.length === 0 && this.enclosure.length !== 0) {
        this.addWorkClose();
        return;
      }
      arr.forEach((item) => {
        let dataItem = {};
        dataItem.id = item.id;
        dataItem.fileName = item.name + "." + item.type;
        this.enclosure.push(dataItem);
      });
      this.addWorkClose();
    },
    handleClose(val) {
      this.enclosure.forEach((item, index) => {
        if (item.id === val.id) {
          this.enclosure.splice(index, 1);
        }
      });
    },
    // 插入模板
    async tmp(item) {
      this.temp = false;
      let params = {
        recordId: this.$route.params.id,
        templateId: item.id,
      };
      let res = await getEmailObjectGetTemplateBody(params);
      if (res.result) {
        // 选择模板时清空富文本内容
        // this.$refs.wangEditor.clearvalue();
        this.email.theme = res.data.subject;
        this.$refs.wangEditor.signContent(res.data.content, true);
        // 解决富文本光标乱跳
        this.$refs.wangEditor.isFocus = false;
        // 选择模板时，将返回的模板内容赋值
        this.email.content = res.data.content;
        // 查询邮件签名
        // this.getEmailObjectQuerySigns()
      }
    },
    editorContent(val) {
      this.email.content = val;
    },
    Manages() {
      this.$refs.emailTemplateFront.showDialog("email");
      this.showRelateid = this.$route.params.id;
      // this.$refs.Manage.Manages();
    },
    async inserts() {
      var ins = "";
      if (this.$refs.ist.fieldnameone === "") {
        return;
      } else if (this.$refs.ist.fieldnametwo === "") {
        let array = this.$refs.ist.item;

        if (array.id === undefined) {
          let params = {
            recordId: this.recordId,
            filedstr:
              "{!" +
              this.objectApi +
              "." +
              this.$refs.ist.items.fieldname +
              "}",
          };
          ins = params;
        } else {
          let params = {
            recordId: this.recordId,
            filedstr:
              "{!" +
              this.objectApi +
              "." +
              this.$refs.ist.items.fieldname +
              "}",
          };
          ins = params;
        }
      } else {
        let array = this.$refs.ist.item;

        if (array.id === undefined) {
          let params = {
            recordId: this.recordId,
            filedstr:
              "{!" +
              this.objectApi +
              "." +
              this.$refs.ist.fieldnameone +
              "." +
              this.$refs.ist.fieldnametwo +
              "}",
          };
          ins = params;
        } else {
          let params = {
            recordId: this.recordId,
            filedstr:
              "{!" +
              this.objectApi +
              "." +
              this.$refs.ist.fieldnameone +
              "." +
              this.$refs.ist.fieldnametwo +
              "}",
          };
          ins = params;
        }
      }

      let res = await getEmailObjectmergeField(ins);
      if (res.result) {
        if (res.data === null) {
          this.errdialog = true;
        } else {
          this.$refs.ist.clear();
          this.$refs.wangEditor.beforeEditorEdit(res.data);
          // this.content = this.content + res.data
        }
      }
      this.visibles = false;
    },
    ccaddresses() {
      this.isCcaddress = true;
    },
    bccaddresses() {
      this.isBccaddress = true;
    },
    tab() {},

    async queding() {
      if (this.types === "email") {
        let params = {
          id: this.listInfo.id,
        };
        let res = await getEmailObjectDelete(params);
        if (res.result) {
          this.centerDialogVisible = false;
          this.page = 1;
          this.featureAndDiePage = 1;
          this.lastMonthPage = 1;
          this.beforeMonthPage = 1;
          this.thisMonthPage = 1;
          this.GetActivityGetActivityLists();
          this.month();
          this.Lastmonth();
          this.more();
          this.hoverNull();
        }
      } else {
        this.isdown = true;
        if (this.listInfo.istask === "0") {
          this.objectApis = "Event";
        } else if (this.listInfo.istask === "1") {
          this.objectApis = "Task";
        }

        let params = {
          objectApi: this.objectApis,
          id: this.listInfo.id,
        };
        let res = await GetDelete(params);
        if (res.result) {
          this.centerDialogVisible = false;
          this.page = 1;
          this.featureAndDiePage = 1;
          this.lastMonthPage = 1;
          this.beforeMonthPage = 1;
          this.thisMonthPage = 1;
          this.GetActivityGetActivityLists();
          this.month();
          this.Lastmonth();
          this.more();
        }
      }
    },
    // 本月
    async month(val) {
      if (this.thisMonthPage == 1) {
        this.monthList = [];
      }
      let params = {
        recordId: val !== undefined ? val : this.recordId,
        type: "thismonth",
        page: this.thisMonthPage,
        pageSize: this.thisMonthPageSize,
      };
      let res = await GetActivityGetActivityList(params);
      if (res.result) {
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            if (item.iscompleted === "1") {
              this.checkListe.push(item);
            }
            if (
              item.taskoreventtype === undefined ||
              item.taskoreventtype === null
            ) {
              if (item.istask === "0") {
                (item.size = "large"),
                  (item.class = "shijian"),
                  (item.icon = "el-icon-date");
              } else if (item.istask === "1") {
                (item.size = "large"),
                  (item.class = "renwu"),
                  (item.icon = "el-icon-finished");
              }
            } else {
              (item.size = "large"),
                (item.class = "phone"),
                (item.icon = "el-icon-phone");
            }
            if (item.type === "email") {
              (item.size = "large"),
                (item.class = "email"),
                (item.icon = "el-icon-message");
              // 处理发件人、抄送人、密送人尖括号
              let reg = /<(.+?)>/g;
              if (item.toaddress) {
                let toaddressArg = item.toaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.toaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.toaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.toaddress += ";";
                    }
                  });
                }
              }
              if (item.fromaddress) {
                let toaddressArg = item.fromaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.fromaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.fromaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.fromaddress += ";";
                    }
                  });
                }
              }
              if (item.bccaddress) {
                let bccaddressArg = item.bccaddress.match(reg);
                if (Array.isArray(bccaddressArg)) {
                  item.bccaddress = "";
                  bccaddressArg.forEach((itemaddress, index) => {
                    item.bccaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (bccaddressArg.length - 1 !== index) {
                      item.bccaddress += ";";
                    }
                  });
                }
              }
              if (item.ccaddress) {
                let toaddressArg = item.ccaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.ccaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.ccaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.ccaddress += ";";
                    }
                  });
                }
              }
            }
            if (item.type === "sms") {
              item.size = "large";
              item.class = "sms";
            }
          });
        }
      }
      // 数据小于10条不显示查看更多按钮
      if (res.data.length < 10) {
        res.data.forEach((item) => {
          this.monthList.push(item);
        });
        this.thisMonthSeeMore = false;
      } else if (res.data.length == 10) {
        res.data.forEach((item) => {
          this.monthList.push(item);
        });
        this.againSeeMore(
          "thismonth",
          this.thisMonthPage + 1,
          this.thisMonthPageSize
        );
      }
      this.monthlength = this.monthList.length;
      // 如果没有数据不显示模块
      if (this.monthList.length > 0) {
        this.isNewtasks = true;
      } else {
        this.isNewtasks = false;
      }
      this.thisMonthLoading = false;
      if (this.monthList.length !== 0) {
        this.echoEmail = this.monthList.find((item) => item.type === "email");
      }
    },
    //上月
    async Lastmonth(val) {
      if (this.lastMonthPage == 1) {
        this.lastmonthlist = [];
      }
      let params = {
        recordId: val !== undefined ? val : this.recordId,
        type: "lastmonth",
        page: this.lastMonthPage,
        pageSize: this.lastMonthPageSize,
      };
      let res = await GetActivityGetActivityList(params);

      if (res.result) {
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            if (item.iscompleted === "1") {
              this.checkListes.push(item);
            }

            if (
              item.taskoreventtype === undefined ||
              item.taskoreventtype === null
            ) {
              if (item.istask === "0") {
                (item.size = "large"),
                  (item.class = "shijian"),
                  (item.icon = "el-icon-date");
              } else if (item.istask === "1") {
                (item.size = "large"),
                  (item.class = "renwu"),
                  (item.icon = "el-icon-finished");
              }
            } else {
              (item.size = "large"),
                (item.class = "phone"),
                (item.icon = "el-icon-phone");
            }
            if (item.type === "email") {
              (item.size = "large"),
                (item.class = "email"),
                (item.icon = "el-icon-message");
              // 处理发件人、抄送人、密送人尖括号
              let reg = /<(.+?)>/g;
              if (item.toaddress) {
                let toaddressArg = item.toaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.toaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.toaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.toaddress += ";";
                    }
                  });
                }
              }
              if (item.fromaddress) {
                let toaddressArg = item.fromaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.fromaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.fromaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.fromaddress += ";";
                    }
                  });
                }
              }
              if (item.bccaddress) {
                let bccaddressArg = item.bccaddress.match(reg);
                if (Array.isArray(bccaddressArg)) {
                  item.bccaddress = "";
                  bccaddressArg.forEach((itemaddress, index) => {
                    item.bccaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (bccaddressArg.length - 1 !== index) {
                      item.bccaddress += ";";
                    }
                  });
                }
              }
              if (item.ccaddress) {
                let toaddressArg = item.ccaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.ccaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.ccaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.ccaddress += ";";
                    }
                  });
                }
              }
            }
            if (item.type === "sms") {
              item.size = "large";
              item.class = "sms";
            }
          });
        }
      }
      if (res.data.length > 0) {
        if (res.data.length < 10) {
          this.lastMonthSeeMore = false;
        } else if (res.data.length == 10) {
          this.againSeeMore(
            "lastmonth",
            this.lastMonthPage + 1,
            this.lastMonthPageSize
          );
        }
        res.data.forEach((item) => {
          this.lastmonthlist.push(item);
        });
        this.Lastmonthlength = this.lastmonthlist.length;
      }
      // 有数据时，显示上月模块
      if (this.lastmonthlist.length > 0) {
        this.isNewtaskss = true;
      } else {
        this.isNewtaskss = false;
      }
      this.lastMonthLoading = false;
      if (
        this.lastmonthlist.length !== 0 &&
        (this.echoEmail === null || this.echoEmail?.$type === "more")
      ) {
        this.echoEmail = this.lastmonthlist.find(
          (item) => item.type === "email"
        );
      }
    },
    // 插入原文内容
    getEmailContent(arr) {
      let emailData = {};
      if (arr) {
        emailData = {
          detail: {
            fromaddress: arr.fromaddress ? arr.fromaddress : "",
            toaddress: arr.toaddress ? arr.toaddress : "",
            ccaddress: arr.ccaddress ? arr.ccaddress : "",
            bccaddress: arr.bccaddress ? arr.bccaddress : "",
            htmlbody: arr.content,
          },
        };
      }
      this.$nextTick(() => {
        this.$refs.wangEditor.wangEditorese(emailData);
      });
      setTimeout(() => {
        this.emailBeforeContent = this.email.content;
      }, 1000);
    },
    //更多
    async more(val) {
      if (this.beforeMonthPage == 1) {
        this.moreList = [];
      }
      let params = {
        recordId: val !== undefined ? val : this.recordId,
        type: "more",
        page: this.beforeMonthPage,
        pageSize: this.beforeMonthPageSize,
      };
      let res = await GetActivityGetActivityList(params);

      if (res.result) {
        if (res.data.length > 0) {
          res.data.forEach((item) => {
            if (item.iscompleted === "1") {
              this.checkListe.push(item);
            }
            if (
              item.taskoreventtype === undefined ||
              item.taskoreventtype === null
            ) {
              if (item.istask === "0") {
                (item.size = "large"),
                  (item.class = "shijian"),
                  (item.icon = "el-icon-date");
              } else if (item.istask === "1") {
                (item.size = "large"),
                  (item.class = "renwu"),
                  (item.icon = "el-icon-finished");
              }
            } else {
              (item.size = "large"),
                (item.class = "phone"),
                (item.icon = "el-icon-phone");
            }
            if (item.type === "email") {
              (item.size = "large"),
                (item.class = "email"),
                (item.icon = "el-icon-message");
              // 处理发件人、抄送人、密送人尖括号
              let reg = /<(.+?)>/g;
              if (item.toaddress) {
                let toaddressArg = item.toaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.toaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.toaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.toaddress += ";";
                    }
                  });
                }
              }
              if (item.fromaddress) {
                let toaddressArg = item.fromaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.fromaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.fromaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.fromaddress += ";";
                    }
                  });
                }
              }
              if (item.bccaddress) {
                let bccaddressArg = item.bccaddress.match(reg);
                if (Array.isArray(bccaddressArg)) {
                  item.bccaddress = "";
                  bccaddressArg.forEach((itemaddress, index) => {
                    item.bccaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (bccaddressArg.length - 1 !== index) {
                      item.bccaddress += ";";
                    }
                  });
                }
              }
              if (item.ccaddress) {
                let toaddressArg = item.ccaddress.match(reg);
                if (Array.isArray(toaddressArg)) {
                  item.ccaddress = "";
                  toaddressArg.forEach((itemaddress, index) => {
                    item.ccaddress += itemaddress.slice(
                      1,
                      itemaddress.length - 1
                    );
                    if (toaddressArg.length - 1 !== index) {
                      item.ccaddress += ";";
                    }
                  });
                }
              }
            }
            if (item.type === "sms") {
              item.size = "large";
              item.class = "sms";
            }
          });
        }
      }

      if (res.data.length > 0) {
        if (res.data.length < 10) {
          this.beforeMonthSeeMore = false;
        } else if (res.data.length == 10) {
          this.againSeeMore(
            "more",
            this.beforeMonthPage + 1,
            this.beforeMonthPageSize
          );
        }
        res.data.forEach((item) => {
          this.moreList.push(item);
        });
      }
      this.beforeMonthLoading = false;
      if (this.moreList.length !== 0 && this.echoEmail === null) {
        this.echoEmail = this.moreList.find((item) => item.type === "email");

        // 需要确定原文赋值必须本月最高，上月次之，更久之前最低。即将到来和已逾期不会有邮件
        if (this.echoEmail) {
          this.echoEmail.$type = "more";
        }
      }
    },

    // 弹窗关闭
    cancel() {
      this.isFilter = false;
      this.dialogAttr.isShow = false;
      this.zhucongfield = [];
    },
    // 新增/修改
    save(val) {
      if (val !== "") {
        this.featureAndDiePage = 1;
        this.lastMonthPage = 1;
        this.beforeMonthPage = 1;
        this.thisMonthPage = 1;
        this.GetActivityGetActivityLists();
        this.month();
        this.Lastmonth();
        this.more();
      }
    },
    // 本月
    orderScroll() {
      this.isdown = false;
      let a = this.$refs.Box.scrollHeight;
      let b = this.$refs.Box.clientHeight;
      let c = this.$refs.Box.scrollTop;
      if (b + parseInt(c) === a) {
        this.thisMonthPage = 1;
        this.thisMonthPage = this.thisMonthPage + 1;
        if (this.monthlength > 9) {
          this.month();
        }
      }
    },
    //上月
    orderScrolls() {
      this.isdown = false;
      let a = this.$refs.Boxs.scrollHeight;
      let b = this.$refs.Boxs.clientHeight;
      let c = this.$refs.Boxs.scrollTop;
      if (b + parseInt(c) === a) {
        this.lastMonthPage = this.lastMonthPage + 1;
        if (this.Lastmonthlength === 10) {
          this.Lastmonth();
        }
      }
    },
    //即将到来
    orderScrolles() {
      this.isdown = false;
      let a = this.$refs.Boxes.scrollHeight;
      let b = this.$refs.Boxes.clientHeight;
      let c = this.$refs.Boxes.scrollTop;
      if (b + parseInt(c) === a) {
        this.page = this.page + 1;
        this.GetActivityGetActivityLists();
      }
    },
    orderScrollss() {
      this.isdown = false;
      let a = this.$refs.Boxese.scrollHeight;
      let b = this.$refs.Boxese.clientHeight;
      let c = this.$refs.Boxese.scrollTop;

      if (b + parseInt(c) === a - 1) {
        this.moreListpage = this.moreListpage + 1;
        if (this.Morelength === 10) {
          this.more();
        }
      }
    },

    checks(e, it, type) {
      // 获取对象记录权限
      CommonObjApi.getPermissionById({ id: it.id }).then((res) => {
        if (res.data.isEdit) {
          this.straining = true;
          if (type === "soon") {
            if (it.iscompleted === "0") {
              let datas = [];
              let objectDatas = {
                id: it.id,
                iscompleted: "1",
              };
              datas.push(objectDatas);
              let params = {
                objectApi: "Task",
                data: JSON.stringify(datas),
              };

              this.commonObjApiSave(params);
            } else if (it.iscompleted === "1") {
              let datees = [];
              let objectDatas = {
                id: it.id,
                iscompleted: "0",
              };
              datees.push(objectDatas);
              let params = {
                objectApi: "Task",
                data: JSON.stringify(datees),
              };

              this.commonObjApiSave(params);
            }
          } else if (type === "month") {
            if (it.iscompleted === "0") {
              let datas = [];
              let objectDatas = {
                id: it.id,
                iscompleted: "1",
              };
              datas.push(objectDatas);
              let params = {
                objectApi: "Task",
                data: JSON.stringify(datas),
              };

              this.commonObjApiSave(params);
            } else if (it.iscompleted === "1") {
              let datees = [];
              let objectDatas = {
                id: it.id,
                iscompleted: "0",
              };
              datees.push(objectDatas);
              let params = {
                objectApi: "Task",
                data: JSON.stringify(datees),
              };

              this.commonObjApiSave(params);
            }
          } else if (type === "last") {
            if (it.iscompleted === "0") {
              let datas = [];
              let objectDatas = {
                id: it.id,
                iscompleted: "1",
              };
              datas.push(objectDatas);
              let params = {
                objectApi: "Task",
                data: JSON.stringify(datas),
              };

              this.commonObjApiSave(params);
            } else if (it.iscompleted === "1") {
              let datees = [];
              let objectDatas = {
                id: it.id,
                iscompleted: "0",
              };
              datees.push(objectDatas);
              let params = {
                objectApi: "Task",
                data: JSON.stringify(datees),
              };

              this.commonObjApiSave(params);
            }
          }
        } else {
          // 抱歉，您无权编辑此条数据，请联系系统管理员
          this.$message.error(this.$i18n.t("label.commonobjects.Nopermission"));
        }
        //  }
      });
    },

    /**根据是否是控制台样式  生成二级tab的饼跳转不同路由的方法
     * 服务控制台跳转方法
     * @param {Object} tabObj {id:对象id,name:对象名称} 生成二级tab所需参数
     *
     */
    consoleJump(tabObj) {
      let path = "";
      // 导航样式为控制台样式
      if (this.$store.state.navigationStyle) {
        path = `/commonObjects/console-multi-screen/console-detail/${tabObj.id}/DETAIL?dataId=${tabObj.id}`;
        // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
        // 当前一级tab的对象
        let currentLevelTab = {};
        this.consoleData.data.list.forEach((item) => {
          if (item.id == this.consoleData.data.level1Id) {
            // 设置二级带单选中样式
            item.level2Id = tabObj.id;
            // 当前一级tabid
            currentLevelTab.id = item.id;
            // 当前一级tabname
            currentLevelTab.name = item.name;
            // 当前一级菜单路由  有可能是通过ccdk生成的一级菜单
            currentLevelTab.routerName = item.routerName || "";
            currentLevelTab.routerPath = item.routerPath || "";
            // 当前一级菜单路由参数
            currentLevelTab.params = item.params || "";
            currentLevelTab.query = item.query || "";
            item.children = item.children ? item.children : [];
            // 判断children里是否存在此点击项 不存在再添加避免重复添加
            // 判断是否存在此点击项
            let res = item.children?.some((cItem) => {
              return cItem.id == tabObj.id;
            });
            if (!res) {
              // 如果不存在再push
              item.children.push({
                id: tabObj.id,
                name: tabObj.name,
              });
              // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则添加到第一项
              let flag = item.children.some((cItem) => {
                return cItem.id == this.consoleData.data.level1Id;
              });
              if (!flag) {
                item.children.unshift(currentLevelTab);
              }
            }
          }
        });
        this.$store.commit("setConsoleData", this.consoleData);
        // 将数据保存到后端
        consoleSaveTab({
          appid: this.$store.state.home.homeApplicationId,
          opentab: JSON.stringify(this.consoleData),
        });
      } else {
        path = `/commonObjects/detail/${tabObj.id}/DETAIL`;
      }
      return path;
    },
    /**
     *
     * @param {String} id
     * @param {String} name
     *
     */
    routers(id, name) {
      CommonObjApi.getPermissionById({ id: id }).then((res) => {
        if (res.data.objId === "user") {
          let path = this.consoleJump({ id: id, name: name });
          this.$router.push({
            path: path,
          });
          setTimeout(() => {
            this.activities = [];
            this.monthList = [];
            this.lastmonthlist = [];
            this.moreList = [];
            this.GetActivityGetActivityLists(id);
            this.month(id);
            this.lastMonthPage = 1;
            this.Lastmonth(id);
            this.more(id);
          }, 2000);
        }
      });
    },

    routeres(val) {
      if (val.type === "email") {
        return;
      } else {
        let path = this.consoleJump({ id: val.id, name: val.subject });
        this.$router.push({
          path: path,
          type: val.type,
        });
        // this.$router.push({
        //   path: `/commonObjects/console-multi-screen/console-detail/${val.id}/DETAIL`,
        //   query: {
        //     type: val.type,
        //   },
        // });
        // this.$router.push({
        //   path: `/commonObjects/detail/${val.id}/DETAIL`,
        //   query: {
        //     type: val.type,
        //   },
        // });
        setTimeout(() => {
          this.activities = [];
          this.monthList = [];
          this.lastmonthlist = [];
          this.moreList = [];
          this.GetActivityGetActivityLists(val.id);
          this.month(val.id);
          this.Lastmonth(val.id);
          this.more(val.id);
        }, 2000);
      }
    },
    getMonth() {
      let date = new Date();
      let month = date.getMonth() + 1;
      if (month === 1) {
        this.getmonth = this.$i18n.t("january");
      } else if (month === 2) {
        this.getmonth = this.$i18n.t("february");
      } else if (month === 3) {
        this.getmonth = this.$i18n.t("march");
      } else if (month === 4) {
        this.getmonth = this.$i18n.t("april");
      } else if (month === 5) {
        this.getmonth = this.$i18n.t("mary");
      } else if (month === 6) {
        this.getmonth = this.$i18n.t("june");
      } else if (month === 7) {
        this.getmonth = this.$i18n.t("july");
      } else if (month === 8) {
        this.getmonth = this.$i18n.t("augest");
      } else if (month === 9) {
        this.getmonth = this.$i18n.t("september");
      } else if (month === 10) {
        this.getmonth = this.$i18n.t("october");
      } else if (month === 11) {
        this.getmonth = this.$i18n.t("november");
      } else if (month === 12) {
        this.getmonth = this.$i18n.t("december");
      }

      let Last_month = date.getMonth();
      Last_month = Last_month == 0 ? 12 : Last_month;
      if (Last_month === 1) {
        this.lastmonth = this.$i18n.t("january");
      } else if (Last_month === 2) {
        this.lastmonth = this.$i18n.t("february");
      } else if (Last_month === 3) {
        this.lastmonth = this.$i18n.t("march");
      } else if (Last_month === 4) {
        this.lastmonth = this.$i18n.t("april");
      } else if (Last_month === 5) {
        this.lastmonth = this.$i18n.t("mary");
      } else if (Last_month === 6) {
        this.lastmonth = this.$i18n.t("june");
      } else if (Last_month === 7) {
        this.lastmonth = this.$i18n.t("july");
      } else if (Last_month === 8) {
        this.lastmonth = this.$i18n.t("augest");
      } else if (Last_month === 9) {
        this.lastmonth = this.$i18n.t("september");
      } else if (Last_month === 10) {
        this.lastmonth = this.$i18n.t("october");
      } else if (Last_month === 11) {
        this.lastmonth = this.$i18n.t("november");
      } else if (Last_month === 12) {
        this.lastmonth = this.$i18n.t("december");
      }
      // this.lastmonth = Last_month;

      let Front_month = date.getFullYear() + "/" + (this.lastmonth - 1);
      this.Front_month = Front_month;
    },
    // 获取当前系统时间的下一个整点以及整点之后一个小时的时间
    getHour() {
      let date = new Date();
      date.setHours(date.getHours() + 1);
      let y = date.getFullYear();
      let m =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let monthDay = y + "-" + m + "-" + d + " " + h + ":00:00";

      function getNextHour(intPoint) {
        let dd = new Date(intPoint);
        let Y = dd.getFullYear();
        let M =
          dd.getMonth() + 1 < 10
            ? "0" + (dd.getMonth() + 1)
            : dd.getMonth() + 1;
        let D = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
        let H =
          dd.getHours() + 1 < 10
            ? "0" + (dd.getHours() + 1)
            : dd.getHours() + 1;
        return Y + "-" + M + "-" + D + " " + H + ":00:00";
      }
      return {
        start: monthDay,
        end: getNextHour(monthDay),
      };
    },
    //获取相关项和名称
    async ActivityGetQuickActivityPages() {
      this.cities = [];
      let params = {
        recordId: this.recordId,
        type: "task",
      };
      let res = await ActivityGetQuickActivityPage(params);
      if (res.result) {
        res.data.relateobjList.forEach((item) => {
          let obj = {
            label: item.labelName,
            value: item.prefix,
            id: item.id,
          };
          if (this.prefix === "004" || this.prefix === "003") {
            if (this.activeName === "email") {
              this.shortMessage.select = res.data.relateobjList[0].labelName;
              this.shortMessage.objId = res.data.relateobjList[0].id;
              this.shortMessage.prefix = res.data.relateobjList[0].prefix;
            } else if (this.activeName === "message") {
              this.note.select = res.data.relateobjList[0].labelName;
              this.note.objId = res.data.relateobjList[0].id;
              this.note.prefix = res.data.relateobjList[0].prefix;
            } else if (this.activeName === "generateFile") {
              if (this.prefix === "004") {
                this.generateData.objId = res.data.whoobjInfoList[0].id;
                this.generateData.prefix = res.data.whoobjInfoList[0].prefix;
              } else {
                this.generateData.objId = res.data.whoobjInfoList[1].id;
                this.generateData.prefix = res.data.whoobjInfoList[1].prefix;
              }
            }
          } else if (this.prefix === "001") {
            this.generateData.objId = res.data.relateobjList[0].id;
            this.generateData.prefix = res.data.relateobjList[0].prefix;
          } else {
            this.generateData.objId = item.id;
            this.generateData.prefix = this.prefix;
            this.shortMessage.select = this.tabName;
            this.shortMessage.objId = item.id;
            this.shortMessage.prefix = this.prefix;
            this.shortMessage.labelValue = this.bigTitle[0]?.fieldValue;
            this.email.relatedid = this.dataId;
          }
          this.cities.push(obj);
        });
      }
    },
    optiones(item) {
      this.shortMessage.select = item.label;
      this.shortMessage.objId = item.id;
      this.shortMessage.prefix = item.value;
    },
    // // 查找所有人
    remoteSearchOwner() {
      // 所有人数据写死
      this.fieldId = "";
      if (this.activeName === "email") {
        this.relevantObjId = this.shortMessage.objId;
        this.relevantPrefix = this.shortMessage.prefix;
      } else if (this.activeName === "message") {
        this.relevantObjId = this.note.objId;
        this.relevantPrefix = this.note.prefix;
      }
      this.showSearchTables = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    // 改变查找/查找带值选项及值
    changeSelect(row) {
      if (row.data) {
        if (this.activeName === "email") {
          this.shortMessage.labelValue = row.data.name;
          this.email.relatedid = row.data.id;
          this.showSearchTables = false;
        } else if (this.activeName === "message") {
          this.note.labelValue = row.data.name;
          this.note.relatedid = row.data.id;
          this.showSearchTables = false;
        } else if (this.activeName === "generateFile") {
          this.generateRecordList = [];
          this.generateRecordList = [
            {
              id: row.data.id,
              name: row.data.name,
            },
          ];
          this.generateRecord = row.data.name;
          this.generateData.labelValue = row.data.name;
          this.generateData.relatedid = row.data.id;
          this.showSearchTables = false;
        }
      } else {
        //清空按钮
        this.showSearchTables = false;
        this.generateData.relatedid = "";
        this.generateRecord = "";
        this.generateRecordList = [];
      }
    },
    Autographes() {
      this.Autograph = !this.Autograph;
    },
    UploadSuccesses(val) {
      let dataItem = {};
      dataItem.id = val.id;
      dataItem.fileName = val.name + "." + val.type;
      this.enclosure.push(dataItem);
    },
    chakan() {
      this.ischakan = false;
      this.isNewtasksses = true;
    },
    replys(val, type) {
      this.$refs.Replyes.obtain(val, type);
    },
    hoverNull() {
      this.indexs = null;
      this.indexes = null;
      this.indexess = null;
      this.indexeses = null;
    },
    async show_Message() {
      let params = {
        onlyShowMyTemplate: "false",
        searchWord: "",
      };
      let res = await getTextMessageGetTextMessageTemplata(params);
      if (res.result) {
        this.smsTemplate = res.data;
      }
    },
    smsTemplateDetail(val) {
      this.visibleMessage = false;
      this.note.content = val.mainbody;
    },
    // 选中表情包事件
    bindChange(item) {
      this.note.content += item.text;
      // this.textarea += item.text;
    },
    querySearchAsync(queryString) {
      let res = this.smsTemplate.filter((item) => {
        return item.name && item.name.indexOf(queryString) > -1;
      });
      this.smsTemplate = res;
    },
    handleSelect(item) {
      this.selectValues += item.name + ",";
      this.selectValue = this.selectValues;
      if (item.email !== "") {
        this.email.addressees += "," + item.email;
      }
    },
    handleSelects(item) {
      this.ccaddreses += item.name + ",";
      this.ccaddress = this.ccaddreses;
      if (item.email !== "") {
        if (this.email.ccaddress === "") {
          this.email.ccaddress = item.email;
        } else {
          this.email.ccaddress += "," + item.email;
        }
      }
    },
    handleSelectes(item) {
      this.bccaddreses += item.name + ",";
      this.bccaddress = this.bccaddreses;
      if (item.email !== "") {
        if (this.email.bccaddress === "") {
          this.email.bccaddress = item.email;
        } else {
          this.email.bccaddress += "," + item.email;
        }
      }
    },
    async remoteMethod(query) {
      this.queryvalue = query;
      let str = query.replace(/'/g, "");
      let params = {
        searchKey: str,
        type: this.objectApi,
      };
      let res = await getEmailObjectSearchEmail(params);
      if (res.result) {
        if (res.data != null) {
          let peoplearr = [];
          res.data.forEach((item) => {
            let obj = {};
            obj.name = item.email;
            obj.value = item.name;
            obj.id = item.id;
            obj.objid = item.objid;
            peoplearr.push(obj);
          });
          this.countries = peoplearr;
        } else {
          this.countries = [];
        }
      }
      if (this.countries.length !== 0) {
        this.$refs.multiselect.$refs.list.style = "display: block;";
        this.$refs.multiselect.$refs.tags.style =
          "border-bottom-left-radius:0px;border-bottom-right-radius:0px;";
      } else {
        this.$refs.multiselect.$refs.list.style = "display: none;";
        this.$refs.multiselect.$refs.tags.style =
          "border-bottom-left-radius:5px;border-bottom-right-radius:5px;";
      }
    },
    async remoteMethods(query) {
      this.queryvalue = query;
      if (query.length >= 2) {
        let str = query.replace(/'/g, "");
        let params = {
          searchKey: str,
          type: this.objectApi,
        };
        let res = await getEmailObjectSearchEmail(params);
        if (res.result) {
          if (res.data != null) {
            let peoplearr = [];
            res.data.forEach((item) => {
              let obj = {};
              obj.name = item.email;
              obj.value = item.name;
              obj.id = item.id;
              obj.objid = item.objid;
              peoplearr.push(obj);
            });
            this.countriess = peoplearr;
          } else {
            this.countriess = [];
          }
        }
        if (this.countriess.length !== 0) {
          this.$refs.multiselects.$refs.tags.style =
            "border-bottom-left-radius:0px;border-bottom-right-radius:0px;";
          this.$refs.multiselects.$refs.list.style = "display: block;";
        } else {
          this.$refs.multiselects.$refs.tags.style =
            "border-bottom-left-radius:5px;border-bottom-right-radius:5px;";
          this.$refs.multiselects.$refs.list.style = "display: none;";
        }
      }
    },
    async remoteMethodes(query) {
      this.queryvalue = query;
      if (query.length >= 2) {
        let str = query.replace(/'/g, "");
        let params = {
          searchKey: str,
          type: this.objectApi,
        };
        let res = await getEmailObjectSearchEmail(params);
        if (res.result) {
          if (res.data != null) {
            let peoplearr = [];
            res.data.forEach((item) => {
              let obj = {};
              obj.name = item.email;
              obj.value = item.name;
              obj.id = item.id;
              obj.objid = item.objid;
              peoplearr.push(obj);
            });
            this.countriessn = peoplearr;
          } else {
            this.countriessn = [];
          }
        }
        if (this.countriessn.length !== 0) {
          this.$refs.multiselectes.$refs.tags.style =
            "border-bottom-left-radius:0px;border-bottom-right-radius:0px;";
          this.$refs.multiselectes.$refs.list.style = "display: block;";
        } else {
          this.$refs.multiselectes.$refs.tags.style =
            "border-bottom-left-radius:5px;border-bottom-right-radius:5px;";
          this.$refs.multiselectes.$refs.list.style = "display: none;";
        }
      }
    },
    searches() {
      this.showAddCampaignMembers = true;
      if (this.checkedList.length !== 0) {
        this.$refs.echo.echos(this.checkedList);
      }
    },
    closeAddCampaignMembersDialog() {
      this.showAddCampaignMembers = false;
    },
    next(val) {
      this.checkedList = "";
      this.note.addressees = "";
      this.note.addressee = "";
      this.checkedList = val;
      this.note.addressees = val.map((res) => res.id).join();
      this.note.addressee = val.map((res) => res.name).join();
      this.showAddCampaignMembers = false;
    },
    async nodeSend() {
      let params = {
        ids: this.note.addressees,
        content: this.note.content,
        relateid: this.note.relatedid,
        type: 0, // 1表示一起发送，0表示分别发送
      };
      let res = await getTextMessageSendTextMessage(params);
      if (res.result) {
        if (res.data.sendstatus === "0") {
          this.$message.success(this.$i18n.t("label.apply.send.success"));
        } else {
          // 短信发送失败
          this.$message.error(this.$i18n.t("label.sms.senderr"));
        }

        // this.note.addressees = "";
        this.note.content = "";
        this.note.labelValue = "";
        this.note.relatedid = "";
        this.ismessage = true;
        // 重新获取活动列表数据
        this.featureAndDiePage = 1;
        this.lastMonthPage = 1;
        this.beforeMonthPage = 1;
        this.thisMonthPage = 1;
        this.GetActivityGetActivityLists();
        this.month();
        this.Lastmonth();
        this.more();
      }
    },
    // 查询邮件签名
    async getEmailObjectQuerySigns() {
      let res = await getEmailObjectQuerySign();
      if (res.result) {
        if (
          res.data !== undefined ||
          res.data !== null ||
          JSON.stringify(res.data) !== "{}"
        ) {
          if (res.data.isusing === "true") {
            this.$nextTick(() => {
              // 确保放到最上面
              this.$refs.wangEditor.signContent(res.data.signContent, true);
            });
          }
        }
      }
    },
    sameDay() {
      this.timestamp = Date.parse(new Date());
    },
    //手动填入邮箱
    //     合法E-mail地址：
    // 1. 必须包含一个并且只有一个符号“@”
    // 2. 第一个字符不得是“@”或者“.”
    // 3. 不允许出现“@.”或者.@
    // 4. 结尾不得是字符“@”或者“.”
    addmytag() {
      if (this.seahcvalueIndex >= this.seahcvalue.length) {
        let reg = /^[^.](.*[^.])*@[^.].*\..*[^.]$/;
        let newReg = /^[^@]*@[^@]*$/;
        // let reg = /^([a-zA-Z0-9]+[-_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
        if (reg.test(this.queryvalue) && newReg.test(this.queryvalue)) {
          let istrue = this.seahcvalue.some((item) => {
            return item.name == this.queryvalue;
          });
          if (istrue == false) {
            let obj = {};
            obj.name = this.queryvalue;
            obj.value = this.queryvalue;
            obj.id = this.queryvalue;
            obj.objid = this.queryvalue;
            this.seahcvalue.push(obj);
            // 同步seahcvalueIndex，解决一次添加多个邮箱失败
            this.seahcvalueIndex = this.seahcvalue.length;
            this.$refs.multiselect.search = "";
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"), //该邮箱已添加
              type: "error",
            });
            this.countries = [];
          }
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_correct_email_address"
            ), //请输入正确的邮箱地址
            type: "error",
          });
          // this.$refs.multiselect.search = "";
          this.countries = [];
        }
      }
    },
    //手动填入邮箱
    addmytagtwo() {
      if (this.seahcvalueIndexTwo >= this.seahcvalues.length) {
        let reg = /^[^.](.*[^.])*@[^.].*\..*[^.]$/;
        let newReg = /^[^@]*@[^@]*$/;
        if (reg.test(this.queryvalue) && newReg.test(this.queryvalue)) {
          let istrue = this.seahcvalues.some((item) => {
            return item.name == this.queryvalue;
          });
          if (istrue == false) {
            let obj = {};
            obj.name = this.queryvalue;
            obj.value = this.queryvalue;
            obj.id = this.queryvalue;
            obj.objid = this.queryvalue;
            this.seahcvalues.push(obj);
            this.seahcvalueIndexTwo = this.seahcvalues.length;
            this.$refs.multiselects.search = "";
            this.countriess = [];
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"), //该邮箱已添加
              type: "error",
            });
            this.countriess = [];
          }
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_correct_email_address"
            ), //"请输入正确的邮箱地址",
            type: "error",
          });
          // this.$refs.multiselects.search = "";
          this.countriess = [];
        }
      }
    },
    //手动填入邮箱
    addmytagthree() {
      if (this.seahcvalueIndexThree >= this.seahcvaluesn.length) {
        let reg = /^[^.](.*[^.])*@[^.].*\..*[^.]$/;
        let newReg = /^[^@]*@[^@]*$/;
        if (reg.test(this.queryvalue) && newReg.test(this.queryvalue)) {
          let istrue = this.seahcvaluesn.some((item) => {
            return item.name == this.queryvalue;
          });
          if (istrue == false) {
            let obj = {};
            obj.name = this.queryvalue;
            obj.value = this.queryvalue;
            obj.id = this.queryvalue;
            obj.objid = this.queryvalue;
            this.seahcvaluesn.push(obj);
            this.seahcvalueIndexThree = this.seahcvaluesn.length;
            this.$refs.multiselectes.search = "";
            this.countriessn = [];
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"), //该邮箱已添加
              type: "error",
            });
            this.countriessn = [];
          }
        } else {
          this.$message({
            showClose: true,
            message: this.$i18n.t(
              "vue_label_commonobjects_detail_correct_email_address"
            ),
            type: "error",
          });
          // this.$refs.multiselectes.search = "";
          this.countriessn = [];
        }
      }
    },
    open() {
      this.$nextTick(() => {
        this.seahcvalueIndex = this.seahcvalue.length;
        if (
          this.$refs.multiselect.search === "" &&
          this.seahcvalue.length === 0
        ) {
          this.$refs.multiselect.$refs.tags.style =
            "border-bottom-left-radius:5px;border-bottom-right-radius:5px;";
          this.$refs.multiselect.$refs.list.style = "display: none;";
        }
      });
      // let div = document.querySelectorAll(".noResult");
      // div[0].style.width = "10px";
      // div[0].style.display = "inline-block";
    },
    opens() {
      this.$nextTick(() => {
        this.seahcvalueIndexTwo = this.seahcvalues.length;
        if (
          this.$refs.multiselects.search === "" &&
          this.seahcvalues.length === 0
        ) {
          this.$refs.multiselects.$refs.tags.style =
            "border-bottom-left-radius:5px;border-bottom-right-radius:5px;";
          this.$refs.multiselects.$refs.list.style = "display: none;";
        }
      });
      // let div = document.querySelectorAll(".noResult");
      // div[0].style.width = "10px";
      // div[0].style.display = "inline-block";
    },
    openes() {
      this.$nextTick(() => {
        this.seahcvalueIndexThree = this.seahcvaluesn.length;
        if (
          this.$refs.multiselectes.search === "" &&
          this.seahcvaluesn.length === 0
        ) {
          this.$refs.multiselectes.$refs.tags.style =
            "border-bottom-left-radius:5px;border-bottom-right-radius:5px;";
          this.$refs.multiselectes.$refs.list.style = "display: none;";
        }
      });
    },
    //添加字段取值失败后，继续添加字段
    Continueadd() {
      this.errdialog = false;
      let fieldstr = `<span style="color:red">{${this.insertFields}}</span>`;
      // this.editorText = this.editorText + fieldstr;
      this.$refs.wangEditor.beforeEditorEdit(fieldstr);
    },
    addtagtest() {
      if (this.$refs.multiselect.search !== "") {
        this.addmytag();
      }
    },
    addtagtests() {
      if (this.$refs.multiselects.search !== "") {
        this.addmytagtwo();
      }
    },
    addtagtestes() {
      if (this.$refs.multiselectes.search !== "") {
        this.addmytagthree();
      }
    },
    calendarNew() {
      let prefix = this.recordId.substring(0, 3);
      if (this.recordId && this.bigTitle[0].fieldValue) {
        this.$router.push({
          path: `/CalendarHeader`, // 路由
          query: {
            id: this.recordId, // 记录id
            objId: this.objectApi, // 对象Api
            prefix: prefix, // 对象前缀
            name: this.bigTitle[0].fieldValue, // 记录名称
            tabName: this.tabName, // 对象名称（例如：潜在客户，业务机会）
          },
        });
      } else {
        this.$message.warning(this.$i18n.t("message.error.SQLTimeout"));
      }
    },
    commonObjApiSave(params) {
      CommonObjApi.save(params).then((res) => {
        if (res.result) {
          res.data.forEach((item) => {
            if (item.isSaveSuccess !== "true") {
              if(item.errormessage && item.errormessage.includes('illegal_field_apiname')){
                // 值不存在或与筛选条件不匹配
                this.$message.error(this.$i18n.t("c2420"));
              }else{
                this.$message.error(item.errormessage);
              }

            } else {
              this.featureAndDiePage = 1;
              this.lastMonthPage = 1;
              this.beforeMonthPage = 1;
              this.thisMonthPage = 1;
              this.GetActivityGetActivityLists();
              this.month();
              this.Lastmonth();
              this.more();
              this.$nextTick(() => {
                this.straining = false;
              });
            }
          });
        }
      });
    },
    //刷新
    refresh: debounce(function () {
      this.init();
    }, 1000),
    //全部展开
    expandAll() {
      if (this.isExpandAll === false) {
        this.isExpandAll = true;
      } else {
        this.isExpandAll = false;
      }
    },
  },
  watch: {
    activeName(val) {
      if (val === "message") {
        this.ActivityGetQuickActivityPages();
        this.note.addressee = this.bigTitle[0].fieldValue;
        this.note.addressees = this.dataId;
      } else if (val === "email") {
        this.ActivityGetQuickActivityPages();
        // 个案、联系人、潜客情况下，当有默认的主题和收件人时，展示默认的，没有展示记录里的
        if (
          !this.emailaddressOrSubject &&
          (this.objectApi === "cloudcccase" ||
            this.objectApi === "Lead" ||
            this.objectApi === "cloudcclead" ||
            this.objectApi === "Contact")
        ) {
          // 当最后一封邮件为发出的邮件，那么收件人是该封邮件的收件人；如果最后一封邮件为收到的邮件，那么收件人是该封邮件的发件人,incoming为1是接收，为0是发送
          if (this.echoEmail) {
            if (this.echoEmail.incoming === "1") {
              this.seahcvalue = [];
              let obj = {
                name: this.echoEmail.fromaddress,
                value: this.echoEmail.fromaddress,
              };
              this.seahcvalue.push(obj);
            } else if (this.echoEmail.incoming === "0") {
              this.seahcvalue = [];
              let toaddressAry = this.echoEmail.toaddress.split(";");
              toaddressAry.forEach((item) => {
                let obj = {
                  name: item,
                  value: item,
                };
                this.seahcvalue.push(obj);
              });
            }
            //  改变主题
            if (this.echoEmail.subject) {
              this.email.theme =
                this.$i18n.t("label.emailobject.emaildetail.button.reply") +
                ":" +
                this.echoEmail.subject;
            } else {
              this.email.theme = this.echoEmail.subject;
            }
          }
        }
      } else if (val === "task") {
        this.mold = "bfa";
        this.tabNames = this.$i18n.t("label.exchange.sync.state.task");
      } else if (val === "event") {
        this.mold = "bef";
        this.tabNames = this.$i18n.t("label.mobile.quickcreate.default.events");
      } else if (val === "generateFile") {
        if (
          this.generateObjlist.length > 0 ||
          this.generateFolderlist.length > 0
        ) {
          this.generateLoading = false;
        } else {
          this.generateLoading = true;
          this.getMailMergePageInfo();
          this.ActivityGetQuickActivityPages();
        }
        this.generateRecordList[0].id = this.recordId;
        this.generateRecordList[0].name = this.bigTitle[0]?.fieldValue;
      }
    },
    visibleMessage(val) {
      if (val === true) {
        this.show_Message();
      }
    },
    prefix(val) {
      this.shortMessage.select = val;
      if (val === "008") {
        this.isConent = false;
        this.isnewTask = true;
      }
    },
    // 是否显示查看更早以前按钮方法
    Morelength(val) {
      let earlier = val === null ? 0 : val; //更早之前的数据
      let instant = this.monthlength === null ? 0 : this.monthlength; //当月数据
      let lastMoney = this.Lastmonthlength === null ? 0 : this.Lastmonthlength; //上月数据
      if (earlier !== 0 && instant !== 0 && lastMoney === 0) {
        // 当月，更早>0，上月=0，显示查看更早按钮
        this.ischakan = true;
      } else if (earlier !== 0 && instant === 0 && lastMoney === 0) {
        // 当月=0，更早>0，上月=0，不显示查看更早按钮
        this.ischakan = false;
        this.isNewtasksses = true;
      } else if (earlier !== 0 && instant === 0 && lastMoney !== 0) {
        // 当月=0，更早>0，上月>0，显示查看更早按钮
        this.ischakan = true;
      } else if (earlier === 0 && instant === 0 && lastMoney === 0) {
        // 当月=0，更早=0，上月=0，不显示查看更早按钮
        this.ischakan = false;
      } else if (
        (earlier !== 0 && this.beforeMonthPage > 1 && instant !== 0) ||
        lastMoney !== 0
      ) {
        // 更早>0,当月>0，上月>0，显示查看更早按钮
        this.ischakan = false;
        this.isNewtasksses = true;
      } else if (
        (earlier !== 0 && this.beforeMonthPage == 1 && instant !== 0) ||
        lastMoney !== 0
      ) {
        // 更早>0,当月>0，上月>0，显示查看更早按钮
        this.ischakan = true;
        this.isNewtasksses = false;
      } else if ((earlier === 0 && instant !== 0) || lastMoney !== 0) {
        // 更早=0,当月>0，上月>0，不显示查看更早按钮
        this.ischakan = false;
      }
    },
    // 编辑签名弹窗显示隐藏
    Autograph(val) {
      if (val) {
        this.$refs.Autograph.getEmailObjectQuerySigns();
      } else {
        // 清空签名富文本内容
        this.$refs.Autograph.$refs.wangEditoresRef.clearvalue();
      }
    },
    seahcvalue() {},
    format() {
      true;
      true;
    },
  },
};
</script>
<style lang="scss">
// 邮件记录回复
.backEmail {
  display: flex;
  p {
    display: flex;
    align-items: center;
  }
}
// svg样式
.weichuli {
  width: 100%;
  height: 100px;
  text-align: center;
}
.iconReply {
  display: inline-block;
}
.iconSms {
  display: inline-block;
  margin-left: 5px;
  width: 15px;
  height: 16px;
}
.fujian {
  display: inline-block;
  margin-right: 5px;
  width: 13px;
  height: 13px;
  cursor: pointer;
}
.Autograph_padding {
  padding: 0px !important;
}

.autocompletePoper {
  .el-scrollbar {
    .el-autocomplete-suggestion__wrap {
      max-width: none !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.box-card {
  width: 100%;
  border-radius: 3px;
  // padding: 0px;
}

::v-deep .box_active {
  .el-card__body {
    padding: 0 !important;
  }
}
.file {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .filelist:first-child {
    margin-left: 0;
  }
  .filelist {
    margin-left: 10px;
    cursor: pointer;
  }
  .fileName {
    font-size: 12px;
    color: black;
  }
}

.header {
  width: 100%;
  height: 32px;
  background: #f3f2f2;
  padding: 5px;
  font-size: 14px;
  color: #080707;
  padding-left: 8px;
  border-radius: 3px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  .titles {
    font-size: 12px;
    font-weight: bold;
    font-family: SourceHanSansCN-Medium;
  }
}

::v-deep .input {
  float: left;

  ::v-deep .el-input__inner {
    width: 317px;
    height: 30px;
    margin-top: 10px;
    margin-left: 8px;
  }
}

::v-deep .submit {
  float: right;
  width: 50px;
  height: 26px;
  background: #006dcc;
  color: #ffff;
  margin-top: -28px;
  margin-right: 10px;

  span {
    position: relative;
    top: -5px;
    left: -9px;
  }
}

.text_right {
  color: #006dcc;
  cursor: pointer;
  text-align: right;
  margin-right: 10px;
  margin-top: 15px;
  font-size: 12px;
}

.comingSoones {
  margin-top: 10px;
}

.comingSoon {
  width: 100%;

  .icons {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 10px;
  }
}

.timeline {
  margin-left: 30px;
  margin-top: 20px;
  font-size: 12px;
}

::v-deep .el-timeline-item__wrapper {
  position: relative;
  padding-left: 28px;

  .el-timeline-item__content {
    position: relative;

    .iconfonts {
      position: absolute;
      left: -60px;
      padding-top: 3px;
    }

    .rightes {
      min-width: 97px;
      float: right;
      position: relative;
      font-size: 12px;
      text-align: right;
    }
  }
}

.xiala {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  cursor: pointer;
}

.dialog_header {
  width: 100%;
}

::v-deep .taskFilter {
  position: absolute;
  top: -6%;
  right: 0;
  bottom: 0;
  left: -14%;
  overflow: auto;
  margin: 0;

  .el-dialog {
    width: 273px;
    height: 367px;
  }

  .title {
    font-size: 14px;
    color: #080707;
    line-height: 17px;
  }

  .dateRange {
    width: 240px;

    .radio {
      .el-radio {
        margin-right: 40px;
        margin-top: 10px;
      }

      .right {
        margin-left: 4px;
      }
    }
  }

  .showActivity {
    margin-top: 20px;

    .showActivity_radio {
      margin-top: 10px;
    }
  }

  .active_type {
    margin-top: 10px;

    .active_type_checkbox {
      .el-checkbox {
        margin-top: 10px;
      }
    }
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #edebeb;

    .dialog-footer {
      position: relative;
      left: -44px;

      .el-button {
        width: 70px;
        height: 35px;
      }
    }
  }
}

.arrow {
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-right: 10px solid;
  border-bottom: 10px solid transparent;
  position: absolute;
  top: 33%;
  left: -10px;
  border-right-color: #fff;
}

.conent {
  height: 100%;
}

::v-deep .el-form-item {
  margin-bottom: 0px;

  // ::v-deep .el-form-item__label {
  //   // width: 25% !important;
  //   text-align: left;

  // }

  ::v-deep .el-form-item__content {
    margin-left: -6px !important;
    display: flex;
  }

  .display {
    display: flex;
    margin-top: 42px;
    margin-left: 8px;
    margin-right: -144px;
  }
}

.checkbox {
  margin-top: 10px;
  margin-bottom: 10px;
}

::v-deep .submits {
  display: block;
  width: 94px;
  height: 26px;
  margin: 20px auto 0px;

  span {
    position: relative;
    top: -5px;
  }
}

::v-deep .editTask {
  .el-dialog {
    margin-top: 6vh !important;
  }

  .el-dialog__header {
    padding: 20px 20px 2;
  }

  .editTask_header {
    width: 100%;
    height: 30px;
    background-color: #ebeaea;
    line-height: 30px;
    padding-left: 20px;
  }

  ::v-deep .el-row {
    margin-left: 10px !important;

    .el-input {
      width: 85% !important;
    }
  }

  .editTask_footer {
    width: 100%;
    height: 30px;
    background-color: #ebeaea;
    line-height: 30px;
    padding-left: 20px;
  }

  .editTask_footer_textarea {
    margin: 0 auto;
    width: 455px;

    ::v-deep .el-textarea__inner {
      min-height: 100px !important;
    }
  }
}

::v-deep .event {
  .el-dialog {
    margin-top: 6vh !important;
  }

  .el-dialog__header {
    padding: 20px 20px 2;
  }

  .event_header {
    width: 100%;
    height: 30px;
    background-color: #ebeaea;
    line-height: 30px;
    padding-left: 20px;
  }

  ::v-deep .el-row {
    margin-left: 10px !important;

    .el-col-12 {
      padding-right: 0px !important;
    }

    .el-input {
      width: 85% !important;
    }
  }

  .event_footer {
    width: 100%;
    height: 30px;
    background-color: #ebeaea;
    line-height: 30px;
    padding-left: 20px;
  }

  .event_footer_textarea {
    margin: 0 auto;
    width: 455px;

    ::v-deep .el-textarea__inner {
      min-height: 100px !important;
    }
  }

  .event_time {
    width: 100%;
    height: 30px;
    background-color: #ebeaea;
    line-height: 30px;
    padding-left: 20px;
  }

  .event_time_footer {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;

    .checkbox {
      margin-bottom: 10px;
    }
  }
}

::v-deep .tasks {
  .el-dialog {
    margin-top: 6vh !important;
  }

  .el-dialog__header {
    padding: 20px 20px 2;
  }

  .tasks_header {
    width: 100%;
    height: 30px;
    background-color: #ebeaea;
    line-height: 30px;
    padding-left: 20px;
  }

  ::v-deep .el-row {
    margin-left: 10px !important;

    .el-col-12 {
      padding-right: 0px !important;
    }

    .el-input {
      width: 85% !important;
    }
  }

  .tasks_footer {
    width: 100%;
    height: 30px;
    background-color: #ebeaea;
    line-height: 30px;
    padding-left: 20px;
  }

  .tasks_footer_textarea {
    margin: 0 auto;
    width: 455px;

    ::v-deep .el-textarea__inner {
      min-height: 100px !important;
    }
  }
}

::v-deep .el-input-group__prepend {
  background: #fff;
}

::v-deep .el-timeline-item {
  padding: 0px;
}

::v-deep .rowButton {
  margin-top: 13px;
  float: right;
  position: absolute;

  .el-button {
    width: 27px;
    height: 27px;
    padding: 12px 14px;
    border-radius: 0px !important;
  }
  .buttonSvg {
    display: inline-block;
    margin-top: 6px;
    width: 30px;
    height: 27px;
    border-radius: 0px !important;
    border: 1px solid #dcdfe6;
  }
}
.templateSvg {
  display: inline-block;
  width: 16px;
  height: 16px;
}
::v-deep .el-input-group__append {
  border-left: none;
  background-color: #ffff;
}

::v-deep .appends {
  .el-input__inner {
    height: 33px;
  }

  .el-form-item.is-error {
    border-color: black !important;
  }
}

::v-deep .el-input__inner {
  height: 30px;
}

/* ::v-deep .el-icon-search{
    position: relative;
    left: 13px;
  } */
::v-deep .newTasks {
  .el-input__prefix {
    right: 5px;
    left: initial;
    transition: all 0.3s;
  }
}

::v-deep .newTask {
  .el-input {
    .el-input__inner {
      font-size: 12px !important;
      color: #666666 !important;
      border: 1px solid #dedcda !important;
    }
  }
}

::v-deep .emails_addressee {
  position: relative;

  .el-form-item__content {
    span {
      width: 100% !important;
    }
  }

  .emails_addressee_button {
    position: absolute;
    right: 10px;

    span {
      margin-right: 15px;
      color: #006dcc;
      cursor: pointer;
    }
  }

  /* .el-form-item__label {
      margin-left: -79px;
    } */
}

.fasong {
  width: 70px;
  height: 30px;
  float: right;
  padding: 5px;
  position: relative;
  top: 45px;
}

::v-deep .el-col {
  position: relative;
  top: -15px;
  margin-right: 20px;
}

/* ::v-deep .el-form-item__error {
    display: none;
  } */

::v-deep .el-popover {
  height: 565px;
}

.determine {
  color: #ffffff;
  background-color: #006dcc;
}

.icon {
  display: block;
  padding-top: 25px;
  padding-left: 10px;
  cursor: pointer;
  font-size: 14px;

  font-weight: 400;
  color: #333333;
  width: 100%;
  height: 40px;
}

.el-icon-plus:before {
  margin-right: 10px;
  width: 13px;
  height: 13px;
  color: #000000;
}

.el-icon-edit:before {
  margin-right: 10px;
  width: 13px;
  height: 13px;
  color: #fcb95b;
}

.adds {
  color: #ffffff !important;
}

::v-deep .el-timeline-item__node--large {
  width: 18px;
  height: 18px;
}

::v-deep .el-timeline-item__node {
  border-radius: 2px;
}

::v-deep .renwu {
  .el-timeline-item__node {
    background: #4bc076;
    color: #fff;
  }
}

::v-deep .phone {
  .el-timeline-item__node {
    background: #49c3cc;
    color: #fff;
  }
}

::v-deep .email {
  .el-timeline-item__node {
    background: #95afc5;
    color: #fff;
  }
}
::v-deep .sms {
  .el-timeline-item__node {
    background: #ffff;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-image: url("~@/assets/noteSms/incomingsms.svg");
  }
}

::v-deep .shijian {
  .el-timeline-item__node {
    background: #eb7092;
    color: #fff;
  }
}

::v-deep .base-form {
  ::v-deep .pull-left {
    padding-right: 35px;
    margin-bottom: 10px;
  }
}

::v-deep .el-timeline-item__tail {
  left: 5px !important;
  top: 22px;
  height: calc(100% - 20px);
  border-left: 1px solid #e4e7ed;
}

::v-deep .el-checkbox-group {
  float: left;
}

::v-deep .el-checkbox__label {
  // display: none;
}

::v-deep .event-item-l {
  margin-right: 10px;
  width: 115px !important;
}

::v-deep .event-item-r {
  width: 750px !important;
}

::v-deep .check {
  .el-link.el-link--primary {
    color: #ccc;
    text-decoration: line-through;
  }
}

::v-deep .timeline {
  .el-loading-mask {
    left: -30px;
  }
}

::v-deep .tabs {
  border-radius: 3px;

  .el-tabs__header {
    background-color: #f5f5f5 !important;

    .el-tabs__nav-wrap {
      .el-tabs__nav-scroll {
        .el-tabs__nav {
          .el-tabs__item {
            font-family: MicrosoftYaHei !important;
            font-size: 12px !important;
            color: #080707 !important;
            letter-spacing: 0;
            text-align: center !important;
            padding: 0 10px !important;
            min-width: 50px;
            width: auto;
          }

          // .el-tabs__item:last-child {
          //   padding: 0 !important;
          //   min-width: 70px !important;
          // }

          .is-active {
            font-family: MicrosoftYaHei !important;
            font-size: 12px !important;
            color: #006dcc !important;
            letter-spacing: 0;
            font-weight: normal !important;
            border-right-color: #dedcda !important;
            border-left-color: #dedcda !important;
          }
        }
      }
    }
  }

  .el-tabs__content {
    padding: 10px !important;
    .img_box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 75px 0;
    }
  }
}

::v-deep .short {
  .shor_form {
    .el-form-item {
      .el-form-item__label {
        float: none !important;
      }

      .el-form-item__content {
        line-height: 0;
        margin-bottom: 15px;

        .el-input {
          .el-input__inner {
            width: 90%;
            height: 30px;
            margin-left: 10px;
          }
        }

        .el-textarea {
          margin-left: 10px;
          width: 100%;

          .el-textarea__inner {
            height: 155px;
          }
        }
      }
    }
  }

  .label {
    margin-left: 10px;
    margin-bottom: 15px;

    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      padding-right: 25px;
    }

    .template {
      float: left;
    }
  }
}

::v-deep .del {
  width: 100%;
  .title {
    margin-right: 20px;
    overflow: hidden; /*也可以用 width:0 代替*/
    flex: 1;
    .content {
      min-width: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // color: #006dcc;
      font-size: 12px;
      cursor: pointer;
    }
    .contentspan:hover {
      border-bottom: 1px #006dcc solid;
    }
    .blueclass {
      color: #006dcc;
    }
  }
}

::v-deep .related {
  margin-top: 10px;

  .inputWrapper {
    .input-with-select {
      .el-input__inner {
        cursor: pointer;
      }

      .el-input-group__prepend {
        .el-select {
          .el-input {
            .el-input__inner {
              width: 104px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .el-input-group {
      width: 80%;
      float: left;
    }

    .el-input-group__prepend {
      min-width: 100px;
    }

    .el-input__icon {
      line-height: 30px;
    }
  }

  .fasong {
    position: absolute;
    top: 0;
    right: 0px;
    background: #006dcc;
    color: #fff;
  }
}

.img {
  display: inline-block;
  width: 16px;
  height: 15px;
  margin: 4px 0 6px 0;
}

.tls {
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: bold;
  color: #333333;
  line-height: 21px;
}

::v-deep .demo-ruleForm {
  min-width: 365px;

  .el-form-item__error {
    display: none;
  }
}

::v-deep .enclosure {
  margin-top: 10px;
  .el-tag {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;

    .el-tag__close {
      float: right;
      top: 10px;
      font-size: 16px;
      margin-right: 10px;
    }

    .ic {
      display: inline-block;
      width: 24px;
      height: 24px;
      position: relative;
      top: -6px;
    }
  }
}

::v-deep .el-tabs--border-card {
  box-shadow: none !important;
  border: 1px solid #dedcda !important;
}

::v-deep .el-card.is-always-shadow {
  box-shadow: none;
  border: 1px solid #dedcda;
}

::v-deep .email_content {
  margin-bottom: 10px;

  .el-card__body {
    padding: 20px;
    max-height: 300px;
    overflow: auto;
  }
}

::v-deep .baseForm {
  .el-form {
    .el-checkbox__label {
      display: inherit;
      float: left;
    }

    .el-form-item {
      .el-form-item__content {
        margin-left: 136px !important;
      }
    }

    .el-checkbox__label {
      margin-left: 10px !important;
    }
  }
}

.expression-img {
  float: left;
  margin: 0px 0 0 8px;
  cursor: pointer;

  ::v-deep .vemoji-main--target {
    span {
      display: inline-block;
      width: 16px;
      height: 16px;
      padding-right: 25px;
      background-image: url("../../assets/images/shortImg/system_smile_ic.png");
      background-repeat: no-repeat;

      &:hover {
        background-image: url("../../assets/images/shortImg/systems_smile_ic_blue.png");
      }
    }
  }

  ::v-deep .vemoji-main--content {
    left: -15px !important;
    bottom: 30px !important;
  }
}

::v-deep .el-autocomplete {
  width: 100%;
}

::v-deep .shou {
  position: relative;
  .el-form-item__label {
    min-width: 58px !important;
    text-align: left;
    // margin-left: 12px !important;
  }
  .el-input__inner {
    font-size: 14px;
    color: #080707;
  }
  .bcc {
    z-index: 1;
    position: absolute;
    right: 17px;

    span {
      margin-left: 10px;
      cursor: pointer;
      color: #006dcc;
    }
  }
}

::v-deep .duanxin {
  width: 100%;
  padding-right: 10px;

  .el-input__inner {
    width: 100% !important;
  }

  .el-input__suffix {
    line-height: 30px;
    padding-right: 10px;
    cursor: pointer;
  }
}

.colorRed {
  color: red;
  font-size: 12px;
}

.multiselect {
  margin-bottom: 10px !important;
}

::v-deep .event-item-l .el-input__inner {
  height: 30px;
}

::v-deep .multiselect__tags {
  padding: 8px 95px 0 8px;
  min-height: 30px !important;
  margin-top: 5px !important;
}

::v-deep .multiselect__select {
  display: none;
}

::v-deep .multiselect__placeholder {
  display: inline;
  margin-bottom: 0px;
  padding-top: 2px;
}

::v-deep .multiselect--above .multiselect__content-wrapper {
  bottom: auto !important;
}

::v-deep .multiselect__tag {
  font-size: 14px;
  color: #080707;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(221, 219, 218);

  .multiselect__tag-icon:after {
    color: rgb(112, 110, 107) !important;
  }

  .multiselect__tag-icon:hover {
    background-color: transparent !important;
  }
}

p {
  margin-bottom: 0px !important;
  font-size: 12px;
  color: #080707;
}

::v-deep .ckgd {
  padding: 8px 14px;
  font-size: 12px;
  color: #006dcc;
  cursor: pointer;
}
::v-deep .el-tabs__nav-prev {
  line-height: 39px !important;
  left: 4px !important;
}
::v-deep .el-tabs__nav-next {
  line-height: 39px !important;
  right: 3px !important;
}

.colores {
  color: #006dcc;
  cursor: pointer;
  word-break: break-all;
  padding-right: 4px;
}
.coloreses {
  cursor: pointer;
  word-break: break-all;
  padding-right: 4px;
}
.paddingClass {
  padding: 0 6px;
}
.pClass {
  word-break: break-all;
}
::v-deep .calendar_New_Task {
  display: flex;
  .calendar_New_Task_input {
    margin-right: 10px;
  }
  .calendar_New_Task_button {
    padding: 2px 20px;
  }
}

::v-deep .multiselect__content-wrapper {
  position: static !important;
  .multiselect__content {
    .multiselect__element {
      .multiselect__option--highlight {
        background: #006dcc !important;
        outline: none !important;
        color: #fff !important;
      }
    }
  }
}
// 保证抄送人和密送人label和必填项label对齐
.chao {
  ::v-deep .el-form-item__label {
    padding-left: 10px;
  }
}
// flex布局
.flexbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.emailbox1 {
  word-break: break-all;
}
// 生成文档
.generateTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;
  font-size: 12px;
  cursor: pointer;
  color: #606266;
  &:hover {
    background: #f5f7fa;
    background-color: #f5f7fa;
  }
}
.generate-box {
  .generate-Title {
    font-size: 14px;
    color: #080707;
    font-weight: bolder;
  }
  .generate-line {
    margin: 8px -10px 0;
    border-top: 1px solid #dedcda;
  }
  .generate-tip {
    font-size: 14px;
    color: #080707;
    margin-top: 5px;
  }
  .generate-record {
    font-size: 14px;
    color: #080707;
    margin: 8px 0;
  }
  .generate-records {
    font-size: 14px;
    color: #080707;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    .generate-record-checkbox {
      margin-left: 16px;
      margin-top: 12px;
      ::v-deep .el-checkbox__label {
        min-width: 100px !important;
      }
    }
    .generate-record-title {
      font-size: 14px;
    }
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }

  .generate-recordBox {
    .generateTipBox:hover {
      background-color: #f5f7fa;
    }
    ::v-deep .el-select {
      width: 85%;
    }
  }
  // ::v-deep .generate-recordData{
  //   width:80%;
  // }
  .generate-FileBox {
    display: flex;
    align-items: center;
    .generate-FileBox {
      font-size: 12px;
      color: #080707;
      font-weight: bold;
      margin-right: 10px;
      width: 40px;
      min-width: 20px;
    }
    ::v-deep .el-select {
      width: calc(85% - 50px);
    }
  }
  .generate-table {
    margin: 10px -10px 0;
    ::v-deep .el-table th,
    .el-table tr {
      border-top: 1px solid #ebeef5;
    }
    ::v-deep .el-table thead {
      color: #666666;
    }
    ::v-deep .el-table .cell {
      word-break: break-word;
    }
  }
  .generate-history {
    display: flex;
    align-items: center;
    margin-right: -10px;
    .history {
      width: 30px;
      margin-right: 4px;
    }
    .history-line {
      border-top: 1px solid #dedcda;
      width: calc(100% - 35px);
    }
  }
  .generate-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    ::v-deep .el-button {
      min-width: 75px;
      height: 28px;
      padding: 0;
    }
  }
}
.beforeMoreTime {
  margin: 0 auto;
  display: inherit;
  padding: 8px 14px;
  font-size: 12px;
  color: #006dcc;
  border: 0;
}
.beforeMoreTime:hover {
  background: #fff;
  border: 0;
  color: #006dcc;
}
.feature-box {
  margin: 0px 0px 10px -30px;
  text-align: center;
  ::v-deep .el-button:hover {
    background: #fff;
    border: 0;
    color: #006dcc;
  }
  ::v-deep .el-button {
    border: 0;
  }
}
</style>
