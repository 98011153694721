<template>
  <div>
    <div class="replyes">
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="535px"
        center
      >
        <div v-loading="loading">
          <el-form ref="form">
            <!-- 发件人 -->
            <el-form-item :label="$t('label.senduser')">
              <el-select v-model="fromId" class="add_form">
                <el-option
                  v-for="item in emaillist"
                  :key="item.id"
                  :label="item.syncmail"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <!-- 收件人 -->
            <el-form-item :label="$t('component_sendmail_label_touser')">
              <el-input v-model="email.toaddress"></el-input>
            </el-form-item>
            <!-- 抄送人和密送人要一直存在 -->
            <!-- 抄送人 -->
            <el-form-item :label="$t('label.mobile.phone.cc.people')">
              <el-input v-model="email.ccaddress"></el-input>
            </el-form-item>
            <!-- 密送人 -->
            <el-form-item :label="$t('vue_label_email_cc')">
              <el-input v-model="email.bccaddress"></el-input>
            </el-form-item>
            <!-- 主题 -->
            <el-form-item :label="$t('label_chatter_theme')">
              <el-input v-model="email.subject"></el-input>
            </el-form-item>
          </el-form>
          <wangEditors ref="wangEditores" @editorContent="editorContent">
          </wangEditors>
          <div class="related">
            <label style="padding-top: 10px">
              <!-- 相关项 -->
              {{ $t("component_telerecord_label_related") }}
            </label>
            <div class="inputWrapper">
              <div style="margin-top: 10px">
                <!-- 请输入内容 -->
                <el-input
                  disabled
                  :placeholder="$t('label_tabpage_contentz')"
                  v-model="shortMessage.labelValue"
                  class="input-with-select"
                >
                  <!-- 请选择 -->
                  <el-select
                    v-model="shortMessage.select"
                    slot="prepend"
                    :placeholder="$t('label_tabpage_pleaseSelectz')"
                    class="select"
                    disabled
                  >
                    <el-option
                      v-for="(item, index) in cities"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                      @click.native="optiones(item)"
                    >
                      <span style="float: left">{{ item.label }}</span>
                    </el-option>
                  </el-select>
                </el-input>
                <el-button class="fasong" @click="submits" :loading="isSending">
                  <!-- 发送 -->
                  {{ $t("label_chatter_send") }}
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import wangEditors from "../wangEditor";
import { ActivityGetQuickActivityPage, GetBrief } from "./api.js";
import {
  getEmailObjectgetEmailDetailInfo,
  getEmailObjectsendEmailObject,
} from "./api.js";

export default {
  components: {
    wangEditors,
  },
  props: {
    recordId: {
      type: String,
    },
    emaillist: {
      type: Array,
      default: () => {
        return [];
      },
    },
    emailFrom: {
      type: String,
    },
  },
  watch: {
    emaillist() {
      // 发件人默认展示第一个
      if (this.emaillist.length > 0) {
        this.fromId = this.emaillist[0].id;
      }
    },
  },
  created() {
    this.ActivityGetQuickActivityPages();
  },
  data() {
    return {
      isSending: false, // loading
      fromId: "",
      dialogVisible: false,
      shortMessage: {
        addressee: "",
        content: "",
        labelValue: "",
        select: "",
        objId: "",
        prefix: "",
      },
      showSearchTables: false,
      fieldId: "",
      relevantObjId: "",
      relevantPrefix: "",
      checkeds: false,
      email: {
        addressee: "",
        theme: "",
        items: "",
        istrackopen: "1",
        isattachment: "0",
        ccaddress: "",
        bccaddress: "",
        addressees: "",
        relatedid: "",
        subject: "",
        toaddress: "",
        content: "",
        relateid: "",
        attachment: "",
      },
      title: "",
      loading: false,
      Korrelate: null,
      cities: [],
      fromaddress: "",
    };
  },
  methods: {
    emailGetBrief() {
      GetBrief({ id: this.$route.params.id }).then((res) => {
        this.shortMessage.select = res.data.label;
      });
    },
    async ActivityGetQuickActivityPages() {
      let params = {
        recordId: this.recordId,
        type: "task",
      };
      let res = await ActivityGetQuickActivityPage(params);
      if (res.result) {
        this.shortMessage.select = this.shortMessageValue;
        this.shortMessage.objId = res.data.relateobjList[0].id;
        this.shortMessage.prefix = res.data.relateobjList[0].prefix;
        res.data.relateobjList.forEach((item) => {
          let obj = {
            label: item.labelName,
            value: item.prefix,
            id: item.id,
          };

          this.cities.push(obj);
        });
      }
    },
    async submits() {
      let ccType = "";
      this.emaillist.forEach((val) => {
        if (this.fromId == val.id) {
          this.fromaddress = val.syncmail;
          ccType = val.cctype;
        }
      });
      let reg = /^[^\.](.*[^\.])*@[^\.].*\..*[^\.]$/;
      let newReg = /^[^@]*@[^@]*$/;
      let flag =
        reg.test(this.email.toaddress) && newReg.test(this.email.toaddress);

      if (this.email.ccaddress && this.email.bccaddress) {
        flag =
          reg.test(this.email.ccaddress) &&
          newReg.test(this.email.ccaddress) &&
          reg.test(this.email.bccaddress) &&
          newReg.test(this.email.bccaddress) &&
          reg.test(this.email.toaddress) &&
          newReg.test(this.email.toaddress);
      } else if (this.email.ccaddress && !this.email.bccaddress) {
        flag =
          reg.test(this.email.ccaddress) &&
          newReg.test(this.email.ccaddress) &&
          reg.test(this.email.toaddress) &&
          newReg.test(this.email.toaddress);
      } else if (!this.email.ccaddress && this.email.bccaddress) {
        flag =
          reg.test(this.email.bccaddress) &&
          newReg.test(this.email.bccaddress) &&
          reg.test(this.email.toaddress) &&
          newReg.test(this.email.toaddress);
      }

      if (flag) {
        // 收件人、密送人、抄送人邮箱号符合正则

        let params = {
          subject: this.email.subject,
          toaddress: this.email.toaddress,
          ccaddress: this.email.ccaddress,
          bccaddress: this.email.bccaddress,
          attachment: this.email.attachment,
          content: this.email.content,
          relatedid: this.email.relatedid,
          isattachment: this.email.isattachment,
          istrackopen: "",
          relateid: this.email.relatedid,
          savetemp: "0",
          singleSend: "0",
          cctype: ccType,
          fromaddress: this.fromaddress,
        };
        this.isSending = true;
        let res;
        try {
          res = await getEmailObjectsendEmailObject(params);
          this.isSending = false;
        } catch (error) {
          this.isSending = false;
        }
        if (res.result) {
          // 发送成功
          this.$message.success(this.$i18n.t("label.apply.send.success"));
          this.dialogVisible = false;
          this.$parent.init();
        }
      } else {
        //  提示输入正确的邮箱号

        this.$message(
          this.$i18n.t("vue_label_commonobjects_detail_correct_email_address")
        );
      }
    },
    async obtain(val, type) {
      this.emailGetBrief();
      this.loading = true;
      this.dialogVisible = true;
      let params = {
        id: val,
      };
      let res = await getEmailObjectgetEmailDetailInfo(params);
      if (res.result) {
        if (type === "reply") {
          // 回复
          this.title =
            this.$i18n.t("label.emailobject.emaildetail.button.reply") +
            ":" +
            res.data.detail.name;
          this.email.subject =
            this.$i18n.t("label.emailobject.emaildetail.button.reply") +
            ":" +
            res.data.detail.name;
          this.email.ccaddress = res.data.detail.ccaddress;
          this.email.bccaddress = res.data.detail.bccaddress;
          this.email.toaddress = res.data.detail.fromaddress;
          this.email.relatedid = res.data.detail.relatedid;
          this.email.isattachment = res.data.detail.isattachment;
          this.email.attachment = res.data.detail.attachment;
          this.$refs.wangEditores.wangEditorese(res.data);
          this.shortMessage.labelValue =
            res.data.relate && res.data.relate.relateidccname;
          this.email.relatedid = res.data.detail.relateid;
          this.loading = false;
          let obj = {
            label: res.data.relate.objectLabel,
            value: res.data.relate.prefix,
            id: res.data.relate.objid,
          };
          this.cities.forEach((item) => {
            if (item.value !== obj.value) {
              this.cities.push(obj);
            }
          });
        } else if (type === "forward") {
          // 转发
          this.title =
            this.$i18n.t("label.emailobject.emaildetail.button.forward") +
            ":" +
            res.data.detail.name;
          this.email.subject =
            this.$i18n.t("label.emailobject.emaildetail.button.forward") +
            ":" +
            res.data.detail.name;
          this.email.toaddress = "";
          this.email.relatedid = res.data.detail.relatedid;
          this.email.isattachment = res.data.detail.isattachment;
          this.email.attachment = res.data.detail.attachment;
          this.$refs.wangEditores.wangEditorese(res.data);
          this.shortMessage.labelValue =
            res.data.relate && res.data.relate.relateidccname;
          this.email.relatedid = res.data.detail.relateid;
          this.loading = false;
          let obj = {
            label: res.data.relate.objectLabel,
            value: res.data.relate.prefix,
            id: res.data.relate.objid,
          };
          this.cities.forEach((item) => {
            if (item.value !== obj.value) {
              this.cities.push(obj);
            }
          });
        }
      }
    },
    editorContent(val) {
      this.email.content = val;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .replyes {
  .el-dialog__wrapper {
    .el-dialog {
      margin-top: 3vh !important;
      .el-dialog__header {
        .el-dialog__title {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: bold;
          color: #080707;
        }
      }
      .el-dialog__body {
        padding: 16px 36px;
        .el-form {
          .el-form-item {
            .el-form-item__label {
              line-height: 0px !important;
              padding: 10px 0 12px 0 !important;
            }
            .el-form-item__content {
              width: 100% !important;
              margin-left: 0px !important;
            }
          }
        }
      }
    }
  }
}
::v-deep .related {
  margin-top: 10px;
  .inputWrapper {
    position: relative;
    .input-with-select {
      .el-input__inner {
        cursor: pointer;
      }
      .el-input-group__prepend {
        .el-select {
          .el-input {
            .el-input__inner {
              width: 104px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .el-input-group {
      width: 80%;
      float: left;
    }
    .el-input-group__prepend {
      min-width: 100px;
    }
    .el-input__icon {
      line-height: 30px;
    }
  }
  .fasong {
    width: 70px !important;
    height: 30px !important;
    float: right !important;

    padding: 5px !important;
    background: #006dcc;
    color: #fff;
  }
}
::v-deep .el-select-dropdown {
  width: 490px !important;
}
.add_form {
  width: 100%;
  ::v-deep .el-input__inner {
    font-size: 14px;
    color: #080707;
  }
}
</style>
