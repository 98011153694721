<!--
 * @Author: zhangyutong
 * @Date: 2021-09-03 15:27:22
 * @LastEditTime: 2022-02-17 14:02:02
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\detailChild\Email\schedule.vue
-->
<!--  -->
<template>
  <div>
    <el-card style="border: 0px">
      <div slot="header" class="clearfix">
        <span>
          <!-- 编辑签名 -->
          {{ $t("vue_label_normal_appointment_sf") }}
        </span>
        <i
          style="float: right; padding: 3px 0; cursor: pointer"
          type="text"
          class="el-icon-close"
          @click="cencel"
        ></i>
      </div>
      <div class="mainContainer">
        <el-input v-model="searchKeyWord">
          <i
            slot="suffix"
            class="el-icon-search el-icon--right searchIocn"
            @click="init"
          ></i>
        </el-input>
        <div class="box-card" v-loading="mainLoading">
          <div
            v-for="(item, index) in this.scheduleList"
            :key="index"
            class="item"
            :style="
              item.invitationurl === selectUrl
                ? 'background-color: #d1eff3;'
                : ''
            "
            @click="selectItem(item)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="footer">
        <el-button size="mini" @click="cencel">
          <!-- 取 消 -->
          {{ $t("button_partner_cancel") }}
        </el-button>
        <el-button class="queding" size="mini" @click="save">
          <!-- 确 定 -->
          {{ $t("label.ems.confirm") }}
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { queryAppointments } from "../api";

export default {
  components: {},
  data() {
    return {
      mainLoading: false,
      selectUrl: "",
      searchKeyWord: "",
      scheduleList: [],
    };
  },
  created() {
    var _this = this;
    document.onkeydown = function () {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.init();
      }
    };
  },
  //方法集合
  methods: {
    selectItem(item) {
      if (this.selectUrl === item.invitationurl) {
        this.selectUrl = "";
      } else {
        this.selectUrl = item.invitationurl;
      }
    },
    cencel() {
      this.selectUrl = "";
      this.searchKeyWord = "";
      this.$emit("scheduleCencel");
    },
    save() {
      if (this.selectUrl !== "") {
        this.$emit("saveSchedule", this.selectUrl);
        this.cencel();
      } else {
        this.$message.error("请选择日程预约");
      }
    },
    init() {
      this.isdiglog = true;
      this.mainLoading = true;
      queryAppointments({ keyword: this.searchKeyWord }).then((res) => {
        if (res.result) {
          this.mainLoading = false;
          this.scheduleList = res.data.list;
        } else {
          // 抱歉，您暂无该对象新建权限
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.searchIocn {
  margin-top: 10px;
  cursor: pointer;
}
.mainContainer {
  overflow-x: auto;
  width: 240px;
}
.box-card {
  margin: 10px 0px;
  min-height: 100px;
  overflow: auto;
  border: 1px solid #dcdfe6;
  padding: 10px;
}
.item {
  padding: 5px 0px;
  cursor: pointer;
  margin: 5px 0px;
}
.selectItem {
  padding: 5px 0px;
  cursor: pointer;
  margin: 5px 0px;
  background-color: #d1eff3;
}
</style>