import axios from '@/config/httpConfig'
// 单条邮件详情
export function getEmailObjectgetEmailDetailInfo(data) {
  return axios.post('/emailObject/getEmailDetailInfo', data)
}
// 发送电子邮件
export function getEmailObjectsendEmailObject(data) {
  return axios.post('/emailObject/sendEmailObject', data)
}
//获取活动快速新建页面信息
export function ActivityGetQuickActivityPage(data) {
  return axios.post('/activity/getQuickActivityPage', data)
}
// 获取详情页标签
export function GetBrief(data) {
  return axios.post('/objectdetail/getBrief', data)
}